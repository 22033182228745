import axios from 'axios';
import FalconComponentCard from 'components/common/FalconComponentCard';
import PageHeader from 'components/common/PageHeader';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Card, Col, Form, Row, Table } from 'react-bootstrap';
import { ApiEndpoint } from 'utils/ApiEndPont';
import { FormatCurrency, FormatDate } from 'utils/FormatData';
import Select from 'react-select';
import AddQuickProduct from './AddProduct';
import ProductsVentas from './ProductsVentas';
import { v4 as uuidv4 } from 'uuid';
import MyModal from 'utils/Modal';
import NewPayment from './NewPayment';
import Flex from 'components/common/Flex';
import { useNavigate, useParams } from 'react-router-dom';
import { decryptData, encryptData } from 'utils/Encrypth';
import { useAuth } from 'context/AuthProvider';
import EditCantidad from 'form-components/EditCantidad';

const NewVenta = () => {
  const Auth = useAuth();
  let { idVenta } = useParams();
  let navigate = useNavigate();
  const [customersList, setCustomersList] = useState([]);
  const [vendedoresList, setVendedoresList] = useState([]);
  const [detallesList, setDetallesList] = useState([]);
  const [customer, setCustomer] = useState(1);
  const [vendedor, setVendedor] = useState(1);
  const [idVentaNew, setIdVentaNew] = useState(0);
  const [producto, setProducto] = useState(null);
  const [openModalPago, setOpenModalPago] = useState(false);
  const [openModalCantidad, setOpenModalCantidad] = useState(false);
  const [cantidad, setCantidad] = useState(1);
  const [deleteProduct, setDeleteProduct] = useState(null);
  const [totalProducts, setTotalProducts] = useState(null);
  const [messageError, setMessageError] = useState(null);
  const [editProduct, setEditProduct] = useState(null);

  const handleClose = () => {
    setOpenModalPago(false);
    setOpenModalCantidad(false);
  };

  useEffect(() => {
    var cajaData = Auth.getCajaData();
    if (!cajaData && cajaData == null) {
      const URIApertura = ApiEndpoint + 'getapertura/';
      axios
        .post(URIApertura, {
          idsucursal: Auth.getSessionData().id_sucursal
        })
        .then(function (response) {
          if (!response.data.error) {
            localStorage.setItem(
              'crmovilcajadata',
              encryptData(JSON.stringify(response.data))
            );
            Auth.setCajaData(response.data);
          }
        });
    }
  }, []);

  const getCustomers = () => {
    const uriParts = ApiEndpoint + 'clientes/';
    axios(uriParts).then(({ data }) => {
      const partList = data.map(parts => {
        return {
          value: `${parts.idcliente}`,
          label: `${parts.nombre}`
        };
      });
      setCustomersList(partList);
      setCustomer(partList[0]);
    });
  };

  const getVenta = idVenta => {
    let detallesListFromVenta = [];
    const uriVenta = ApiEndpoint + 'venta/' + idVenta;
    axios(uriVenta).then(({ data }) => {
      setCustomer({ value: data.idcliente, label: data.cliente });
      setVendedor({ value: data.idvendedor, label: data.vendedor });
    });
    axios(ApiEndpoint + 'ventadetails/' + idVenta).then(({ data }) => {
      data.map(item => {
        let newData = item.cat_producto;
        newData.rowId = uuidv4();
        newData.cantidad = item.cantidad;
        newData.importe = item.cantidad * newData.preciopublico;
        detallesListFromVenta.push(newData);
      });

      setDetallesList(detallesListFromVenta);
      let totalItems = getTotalItems();
      setTotalProducts(totalItems);
    });
  };

  const getVendedores = () => {
    const uriParts = ApiEndpoint + 'vendedores/';
    axios(uriParts).then(({ data }) => {
      const partList = data.map(parts => {
        return {
          value: `${parts.idvendedor}`,
          label: `${parts.nombre}`
        };
      });
      setVendedoresList(partList);
      setVendedor(partList[0]);
    });
  };

  const saveVenta = () => {
    if (!detallesList || detallesList.length == 0) {
      setMessageError('Debe agregar al menos 1 articulo');
      return;
    }

    var cajaData = Auth.getCajaData();
    var dataToSubmit = {
      idsucursal: Auth.getSessionData().id_sucursal,
      idvendedor: vendedor.value,
      idcliente: customer.value,
      comentarios: '',
      subtotal: getTotalVenta(),
      iva: 0,
      total: getTotalVenta() + 0,
      Details: detallesList,
      ventaid: uuidv4(),
      idapertura: cajaData.idapertura,
      iduser: Auth.getSessionData().id_user
    };

    const URI = ApiEndpoint + (idVenta > 0 ? 'updateventa' : 'saveventa/');
    axios
      .post(URI, dataToSubmit)
      .then(function (response) {
        setIdVentaNew(response.data.id);
        navigate('/Ventas');
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const saveAndChargeVenta = () => {
    if (!detallesList || detallesList.length == 0) {
      setMessageError('Debe agregar al menos 1 articulo');
      return;
    }

    var cajaData = Auth.getCajaData();
    var dataToSubmit = {
      idsucursal: Auth.getSessionData().id_sucursal,
      idvendedor: vendedor.value,
      idcliente: customer.value,
      comentarios: '',
      subtotal: getTotalVenta(),
      iva: 0,
      total: getTotalVenta() + 0,
      Details: detallesList,
      ventaid: uuidv4(),
      idapertura: cajaData.idapertura,
      iduser: Auth.getSessionData().id_user,
      idVenta: idVenta ?? 0
    };

    const URI = ApiEndpoint + (idVenta > 0 ? 'updateventa' : 'saveventa/');
    axios
      .post(URI, dataToSubmit)
      .then(function (response) {
        setIdVentaNew(response.data.id);
        setOpenModalPago(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    var cajaData = Auth.getCajaData();
    if (!cajaData && cajaData == null) {
      navigate('/');
      return;
    }
    getCustomers();
    getVendedores();
    setDetallesList([]);
    if (idVenta && idVenta != 0) {
      setIdVentaNew(idVenta);
      getVenta(idVenta);
    }
  }, []); // empty array makes hook working once

  useEffect(() => {
    if (producto != null) {
      let newData = producto;
      newData.rowId = uuidv4();
      newData.cantidad = cantidad;
      newData.importe = cantidad * newData.preciopublico;
      detallesList.push(newData);
      setTotalProducts(totalProducts + 1);
    }
  }, [producto]);

  useEffect(() => {
    if (deleteProduct) {
      var newArr = detallesList;
      setDetallesList(newArr.filter(c => c.rowId != deleteProduct));

      setTotalProducts(getTotalItems());
    }
  }, [deleteProduct]);

  const onChangeCantidad = data => {
    var product = detallesList.find(c => c.rowId == data.rowId);
    product.cantidad = data.cantidad;
    product.importe = data.cantidad * product.preciopublico;
    setDetallesList([...detallesList]);
    setTotalProducts(getTotalItems());
  };

  const getTotalVenta = () =>
    detallesList
      .map(
        ({ cantidad, preciopublico }) =>
          Number(cantidad) * Number(preciopublico)
      )
      .reduce((total, currentValue) => Number(total) + Number(currentValue), 0);

  const getTotalItems = () =>
    detallesList
      .map(({ cantidad }) => Number(cantidad))
      .reduce((total, currentValue) => Number(total) + Number(currentValue), 0);

  useEffect(() => {
    if (messageError != '') {
      setTimeout(() => {
        setMessageError('');
      }, 5000);
    }
  }, [messageError]);

  return (
    <>
      <PageHeader
        title={'Nueva Venta '}
        titleTag="h5"
        className="mb-3"
      ></PageHeader>
      <Card className="mb-3">
        <Card.Body>
          <Row>
            <Col>
              <Row>
                <Col md={12} className="mb-4 mb-lg-0">
                  <Form.Group className="mb-3" controlId="idPart">
                    <Form.Label>Cliente</Form.Label>
                    <div style={{ width: '450px' }}>
                      <Select
                        aria-label="Default select"
                        name="part"
                        size="sm"
                        closeMenuOnSelect={true}
                        classNamePrefix="react-select"
                        value={customer}
                        defaultValue={customer}
                        onChange={value => {
                          setCustomer(value);
                        }}
                        options={customersList}
                      ></Select>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12} className="mb-4 mb-lg-0">
                  <Form.Group className="mb-3" controlId="idPart">
                    <Form.Label>Vendedor</Form.Label>
                    <div style={{ width: '450px' }}>
                      <Select
                        aria-label="Default select"
                        name="vendedor"
                        size="sm"
                        closeMenuOnSelect={true}
                        classNamePrefix="react-select"
                        value={vendedor}
                        defaultValue={vendedor}
                        onChange={value => {
                          setVendedor(value);
                        }}
                        options={vendedoresList}
                      ></Select>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <h3 className="text-success fw-light">
                  <span className="fw-bold">{messageError}</span>
                </h3>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col md={12} className="mb-4 mb-lg-0">
                  <Flex justifyContent="center" className="p-2 mb-2">
                    Fecha: {FormatDate(new Date())}
                  </Flex>
                </Col>
              </Row>

              <Row>
                <hr />
              </Row>
              <Row>
                <Col md={6} className="mb-4 mb-lg-0">
                  <h3 className="mb-3 fs-0">
                    <Flex justifyContent="center">Total</Flex>
                  </h3>
                  <div className="d-flex">
                    <div className="flex-1">
                      <h3 className="text-primary mb-0">
                        <Flex justifyContent="center">
                          {FormatCurrency(getTotalVenta())}
                        </Flex>
                      </h3>
                    </div>
                  </div>
                </Col>
                <Col md={6} className="mb-4 mb-lg-0">
                  <h5 className="mb-3 fs-0">
                    <Flex justifyContent="center">Productos</Flex>
                  </h5>
                  <div className="d-flex">
                    <div className="flex-1">
                      <h4 className="text-primary mb-0">
                        <Flex justifyContent="center">{getTotalItems()}</Flex>
                      </h4>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12} className="mb-4 mb-lg-0">
                  <div className="flex-1">
                    <h4 className="text-primary mb-0">
                      <Flex justifyContent="center" className="p-2 mb-2">
                        {totalProducts && totalProducts > 0 && (
                          <>
                            <Button
                              onClick={() => {
                                saveAndChargeVenta();
                              }}
                              color="success"
                              size="lg"
                              variant="primary"
                              transform="shrink-3"
                              className="rounded-pill me-2 mb-1"
                            >
                              Guardar y Cobrar
                            </Button>
                            <Button
                              onClick={() => {
                                saveVenta();
                              }}
                              color="primary"
                              size="lg"
                              variant="info"
                              type="button"
                              transform="shrink-3"
                              className="rounded-pill me-2 mb-1"
                            >
                              Guardar
                            </Button>
                          </>
                        )}
                      </Flex>
                    </h4>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Row>
        <Col lg={12} className="mb-4 mb-lg-0">
          <Card className="mb-3">
            <Card.Body>
              <FalconComponentCard noGuttersBottom>
                <AddQuickProduct
                  addProduct={producto => {
                    //detallesList.push(producto);
                    setProducto(producto);
                  }}
                  setCantidad={setCantidad}
                  cantidad={cantidad}
                ></AddQuickProduct>
              </FalconComponentCard>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col lg={12}>
          <FalconComponentCard
            noGuttersBottom
            style={{ minHeight: 500, maxHeight: 500, overflow: 'auto' }}
          >
            {detallesList && (
              <ProductsVentas
                detallesList={detallesList}
                totalProducts={totalProducts}
                setDeleteProduct={setDeleteProduct}
                setEditProduct={setEditProduct}
                setOpenModalCantidad={setOpenModalCantidad}
              ></ProductsVentas>
            )}
          </FalconComponentCard>
        </Col>
      </Row>
      {openModalPago && idVentaNew && (
        <MyModal
          id="id_myModal"
          title={'Agregar Pago'}
          openModal={openModalPago}
          closeModal={handleClose}
        >
          <NewPayment
            idVenta={idVentaNew}
            montoAPagar={getTotalVenta() + 0}
            closeModal={handleClose}
          />
          {/* <TicketPreview
            idVenta={1}
            montoAPagar={100}
            closeModal={handleClose}
          /> */}
        </MyModal>
      )}
      {openModalCantidad && (
        <MyModal
          id="id_myModal"
          title={'Editar Cantidad'}
          openModal={openModalCantidad}
          closeModal={handleClose}
        >
          <EditCantidad
            producto={editProduct}
            onChangeCantidad={onChangeCantidad}
          ></EditCantidad>
        </MyModal>
      )}
    </>
  );
};

export default NewVenta;
