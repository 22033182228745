import { useEffect, useState, useMemo } from 'react';
import { set, useForm } from 'react-hook-form';
import axios from 'axios';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import AlertNotification from 'form-components/AlertNotification';
import { ApiEndpoint } from 'utils/ApiEndPont';
import { FormInputText } from 'form-components/FormInputText';
import Select from 'react-select';
import uuid from 'react-uuid';

const TechAsign = ({ idReparacion, closeModal }) => {
  const [alertMessage, setAlertMessage] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState('success');

  const [technicians, setTechnicians] = useState([]);
  const [selectedTech, setSelectedTech] = useState([]);
  const [idSelectedTech, setIdSelectedTech] = useState(null);

  const [values, setValues] = useState({
    technicians: '0'
  });
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  // const [categoriesFailure] = useSymptomsCategory ();
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      technicians: '0'
    }
  });

  useEffect(() => {
    const uritech = ApiEndpoint + 'tecnicos/';
    axios(uritech).then(({ data }) => {
      const techs = data.map(tipo => {
        return {
          value: `${tipo.idtecnico}`,
          label: `${tipo.nombre}`
        };
      });
      setTechnicians(techs);
    });
  }, []); // empty array makes hook working once

  const onSubmit = (data, e) => {
    const form = e.target;
    if (form.checkValidity() === false) {
      console.log('entre a submit 123');
      setValidated(true);
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    const URI = ApiEndpoint + 'reparaciones/asign';
    setValidated(true);
    axios
      .post(URI, {
        reparacion: idReparacion,
        idtech: idSelectedTech
      })
      .then(function (response) {
        console.log(response);
        if (!response.error) {
          closeModal();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onChange = event => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  return (
    <>
      {' '}
      <Card style={{ width: '100%' }}>
        <Card.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit(onSubmit)}
          >
            <hr />
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="idPart">
                  <Form.Label>Tecnico Asignado</Form.Label>
                  <div style={{ width: '450px' }}>
                    <Select
                      aria-label="Default select"
                      name="selectedtech"
                      size="sm"
                      closeMenuOnSelect={true}
                      classNamePrefix="react-select"
                      value={selectedTech}
                      onChange={value => {
                        setIdSelectedTech(`${value.value}`);
                        setSelectedTech(value);
                      }}
                      options={technicians}
                    ></Select>
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <Button type="submit" color="primary" size="sm">
              Guardar
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default TechAsign;
