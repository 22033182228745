import axios from 'axios';
import Flex from 'components/common/Flex';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { ApiEndpoint } from 'utils/ApiEndPont';
import { FormatDate } from 'utils/FormatData';
import barChart from 'assets/img/illustrations/crm-bar-chart.png';
import lineChart from 'assets/img/illustrations/crm-line-chart.png';
import MyModal from 'utils/Modal';
import NewApertura from 'Catalogos/Caja/NewApertura';
import { useAuth } from 'context/AuthProvider';
import TurnoCard from './TurnoCard';
import InfoSucursalCard from './InfoSucursalCard';
import { Link, useNavigate } from 'react-router-dom';

const Dashboard = () => {
  let history = useNavigate();
  const Auth = useAuth();
  const [isCajaClose, setIsCajaClose] = useState(true);
  const [openModalCaja, setOpenModalCaja] = useState(false);
  const handleClose = () => {
    setOpenModalCaja(false);
    window.location.reload();
  };

  const getAperturaData = () => {
    const URIApertura = ApiEndpoint + 'getapertura/';

    axios
      .post(URIApertura, {
        idsucursal: Auth.getSessionData().id_sucursal
      })
      .then(function (response) {
        if (!response.data.error) {
          setIsCajaClose(false);
          Auth.setCajaData(response.data);
        } else {
          localStorage.setItem('crmovilcajadata', null);

          Auth.setCajaData(null);
        }
      });
  };

  useEffect(() => {
    var authCheck = Auth.getSessionData();
    if (!authCheck || authCheck?.length === 0 || authCheck === 'null') {
      history('/login');
      return;
      //Router.push('/login');
    } else {
      getAperturaData();
    }
  }, []);

  return (
    <>
      <Card className="bg-100 shadow-none border mb-3">
        <Card.Header>
          <Row className="align-items-center justify-content-between">
            <Col sm="auto">
              <Flex alignItems="center">
                <img src={barChart} width={90} alt="..." className="ms-n4" />
                <div>
                  <h6 className="text-primary fs--1 mb-0">CRMovil</h6>
                </div>
                <img
                  src={lineChart}
                  width={140}
                  alt="..."
                  className="ms-n4 d-md-none d-lg-block"
                />
              </Flex>
            </Col>
            <Col md="auto" className="mb-3 mb-md-0">
              <Row className="g-3 gy-md-0 h-100 align-items-center">
                <Col>
                  <Row>
                    <Col xs="auto">
                      <h6 className="text-700 mb-0 text-nowrap">Usuario:</h6>
                    </Col>
                    <Col md="auto">
                      <h6 className="text-700 mb-0 text-nowrap">
                        {Auth.getSessionData()?.vc_name +
                          ' ' +
                          Auth.getSessionData()?.vc_lastname}
                      </h6>
                    </Col>
                  </Row>{' '}
                </Col>
              </Row>
            </Col>
          </Row>
          <hr />
        </Card.Header>
        <Card.Body className="overflow-hidden p-lg-6">
          <Row className="align-items-center justify-content-between">
            <Col lg={6}>
              {Auth.getSessionData() && <InfoSucursalCard></InfoSucursalCard>}
            </Col>
            <Col lg={6} className="ps-lg-4 my-5 text-center text-lg-start">
              {isCajaClose ? (
                <>
                  <h3 className="text-primary">Caja Cerrada</h3>
                  <p className="lead">
                    Debe Iniciar la caja para poder vender.
                  </p>
                  <Button
                    onClick={() => {
                      setOpenModalCaja(true);
                    }}
                    variant="falcon-primary"
                  >
                    Abrir Caja
                  </Button>
                </>
              ) : (
                <TurnoCard></TurnoCard>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {/* <ServicesListByStatus idLocation={1} idStatusService={1} /> */}
      {openModalCaja && (
        <MyModal
          id="id_myModal"
          title={'Abrir Caja'}
          openModal={openModalCaja}
          closeModal={handleClose}
        >
          <NewApertura
            idUser={1}
            idUserType={1}
            closeModal={handleClose}
            idsucursal={1}
          ></NewApertura>
        </MyModal>
      )}
    </>
  );
};

export default Dashboard;
