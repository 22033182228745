import React, { useEffect, useState } from 'react';
import VentasFilters from './VentasFilters';
import axios from 'axios';
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MyModal from 'utils/Modal.js';
import NewPayment from './NewPayment';
import TicketPreview from './TicketPreview';
import { FormatCurrency, FormatDate, FormatDateToDb } from 'utils/FormatData';
import { Link } from 'react-router-dom';
import { ApiEndpoint } from 'utils/ApiEndPont';

const VentasList = () => {
  const [filterFolio, setFilterFolio] = useState(0);
  const [customerIdFilter, setCustomerIdFilter] = useState(0);
  const [idStatusFilter, setIdStatusFilter] = useState(0);
  const [idSucursalFilter, setIdSucursalFilter] = useState(0);
  const [fromFilter, setFromFilter] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [toFilter, setToFilter] = useState(new Date());
  const [ventas, setVentas] = useState(false);
  const [idVenta, setIdVenta] = useState(0);

  const [openModal, setOpenModal] = useState(false);
  const [openModalPago, setOpenModalPago] = useState(false);

  const columns = [
    {
      accessor: 'vc_folio',
      Header: 'folio',
      headerProps: { style: { minWidth: '200px' }, className: 'ps-5' },
      cellProps: { className: 'ps-5' }
    },
    {
      accessor: 'dt_fecha',
      Header: 'Fecha',
      disableSortBy: true,
      cellProps: { className: 'ps-5' },
      Cell: rowData => {
        let total = rowData.row.original.dt_fecha;
        return <>{FormatDate(total)}</>;
      }
    },
    {
      accessor: 'vc_sucursal',
      Header: 'Sucursal',
      headerProps: { style: { minWidth: '200px' }, className: 'ps-5' },
      cellProps: { className: 'ps-5' }
    },
    {
      accessor: 'n_total',
      Header: 'Importe',
      disableSortBy: true,
      cellProps: { className: 'ps-5' },
      Cell: rowData => {
        let total = rowData.row.original.n_total;
        return <>{FormatCurrency(total)}</>;
      }
    },
    {
      accessor: 'vc_status',
      Header: 'Status',
      disableSortBy: true,
      cellProps: { className: 'ps-5' }
    },
    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: rowData => {
        let idventa = rowData.row.original.id_venta;
        let idStatus = rowData.row.original.id_status;
        return (
          <>
            {idStatus == 1 && (
              <>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Agregar Pago</Tooltip>}
                >
                  <Button
                    variant="falcon-default"
                    size="sm"
                    onClick={() => {
                      setIdVenta(idventa);
                      setOpenModalPago(true);
                    }}
                  >
                    <FontAwesomeIcon icon="dollar-sign" />
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Editar</Tooltip>}
                >
                  <Button
                    variant="falcon-default"
                    size="sm"
                    onClick={() => {
                      setIdVenta(idventa);
                    }}
                  >
                    <FontAwesomeIcon icon="pencil" />
                  </Button>
                </OverlayTrigger>
              </>
            )}

            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Imprimir</Tooltip>}
            >
              <Button
                variant="falcon-default"
                size="sm"
                onClick={() => {
                  setIdVenta(idventa);
                  setOpenModal(true);
                }}
              >
                <FontAwesomeIcon icon="print" />
              </Button>
            </OverlayTrigger>
          </>
        );
      }
    }
  ];
  const handleClose = () => {
    setOpenModal(false);
    setOpenModalPago(false);
  };

  const getVentas = () => {
    const URI = ApiEndpoint + 'getventasreport/';
    setVentas(false);
    var dataToSubmit = {
      al: FormatDateToDb(toFilter),
      del: FormatDateToDb(fromFilter),
      idsucursal: idSucursalFilter,
      idcliente: customerIdFilter,
      idvendedor: 0,
      idstatus: idStatusFilter
    };
    console.log(dataToSubmit);
    axios
      .post(URI, dataToSubmit)
      .then(function (response) {
        setVentas(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <VentasFilters
        filterFolio={filterFolio}
        setFilterFolio={setFilterFolio}
        idStatusFilter={idStatusFilter}
        setIdStatusFilter={setIdStatusFilter}
        fromFilter={fromFilter}
        setFromFilter={setFromFilter}
        toFilter={toFilter}
        action={getVentas}
        setToFilter={setToFilter}
        customerIdFilter={customerIdFilter}
        setCustomerIdFilter={setCustomerIdFilter}
        setIdSucursalFilter={setIdSucursalFilter}
        idSucursalFilter={idSucursalFilter}
      ></VentasFilters>
      <>
        {ventas && (
          <AdvanceTableWrapper
            columns={columns}
            data={ventas || []}
            sortable
            pagination
            perPage={10}
          >
            <Card className="mb-3">
              <Card.Header>
                <Row className="flex-between-center">
                  <Col
                    xs={4}
                    sm="auto"
                    className="d-flex align-items-center pe-0"
                  >
                    <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                      Ventas
                    </h5>
                  </Col>
                  <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                    <div id="orders-actions">
                      <Button
                        size="sm"
                        style={{ width: '100%' }}
                        variant="falcon-primary"
                        as={Link}
                        to="/NewVentas"
                      >
                        Nueva Venta
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="p-0">
                {/* <Row className="flex-end-center mb-3">
            <Col xs="auto" sm={6} lg={4}>
              <AdvanceTableSearchBox table />
            </Col>
          </Row> */}
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    size: 'sm',
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                  }}
                />
              </Card.Body>
              <Card.Footer>
                <AdvanceTablePagination table />
              </Card.Footer>
            </Card>
          </AdvanceTableWrapper>
        )}

        {openModal && (
          <TicketPreview
            id="id_myModal"
            title={'ImprimirVenta'}
            openModal={openModal}
            closeModal={handleClose}
            idVenta={idVenta}
          />
        )}
        {openModalPago && (
          <MyModal
            id="id_myModal"
            title={'Agregar Pago'}
            openModal={openModalPago}
            closeModal={handleClose}
          >
            <NewPayment idVenta={idVenta} closeModal={handleClose} />
            {/* <TicketPreview
            idVenta={1}
            montoAPagar={100}
            closeModal={handleClose}
          /> */}
          </MyModal>
        )}
      </>
    </>
  );
};

export default VentasList;
