import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Button,
  Card,
  Col,
  Dropdown,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ApiEndpoint } from 'utils/ApiEndPont.js';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox.js';
import MyModal from 'utils/Modal.js';
import GenericTableHeader from 'form-components/TableHeaders/GenericTableHeader';
import EditProveedor from 'Catalogos/Configuracion/_EditProveedor';
import EditCliente from './_EditCliente';

const URI = ApiEndpoint + 'clientes/';

const Clientes = () => {
  const [clientes, setClientes] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [idCliente, setIdCliente] = useState(0);

  const columns = [
    {
      accessor: 'idcliente',
      Header: 'Id',
      headerProps: { style: { minWidth: '200px' }, className: 'ps-5' },
      cellProps: { className: 'ps-5' }
    },
    {
      accessor: 'nombre',
      Header: 'Cliente',
      headerProps: { style: { minWidth: '200px' }, className: 'ps-5' },
      cellProps: { className: 'ps-5' }
    },
    {
      accessor: 'razonsocial',
      Header: 'Razon Social',
      disableSortBy: true,
      cellProps: { className: 'ps-5' }
    },
    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: rowData => {
        let idcliente = rowData.row.original.idcliente;
        return (
          <>
            <OverlayTrigger placement="top" overlay={<Tooltip>Editar</Tooltip>}>
              <Button
                variant="falcon-default"
                size="sm"
                onClick={() => {
                  setIdCliente(idcliente);
                  setOpenModal(true);
                }}
              >
                <FontAwesomeIcon icon="pencil-alt" />
              </Button>
            </OverlayTrigger>
          </>
        );
      }
    }
  ];
  const handleClose = () => {
    setOpenModal(false);
    getClientes();
  };

  const getClientes = () => {
    axios.get(URI).then(response => {
      setClientes(response.data);
    });
  };

  useEffect(() => {
    getClientes();
  }, []);
  return (
    <>
      <AdvanceTableWrapper
        columns={columns}
        data={clientes}
        sortable
        pagination
        perPage={10}
      >
        <Card className="mb-3">
          <Card.Header>
            <GenericTableHeader
              label="Clientes"
              newFunction={() => {
                setIdCliente(0);
                setOpenModal(true);
              }}
            />
          </Card.Header>
          <Card.Body className="p-0">
            <Row className="flex-end-center mb-3">
              <Col xs="auto" sm={6} lg={4}>
                <AdvanceTableSearchBox table />
              </Col>
            </Row>
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTablePagination table />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
      {openModal && (
        <MyModal
          id="id_myModal"
          title={(idCliente > 0 ? 'Editar' : 'Agregar') + ' ' + 'Cliente'}
          openModal={openModal}
          closeModal={handleClose}
        >
          <EditCliente idCliente={idCliente} closeModal={handleClose} />
        </MyModal>
      )}
    </>
  );
};

export default Clientes;
