import { createContext, useContext, useMemo, useState } from 'react';
import { useLocalStorage } from './LocalSess';
import { useNavigate } from 'react-router-dom';
import { decryptData, encryptData } from 'utils/Encrypth';
const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [userData, setUserData] = useLocalStorage('loginCrMovil', null);
  const [cajaDataInfo, setCajadataInfo] = useLocalStorage('cajaCrMovil', null);
  const [auth, setAuth] = useState(null);
  const navigate = useNavigate();

  const login = async data => {
    setAuth(true);
    setUserData(encryptData(data));
  };

  const setCajaData = async data => {
    if (data == null) {
      setCajadataInfo(null);
      return;
    }
    setCajadataInfo(encryptData(data));
  };

  const getCajaData = () => {
    if (!cajaDataInfo) return null;
    const dataUserEncr = decryptData(cajaDataInfo);
    return dataUserEncr;
  };

  // call this function to sign out logged in user
  const logout = () => {
    setAuth(false);
    setUserData(null);

    navigate('/', { replace: true });
  };

  const getSessionData = () => {
    if (!userData) return null;
    const dataUserEncr = decryptData(userData);
    return dataUserEncr;
  };
  const value = useMemo(
    () => ({
      userData,
      auth,
      login,
      logout,
      getSessionData,
      setCajaData,
      getCajaData
    }),
    [userData]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
