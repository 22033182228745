import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CloseButton,
  Col,
  Container,
  Modal,
  Row,
  Table
} from 'react-bootstrap';
import { ApiEndpoint } from 'utils/ApiEndPont';
import ReactToPrint from 'react-to-print';
import { FormatCurrency, FormatDate } from 'utils/FormatData';
import { useRef } from 'react';
import Logo from 'assets/img/Logo';
import Flex from 'components/common/Flex';

const TicketPreview = ({ idVenta, closeModal, openModal, title }) => {
  const [venta, setVenta] = useState(null);
  const [ventalDetails, setVentaDetails] = useState(null);

  let componentRef = useRef();
  const handlePrint = () => {
    window.print();
  };

  useEffect(() => {
    console.log('setventa');
    axios.get(ApiEndpoint + 'venta/' + idVenta).then(response => {
      setVenta(response.data);
      getVentaDetails();
    });
  }, []);

  const getVentaDetails = () => {
    axios.get(ApiEndpoint + 'ventadetails/' + idVenta).then(response => {
      getVentaDetailsObject(response.data);
    });
  };
  const getVentaDetailsObject = ventaProductos => {
    const details = [];
    ventaProductos.map(producto => {
      let subProduct = {};
      subProduct.Cantidad = producto.cantidad;
      subProduct.Producto = producto.cat_producto.nombre;
      subProduct.Precio = FormatCurrency(producto.preciounitario);
      subProduct.Importe = FormatCurrency(producto.subimporte);
      details.push(subProduct);
    });
    setVentaDetails(details);
  };

  return (
    <>
      <Modal
        show={openModal}
        onHide={() => closeModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"> {title}</Modal.Title>
          <CloseButton
            className="btn btn-circle btn-sm transition-base p-0"
            onClick={() => closeModal(false)}
          />
        </Modal.Header>
        <Modal.Body>
          {venta && (
            <div ref={componentRef}>
              <Card
                handlePrint={handlePrint}
                style={{ padding: 1, marginLeft: 1 }}
              >
                <div className="printLayoutContainer">
                  <style type="text/css" media="print">
                    {
                      " @page { width: '80mm' } html, body {font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif; color:'black !important'; font-weight: 500 !important; height: auto;font-size: 100%; /* changing to 10pt has no impact */}                "
                    }
                  </style>
                </div>

                <Row sm={12} md={12} style={{ textAlign: 'center' }} item>
                  <Logo style={{ with: '60%', height: '50%' }}></Logo>
                </Row>
                <Row sm={12} md={12} style={{ textAlign: 'center' }} item>
                  <Col className="mt-3 mt-sm-0">
                    <h5>VENTA</h5>
                    <h5>{venta.cat_sucursale.sucursal}</h5>
                    <h5>
                      {venta.cat_sucursale.direccion} <br />{' '}
                      {venta.cat_sucursale.telefono}
                    </h5>
                  </Col>
                </Row>

                <hr />
                <Row>
                  <Col justifyContent="left">
                    <p className=" fw-bold">Folio: {venta.folio}</p>{' '}
                  </Col>
                  <Col justifyContent="right">
                    <p className=" fw-bold">Fecha: {FormatDate(venta.fecha)}</p>
                  </Col>
                </Row>

                <Row className="border border-100">
                  <Flex justifyContent="left" className=" fw-bold">
                    Cliente: {venta.cat_cliente.nombre}
                  </Flex>
                </Row>
                <hr />
                <Row>
                  <Table
                    className="table table-striped table-bordered my-3"
                    aria-label="sticky table"
                  >
                    <thead>
                      <tr style={{ borderBottom: '1px solid black' }}>
                        <th style={{ textAlign: 'left' }} colSpan={4}>
                          Cant / Producto
                        </th>
                        <th style={{ textAlign: 'center' }}>Precio</th>
                        <th style={{ textAlign: 'center' }}>Importe</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ventalDetails &&
                        ventalDetails.map(item => (
                          <tr hover>
                            <th style={{ textAlign: 'left' }} colSpan={4}>
                              {item.Cantidad} / {item.Producto}
                            </th>
                            <th
                              style={{
                                textAlign: 'right'
                              }}
                            >
                              {item.Precio}
                            </th>
                            <th
                              style={{
                                textAlign: 'right'
                              }}
                            >
                              {item.Importe}
                            </th>
                          </tr>
                        ))}
                    </tbody>
                    <tfoot>
                      <tr style={{ borderTop: '1px solid black' }}>
                        <td></td>
                        <td
                          colSpan={4}
                          className="fw-black"
                          style={{ textAlign: 'right' }}
                        >
                          Total
                        </td>
                        <td className="fw-black" style={{ textAlign: 'right' }}>
                          {FormatCurrency(venta.total)}
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                </Row>
                <hr color="DarkGrey" />
                <Row
                  className="fw-black center"
                  style={{
                    textAlign: 'center',
                    alignContent: 'center',
                    alignItems: 'center'
                  }}
                  justifyContent="center"
                >
                  <Row sm={12} md={12} justifyContent="center">
                    {' '}
                    <Flex justifyContent="center" className="p-2 mb-2">
                      {venta.cat_sucursale.sucursal}
                    </Flex>
                  </Row>

                  <Row style={{ textAlign: 'center' }} sm={12} md={12} item>
                    <Flex justifyContent="center" className="p-2 mb-2">
                      {venta.cat_sucursale.email}
                    </Flex>
                  </Row>
                </Row>
              </Card>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <ReactToPrint
            trigger={() => (
              <Button style={{ float: 'right', zIndex: 99, top: 10 }}>
                Imprimir
              </Button>
            )}
            content={() => componentRef.current}
          />
          <Button onClick={() => closeModal()}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TicketPreview;
