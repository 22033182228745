import axios from 'axios';
import Flex from 'components/common/Flex';
import { FormInputText } from 'form-components/FormInputText';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Col, Row, Form, Button, Card } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { v4 as uuidv4 } from 'uuid';
import { ApiEndpoint } from 'utils/ApiEndPont';
import MyModal from 'utils/Modal';
import EditProducto from 'Catalogos/Configuracion/_EditProducto';
const AddProduct = ({ addProduct, setCantidad, cantidad = 1 }) => {
  const [productosList, setProductosList] = useState([]);
  const [idProducto, setIdProducto] = useState(null);
  const [openModalProducto, setOpenModalProducto] = useState(false);
  const [selectedProducto, setSelectedProducto] = useState(null);
  const [producto, setProducto] = useState(null);
  const [productoSmry, setProductoSmry] = useState({
    rowId: uuidv4(),
    idProducto: 0,
    Producto: '',
    Cantidad: 0,
    Costo: 0,
    Codigo: ''
  });
  const [codigo, setCodigo] = useState(0);
  const [costo, setCosto] = useState(0);
  const {
    control,
    formState: { errors }
  } = useForm({
    mode: 'onBlur'
  });

  const handleClose = () => {
    setOpenModalProducto(false);
    getProductosList();
  };

  const getProductosList = () => {
    const uriProducts = ApiEndpoint + 'allproductos/';
    axios(uriProducts).then(({ data }) => {
      const productosListData = data.map(producto => {
        return {
          value: `${producto.id_productos}`,
          label: `${producto.vc_nombre}`
        };
      });
      console.log('productosListData');
      console.log(data);
      setProductosList(productosListData);
    });
  };

  useEffect(() => {
    getProductosList();
  }, []); // empty array makes hook working once

  useEffect(() => {
    if (!codigo && codigo == '') return;

    const uriProducts = ApiEndpoint + 'searchproductos/' + codigo;
    axios(uriProducts).then(({ data }) => {
      if (!data) {
        setProducto(data);
      }
    });
  }, [codigo]);

  useEffect(() => {
    if (!idProducto && idProducto == '') return;

    const uriProducts = ApiEndpoint + 'productos/' + idProducto;
    axios(uriProducts).then(({ data }) => {
      setProducto(data);
      console.log(data);
      setProductoSmry({
        ...productoSmry,
        idProducto: data.idproductos,
        Codigo: data.codigo,
        Producto: data.nombre
      });
    });
  }, [idProducto]);

  const OnChangeCantidad = event => {
    setCantidad(`${event.target.value}`);
    setProductoSmry({ ...productoSmry, Cantidad: `${event.target.value}` });
  };

  const OnChangeCodigo = event => {
    setCodigo(`${event.target.value}`);
    setProductoSmry({ ...productoSmry, Codigo: `${event.target.value}` });
  };

  const OnChangeCosto = event => {
    setCosto(`${event.target.value}`);
    setProductoSmry({ ...productoSmry, Costo: `${event.target.value}` });
  };

  return (
    <>
      <Card.Body>
        <Row>
          <Col class="col-auto">
            <FormInputText
              label="Cantidad"
              changeHandler={OnChangeCantidad}
              name={'cantidad'}
              control={control}
              defaultValue={productoSmry.Cantidad}
            ></FormInputText>
          </Col>
          <Col class="col-auto">
            <FormInputText
              label="Codigo"
              changeHandler={OnChangeCodigo}
              name={'codigo'}
              control={control}
              defaultValue={productoSmry.Codigo}
            ></FormInputText>
          </Col>
          <Col class="col-auto">
            <Form.Group className="mb-3" controlId="idPart">
              <div style={{ width: '450px' }}>
                <Flex className="flex-between-center">
                  <Form.Label>Producto</Form.Label>
                  <Button
                    size="sm"
                    transform="shrink-2"
                    variant="link"
                    className="p-0"
                    onClick={() => setOpenModalProducto(true)}
                  >
                    + Agregar Nuevo
                  </Button>
                </Flex>
                <Select
                  aria-label="Default select"
                  name="producto"
                  size="sm"
                  closeMenuOnSelect={true}
                  classNamePrefix="react-select"
                  value={selectedProducto}
                  onChange={value => {
                    setIdProducto(value.value);
                    setSelectedProducto(value);
                  }}
                  options={productosList}
                ></Select>
              </div>
            </Form.Group>
          </Col>

          <Col class="col-auto">
            <FormInputText
              label="Costo Unitario"
              changeHandler={OnChangeCosto}
              name={'costo'}
              control={control}
              defaultValue={productoSmry.Costo}
            ></FormInputText>
          </Col>
          <Col class="col-auto" style={{ textAlign: 'right' }}>
            <Button
              onClick={() => {
                setProductoSmry({ ...productoSmry, rowId: uuidv4() });
                addProduct(productoSmry);
                setProductoSmry({
                  rowId: uuidv4(),
                  idProducto: 0,
                  Producto: '',
                  Cantidad: 0,
                  Costo: 0,
                  Codigo: ''
                });
              }}
              disabled={!(!!idProducto && idProducto > 0 && cantidad > 0)}
              color="primary"
              size="lg"
            >
              Agregar
            </Button>
          </Col>
        </Row>
      </Card.Body>
      {openModalProducto && (
        <MyModal
          id="id_myModal"
          title="Agregar Producto"
          openModal={openModalProducto}
          closeModal={handleClose}
        >
          <EditProducto idProducto={0} closeModal={handleClose} />
        </MyModal>
      )}
    </>
  );
};

export default AddProduct;
