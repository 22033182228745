import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Main from './Main';
import 'helpers/initFA';
import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Main>
        <App />
      </Main>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('main')
);
