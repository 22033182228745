import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Table } from 'react-bootstrap';
import { FormatCurrency } from 'utils/FormatData';
const DetailFallas = ({
  tr_reparacionfallas,
  setDeleteFalla,
  setDeleteFallaId
}) => {
  return (
    <>
      {tr_reparacionfallas.map(detail => (
        <tr style={{ height: '50px' }} key={detail.rowId}>
          <td className="align-middle">
            <h6 className="align-left text-left">{detail.imei}</h6>
          </td>
          <td className="align-left text-left">{detail.equipo}</td>

          <td className="align-left text-left">{detail.falla}</td>

          <td className="align-middle text-end">
            {FormatCurrency(detail.importe)}
          </td>
          <td>
            <Button
              variant="falcon-default"
              size="sm"
              onClick={() => {
                console.log(detail.idreparacionfalla);
                setDeleteFalla(true);
                setDeleteFallaId(detail.idreparacionfalla);
              }}
            >
              <FontAwesomeIcon icon="trash-alt" />
            </Button>
          </td>
        </tr>
      ))}
    </>
  );
};

const DetailReparacionEquiposList = ({
  detallesList,
  setDeleteFalla,
  setDeleteFallaId
}) => {
  return (
    <>
      {
        <div>
          <Table responsive striped hover style={{ minHeight: '300px' }}>
            <thead className="light">
              <tr className="bg-primary text-white dark__bg-1000">
                <th scope="border-0">IMEI</th>
                <th scope="border-0">Modelo</th>
                <th scope="border-0">Falla</th>
                <th scope="border-0">Estimado</th>
                <th scope="border-0"></th>
              </tr>
            </thead>
            <tbody>
              {detallesList.map(equipo => (
                <>
                  <tr style={{ height: '50px' }} key={equipo.rowId}>
                    <td className="align-middle">
                      <h6 className="align-left text-left">{equipo.imei}</h6>
                    </td>
                    <td className="align-left text-left">
                      {equipo.cat_modelo.modelo}
                    </td>

                    <td className="align-left text-left">{equipo.falla}</td>

                    <td className="align-middle text-end">
                      {FormatCurrency(equipo.estimado)}
                    </td>

                    <td className="text-end">
                      <Button
                        variant="falcon-default"
                        size="sm"
                        onClick={() => {
                          console.log(equipo.rowId);
                          // setDeleteProduct(detail.rowId);
                        }}
                      >
                        <FontAwesomeIcon icon="check" />
                      </Button>
                      <Button
                        variant="falcon-default"
                        size="sm"
                        onClick={() => {
                          console.log(equipo.rowId);
                          // setDeleteProduct(detail.rowId);
                        }}
                      >
                        <FontAwesomeIcon icon="pencil-alt" />
                      </Button>
                      <Button
                        variant="falcon-default"
                        size="sm"
                        onClick={() => {
                          console.log(equipo.rowId);
                          // setDeleteProduct(detail.rowId);
                        }}
                      >
                        <FontAwesomeIcon icon="trash-alt" />
                      </Button>
                    </td>
                  </tr>
                  {equipo.tr_reparacionfallas && (
                    <DetailFallas
                      tr_reparacionfallas={equipo.tr_reparacionfallas}
                      setDeleteFalla={setDeleteFalla}
                      setDeleteFallaId={setDeleteFallaId}
                    ></DetailFallas>
                  )}
                </>
              ))}

              <tr>
                <td>
                  {' '}
                  <h6 className="align-left text-left"> </h6>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </Table>
        </div>
      }
    </>
  );
};

export default DetailReparacionEquiposList;
