import IconButton from 'components/common/IconButton';
import PageHeader from 'components/common/PageHeader';
import React, { useEffect, useState } from 'react';
import SimpleBarReact from 'simplebar-react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { ApiEndpoint } from 'utils/ApiEndPont';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { FormatCurrency, FormatDate } from 'utils/FormatData';
import DetailReparacionEquiposList from './DetailReparacionEquiposList';
import DetailReparacionCambios from './DetailReparacionCambios';
import MyModal from 'utils/Modal';
import ReparacionComentario from './_ReparacionComentario';
import ReparacionFalla from './_ReparacionFalla';
import ConfirmAction from 'form-components/ConfirmationModal';
import ReparacionPreview from './ReparacionPreview';
import AsignTech from './_AsignTech';
import NewPaymentRep from './NewPaymentRep';

const DetailReparacion = ({}) => {
  let { id } = useParams();
  const [reparacion, setReparacion] = useState(null);
  const [reparacionEquipos, setReparacionEquipos] = useState(null);
  const [openComentario, setOpenComentario] = useState(false);
  const [openAsign, setOpenAsign] = useState(false);
  const [openFalla, setOpenFalla] = useState(false);
  const [deleteFalla, setDeleteFalla] = useState(false);
  const [deleteFallaId, setDeleteFallaId] = useState(0);
  const [finishRep, setFinishRep] = useState(false);
  const [openModalPago, setOpenModalPago] = useState(false);

  const [openConfirm, setOpenConfirm] = useState(null);
  const [openPrint, setOpenPrint] = useState(null);
  const handleClose = () => {
    setOpenPrint(false);
    setOpenComentario(false);
    setOpenFalla(false);
    setOpenAsign(false);
    getReparacion();
  };

  const handleCloseConfirm = () => {
    setDeleteFalla(false);
    setOpenConfirm(false);
    setFinishRep(false);
    getReparacion();
  };
  const getReparacion = () => {
    const uriModels = ApiEndpoint + 'reparacion/' + id;
    axios(uriModels).then(({ data }) => {
      setReparacion(data);
    });
  };

  const DeleteConfirmed = isConfirmed => {
    console.log(isConfirmed);
    if (!isConfirmed) {
      return;
    }

    axios
      .delete(ApiEndpoint + 'reparaciones/deletefalla/' + deleteFallaId)
      .then(function (response) {
        if (response.data.error) {
          // setAlertMessage(i18next.t('label.Error'));
          // setTypeAlert('warning');
          // setOpenAlert(true);
          return;
        }
        // setAlertMessage('Registro eliminado con exito');
        // setTypeAlert('success');
        // setOpenAlert(true);
        // getCommonFailuresList();
        handleCloseConfirm();
        getReparacion();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const ConfirmFinish = isConfirmed => {
    console.log(isConfirmed);
    if (!isConfirmed) {
      return;
    }

    axios
      .post(ApiEndpoint + 'reparaciones/finish/', {
        reparacion: id
      })
      .then(function (response) {
        if (response.data.error) {
          // setAlertMessage(i18next.t('label.Error'));
          // setTypeAlert('warning');
          // setOpenAlert(true);
          return;
        }
        // setAlertMessage('Registro eliminado con exito');
        // setTypeAlert('success');
        // setOpenAlert(true);
        // getCommonFailuresList();
        handleCloseConfirm();
        getReparacion();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getReparacionEquipos = () => {
    const uriModels = ApiEndpoint + 'reparaciones/getequipos/' + id;
    axios(uriModels).then(({ data }) => {
      setReparacionEquipos(data);
    });
  };

  useEffect(() => {
    if (deleteFalla) setOpenConfirm(true);
  }, [deleteFalla]);

  useEffect(() => {
    getReparacion();
    getReparacionEquipos();
  }, []); // empty array makes hook working once

  return (
    <>
      {reparacion && (
        <>
          <Card className="mb-3">
            <Card.Body>
              <Row className="justify-content-between align-items-center">
                <Col md>
                  <h5 className="mb-2 mb-md-0">Order #AD20294</h5>
                </Col>
                <Col xs="auto">
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="arrow-down"
                    className="me-1 mb-2 mb-sm-0"
                    iconClassName="me-1"
                  >
                    Download (.pdf)
                  </IconButton>
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="print"
                    iconClassName="me-1"
                    className="me-1 mb-2 mb-sm-0"
                    onClick={() => {
                      setOpenPrint(true);
                    }}
                  >
                    Imprimir Orden de Reparacion
                  </IconButton>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Row>
            <Col xs={9}>
              <Card className="mb-3">
                <Card.Body>
                  <Row className="align-items-center">
                    <Col>
                      <h6 className="text-500">Cliente</h6>
                      <h5>{reparacion.cat_cliente.nombre}</h5>
                      <p className="fs--1">
                        {reparacion.cat_cliente.direccion}
                      </p>
                      <p className="fs--1">
                        {reparacion.cat_cliente.email}
                        <br />
                        {reparacion.cat_cliente.telefono}
                      </p>
                    </Col>
                    <Col sm="auto" className="ms-auto">
                      <div className="table-responsive">
                        <Table borderless size="sm" className="fs--1">
                          <tbody>
                            <tr>
                              <th className="text-sm-end">Reparacion No:</th>
                              <td>{reparacion.folio}</td>
                            </tr>
                            <tr>
                              <th className="text-sm-end">Recepcion:</th>
                              <td>{FormatDate(reparacion.fecharecepcion)}</td>
                            </tr>
                            {reparacion.fechaestimada && (
                              <tr>
                                <th className="text-sm-end">
                                  Fecha de Entrega:
                                </th>
                                <td>
                                  {FormatDate(
                                    reparacion.reparacion ??
                                      reparacion.fechaestimada
                                  )}
                                </td>
                              </tr>
                            )}
                            <tr className="alert-success fw-bold">
                              <th className="text-sm-end">Estimado:</th>
                              <td>{reparacion.total}</td>
                            </tr>
                            <tr className="alert-primary fw-bold">
                              <th className="text-sm-end">Anticipo:</th>
                              <td>{reparacion.anticipo}</td>
                            </tr>{' '}
                            <tr className="border-top border-top-2 fw-bolder text-900">
                              <th>Cobrar:</th>
                              <td>
                                {FormatCurrency(
                                  reparacion.total * 1.16 - reparacion.anticipo
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>

                  <div className="mt-4 fs--1">
                    <SimpleBarReact>
                      {reparacionEquipos && (
                        <DetailReparacionEquiposList
                          setDeleteFalla={setDeleteFalla}
                          detallesList={reparacionEquipos}
                          setDeleteFallaId={setDeleteFallaId}
                        ></DetailReparacionEquiposList>
                      )}
                    </SimpleBarReact>
                  </div>

                  <Row className="justify-content-end">
                    <Col xs="auto">
                      <Table borderless size="sm" className="fs--1 text-end">
                        <tbody>
                          <tr>
                            <th className="text-900">Subtotal:</th>
                            <td className="fw-semi-bold">
                              {FormatCurrency(reparacion.total)}
                            </td>
                          </tr>
                          <tr>
                            <th className="text-900">IVA:</th>
                            <td className="fw-semi-bold">
                              {FormatCurrency(reparacion.total * 0.16)}
                            </td>
                          </tr>
                          <tr className="border-top">
                            <th className="text-900">Total:</th>
                            <td className="fw-semi-bold">
                              {FormatCurrency(reparacion.total * 1.16)}
                            </td>
                          </tr>
                          <tr className="border-top border-top-2 fw-bolder text-900">
                            <th>Resta:</th>
                            <td>
                              {FormatCurrency(
                                reparacion.total * 1.16 - reparacion.anticipo
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer className="bg-light">
                  <Row>
                    <Col>
                      {reparacion && reparacion.idstatusreparacion < 3 && (
                        <IconButton
                          variant="falcon-success"
                          size="sm"
                          icon="check"
                          className="mb-2 mb-sm-0"
                          onClick={() => {
                            setFinishRep(true);
                          }}
                        >
                          Cerrar Reparacion
                        </IconButton>
                      )}
                    </Col>
                    <Col>
                      {reparacion && reparacion.idstatusreparacion == 3 && (
                        <IconButton
                          variant="falcon-success"
                          size="sm"
                          icon="check"
                          className="mb-2 mb-sm-0"
                          onClick={() => {
                            setOpenModalPago(true);
                          }}
                        >
                          Cobrar
                        </IconButton>
                      )}
                    </Col>
                    <Col>
                      {reparacion && reparacion.idstatusreparacion < 3 && (
                        <IconButton
                          variant="falcon-success"
                          size="sm"
                          icon="check"
                          className="mb-2 mb-sm-0"
                          onClick={() => {
                            setOpenAsign(true);
                          }}
                        >
                          Asignar Tecnico
                        </IconButton>
                      )}
                    </Col>
                    <Col></Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>
            <Col>
              <Card className="mb-3">
                <Card.Body>
                  <Row className="align-items-center text-center mb-3">
                    <Col sm={6} className="text-sm-start">
                      {/* <img src={logoInvoice} alt="invoice" width={150} /> */}
                    </Col>
                    <Col className="text-sm-end mt-3 mt-sm-0">
                      <h3 className="mb-3">
                        {reparacion.cat_statusreparacion.status}{' '}
                        <p className="fs--1 mb-0">
                          Tecnico {reparacion.cat_tecnico?.nombre}
                        </p>
                      </h3>
                      <h5>{reparacion.cat_sucursale.sucursal}</h5>
                      <p className="fs--1 mb-0">
                        Recibio {reparacion.cat_vendedor.nombre}
                      </p>
                    </Col>
                    <Col xs={12}>
                      <hr />
                    </Col>
                  </Row>
                  <div className="mt-4 fs--1">
                    <SimpleBarReact>
                      <DetailReparacionCambios
                        setOpenComentario={setOpenComentario}
                        setOpenFalla={setOpenFalla}
                        idReparacion={id}
                      ></DetailReparacionCambios>
                    </SimpleBarReact>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
      {openAsign && (
        <MyModal
          id="id_myModal"
          title={'Asignar Tecnico'}
          openModal={openAsign}
          closeModal={handleClose}
        >
          <AsignTech idReparacion={id} closeModal={handleClose}></AsignTech>
        </MyModal>
      )}

      {openComentario && (
        <MyModal
          id="id_myModal"
          title={'Agregar Comentario'}
          openModal={openComentario}
          closeModal={handleClose}
        >
          <ReparacionComentario idReparacion={id} closeModal={handleClose} />
          {/* <TicketPreview
            idVenta={1}
            montoAPagar={100}
            closeModal={handleClose}
          /> */}
        </MyModal>
      )}
      {openFalla && (
        <MyModal
          id="id_myModal"
          title={'Agregar Falla'}
          openModal={openFalla}
          closeModal={handleClose}
        >
          <ReparacionFalla
            idReparacionEquipo={
              reparacion.tr_reparacion_equipos[0].idreparacionequipo
            }
            closeModal={handleClose}
          />
          {/* <TicketPreview
            idVenta={1}
            montoAPagar={100}
            closeModal={handleClose}
          /> */}
        </MyModal>
      )}
      {openConfirm && (
        <ConfirmAction
          message={'Desea eliminar el registro?'}
          title={'Confirmacion'}
          handleClose={handleCloseConfirm}
          open={openConfirm}
          ConfirmAction={DeleteConfirmed}
        ></ConfirmAction>
      )}
      {finishRep && (
        <ConfirmAction
          message={'Desea Concluir la Reparacion?'}
          title={'Confirmacion'}
          handleClose={handleCloseConfirm}
          open={finishRep}
          ConfirmAction={ConfirmFinish}
        ></ConfirmAction>
      )}
      {openPrint && (
        <MyModal
          id="id_myModal_print"
          title={'Orden de Reparacion'}
          openModal={openPrint}
          closeModal={handleClose}
        >
          <ReparacionPreview idReparacion={id} closeModal={handleClose} />
        </MyModal>
      )}
      {openModalPago && (
        <MyModal
          id="id_myModal"
          title={'Agregar Pago a Reparacion'}
          openModal={openModalPago}
          closeModal={handleClose}
        >
          <NewPaymentRep idReparacion={id} closeModal={handleClose} />
          {/* <TicketPreview
            idVenta={1}
            montoAPagar={100}
            closeModal={handleClose}
          /> */}
        </MyModal>
      )}
    </>
  );
};

export default DetailReparacion;
