import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { ApiEndpoint } from 'utils/ApiEndPont';

const AsignTech = ({ idReparacion, closeModal }) => {
  const [options, setOptions] = useState([]);
  const [selectedTech, setSelectedTech] = useState(null);
  const [idSelectedTech, setIdSelectedTech] = useState(null);
  const [validated, setValidated] = useState(false);

  const [values, setValues] = useState({
    idTech: ''
  });

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      idTech: ''
    }
  });

  useEffect(() => {
    const uriMarcas = ApiEndpoint + 'tecnicos/';
    axios(uriMarcas).then(({ data }) => {
      console.log(uriMarcas);
      const tiposClientesList = data.map(tipo => {
        return {
          value: `${tipo.idtecnico}`,
          label: `${tipo.nombre}`
        };
      });
      setOptions(tiposClientesList);
    });
  }, []); // empty array makes hook working once

  const onSubmit = (data, e) => {
    const form = e.target;
    if (form.checkValidity() === false) {
      console.log('entre a submit 123');
      setValidated(true);
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    const URI = ApiEndpoint + 'reparaciones/asign';
    setValidated(true);

    axios
      .post(URI, {
        idtech: idSelectedTech,
        reparacion: idReparacion
      })
      .then(function (response) {
        console.log(response);
        closeModal();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <Card style={{ width: '100%' }}>
        <Card.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="idPart">
                  <Form.Label>Tecnico</Form.Label>
                  <div style={{ width: '450px' }}>
                    <Select
                      aria-label="Default select"
                      name="selectedTech"
                      size="sm"
                      closeMenuOnSelect={true}
                      classNamePrefix="react-select"
                      value={selectedTech}
                      onChange={value => {
                        setIdSelectedTech(`${value.value}`);
                        setSelectedTech(value);
                      }}
                      options={options}
                    ></Select>
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <Button type="submit" color="primary" size="sm">
              Guardar
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default AsignTech;
