import IconButton from 'components/common/IconButton';
import { Button } from 'react-bootstrap';
import { IsNumber } from 'utils/FormatData';
import * as XLSX from 'xlsx';

const BtnExportToExcell = ({ dataReport, fileName }) => {
  const downloadExcel = data => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, fileName + '.xlsx');
  };

  return (
    <>
      <IconButton
        variant="falcon-default"
        size="sm"
        icon="plus"
        transform="shrink-3"
        onClick={() => downloadExcel(dataReport)}
        //startIcon={<DownloadIcon fontSize="small" />}
        sx={{ mr: 1 }}
      >
        Export
      </IconButton>
    </>
  );
};
export default BtnExportToExcell;
