import EditCliente from 'Catalogos/Administracion/_EditCliente';
import axios from 'axios';
import FalconComponentCard from 'components/common/FalconComponentCard';
import Flex from 'components/common/Flex';
import PageHeader from 'components/common/PageHeader';
import { useAuth } from 'context/AuthProvider';
import { FormInputText } from 'form-components/FormInputText';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { ApiEndpoint } from 'utils/ApiEndPont';
import MyModal from 'utils/Modal';
import EquiposList from './EquiposList';
import IconButton from 'components/common/IconButton';

const Ingreso = () => {
  const Auth = useAuth();
  let navigate = useNavigate();
  const [customer, setCustomer] = useState(1);
  const [vendedoresList, setVendedoresList] = useState([]);
  const [customersList, setCustomersList] = useState([]);
  const [modelsList, setModelsList] = useState([]);
  const [items, setItems] = useState([]);
  const [messageError, setMessageError] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [idCliente, setIdCliente] = useState(0);
  const [vendedor, setVendedor] = useState(1);
  const [selectedModel, setSelectedModel] = useState(0);
  const [telefono, setTelefono] = useState(0);
  const [values, setValues] = useState({
    imei: '',
    model: '0',
    falla: '',
    estadoequipo: '',
    estimado: '0',
    anticipo: '0',
    telefono: '',
    contacto: '',
    comentarioFalla: '',
    total: '0'
  });
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      imei: '',
      model: '0',
      falla: '',
      estadoequipo: '',
      estimado: '0',
      comentarioFalla: '',
      rowId: '0'
    }
  });
  const handleClose = () => {
    setOpenModal(false);
    getCustomers();
    getModels();
  };

  const createReparacion = () => {
    if (!items || items.length == 0) {
      setMessageError('Debe agregar al menos 1 articulo');
      return;
    }
    console.log('values');
    console.log(values);
    var cajaData = Auth.getCajaData();
    var dataToSubmit = {
      idsucursal: Auth.getSessionData().id_sucursal,
      idvendedor: vendedor.value,
      idcliente: customer.value,
      comentarios: '',
      nombrecliente: customer.label,
      telefono: values.contacto,
      subtotal: getTotalVenta(),
      iva: 0,
      total: getTotalVenta() + 0,
      Details: items,
      anticipo: values.anticipo,
      //ventaid: uuidv4(),
      idapertura: cajaData.idapertura,
      iduser: Auth.getSessionData().id_user
    };

    const URI = ApiEndpoint + 'createreparacion/';
    axios
      .post(URI, dataToSubmit)
      .then(function (response) {
        console.log(response);
        if (!response.error) {
          navigate('/Ventas');
        }
        // setIdVentaNew(response.data.id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getVendedores = () => {
    const uriParts = ApiEndpoint + 'vendedores/';
    axios(uriParts).then(({ data }) => {
      const partList = data.map(parts => {
        return {
          value: `${parts.idvendedor}`,
          label: `${parts.nombre}`
        };
      });
      setVendedoresList(partList);
      setVendedor(partList[0]);
    });
  };

  const getCustomers = () => {
    const uriParts = ApiEndpoint + 'clientes/';
    axios(uriParts).then(({ data }) => {
      const partList = data.map(parts => {
        return {
          value: `${parts.idcliente}`,
          label: `${parts.nombre}`
        };
      });
      setCustomersList(partList);
      setCustomer(partList[0]);
    });
  };

  const getModels = () => {
    const uriModels = ApiEndpoint + 'modelos/';
    axios(uriModels).then(({ data }) => {
      const models = data.map(model => {
        return {
          value: `${model.idmodelo}`,
          label: `${model.cat_marca.marca} - ${model.modelo}`
        };
      });
      setModelsList(models);
    });
  };

  const addItem = () => {
    var listItems = items;
    console.log(values);
    items.push(values);
    setItems(items);
    setValues({
      imei: '',
      model: '0',
      falla: '',
      estadoequipo: '',
      equipo: '',
      estimado: '0',
      comentarioFalla: '',
      telefono: ''
    });
  };

  useEffect(() => {
    getCustomers();
    getModels();

    getVendedores();
  }, []); // empty array makes hook working once

  const getTotalVenta = () =>
    items.reduce((total, item) => Number(total) + Number(item.estimado), 0);

  const onChange = event => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  return (
    <>
      <PageHeader
        title={
          'Nueva Reparacion ' // + String('0000000' + 123).slice(-7)
        }
        titleTag="h5"
        className="mb-3"
      ></PageHeader>
      <Card className="mb-3">
        <Card.Header>
          <Row className="flex-between-center">
            <Col sm="auto">
              <h5 className="mb-0">Datos de Cliente</h5>
            </Col>
            <Col sm="auto">
              <IconButton
                onClick={() => {
                  setIdCliente(0);
                  setOpenModal(true);
                }}
                iconClassName="me-2"
                variant="falcon-default"
                size="sm"
                icon="plus"
                transform="shrink-2"
              >
                Agregar Cliente
              </IconButton>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Row className="align-items-center">
            <Col md={10}>
              <Form.Group className="mb-2" controlId="customer">
                <Form.Label>Cliente</Form.Label>
                <Select
                  aria-label="Default select"
                  name="customer"
                  size="sm"
                  closeMenuOnSelect={true}
                  classNamePrefix="react-select"
                  value={customer}
                  defaultValue={customer}
                  onChange={value => {
                    setCustomer(value);
                  }}
                  options={customersList}
                ></Select>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <FormInputText
                label="Contacto"
                size="sm"
                changeHandler={event => {
                  onChange(event);
                  setTelefono(event.value);
                }}
                name={'contacto'}
                control={control}
                defaultValue={values.contacto}
                value={values.contacto}
              ></FormInputText>
            </Col>
            <Col md={6} className="mb-4 mb-lg-0">
              <Form.Group className="mb-3" controlId="idPart">
                <Form.Label>Vendedor</Form.Label>
                <div style={{ width: '450px' }}>
                  <Select
                    aria-label="Default select"
                    name="vendedor"
                    size="sm"
                    closeMenuOnSelect={true}
                    classNamePrefix="react-select"
                    value={vendedor}
                    defaultValue={vendedor}
                    onChange={value => {
                      setVendedor(value);
                    }}
                    options={vendedoresList}
                  ></Select>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mb-3">
        <Card.Header>Equipos</Card.Header>
        <Card.Body>
          <Row>
            <Col md={6}>
              <FormInputText
                label="IMEI"
                size="sm"
                changeHandler={onChange}
                name={'imei'}
                control={control}
                defaultValue={values.imei}
              ></FormInputText>
            </Col>
            <Col md={6}>
              <Form.Group controlId="idPart">
                <Flex className="flex-between-center">
                  <Form.Label>Modelo</Form.Label>
                  <Button
                    size="sm"
                    transform="shrink-2"
                    variant="link"
                    className="p-0"
                  >
                    + Agregar
                  </Button>
                </Flex>
                <Select
                  aria-label="Default select"
                  name="model"
                  size="sm"
                  closeMenuOnSelect={true}
                  classNamePrefix="react-select"
                  value={selectedModel}
                  defaultValue={selectedModel}
                  onChange={value => {
                    setValues({
                      ...values,
                      ['equipo']: value.label,
                      ['model']: value.value
                    });
                    console.log(value);
                    setSelectedModel(value);
                  }}
                  options={modelsList}
                ></Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormInputText
                label="Telefono"
                size="sm"
                changeHandler={event => {
                  setTelefono(event.value);
                  onChange(event);
                }}
                name={'telefono'}
                control={control}
                defaultValue={values.telefono}
                value={values.telefono}
              ></FormInputText>
            </Col>
            <Col md={6}>
              <FormInputText
                label="Falla"
                size="sm"
                changeHandler={onChange}
                name={'falla'}
                control={control}
                defaultValue={values.falla}
              ></FormInputText>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <FormInputText
                label="Comentarios Equipo"
                size="sm"
                changeHandler={onChange}
                name={'estadoequipo'}
                control={control}
                defaultValue={values.estadoequipo}
                value={values.estadoequipo}
              ></FormInputText>
            </Col>
          </Row>
          <Row>
            <Col md={6}></Col>
            <Col md={6}>
              <FormInputText
                label="Estimado"
                size="sm"
                changeHandler={onChange}
                name={'estimado'}
                control={control}
                defaultValue={values.estimado}
              ></FormInputText>
            </Col>
          </Row>
          <Row>
            <Col md={10}></Col>
            <Col>
              <Button
                onClick={() => {
                  addItem();
                }}
                color="success"
                size="sm"
                variant="primary"
                transform="shrink-3"
                className="rounded-pill me-2 mb-1"
              >
                Agregar Equipo
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Row>
        <h3 className="text-success fw-light">
          <span className="fw-bold">{messageError}</span>
        </h3>
      </Row>
      <Row>
        <Col md={12} className="mb-4 mb-lg-0">
          <div className="flex-1">
            <h4 className="text-primary mb-0">
              <Flex justifyContent="center" className="p-2 mb-2">
                {items && items > 0 && (
                  <>
                    <Button
                      onClick={() => {}}
                      color="success"
                      size="lg"
                      variant="primary"
                      transform="shrink-3"
                      className="rounded-pill me-2 mb-1"
                    >
                      Guardar y Cobrar
                    </Button>
                    <Button
                      onClick={() => {}}
                      color="primary"
                      size="lg"
                      variant="info"
                      transform="shrink-3"
                      className="rounded-pill me-2 mb-1"
                    >
                      Guardar
                    </Button>
                  </>
                )}
              </Flex>
            </h4>
          </div>
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col lg={12}>
          <FalconComponentCard
            noGuttersBottom
            style={{ minHeight: 500, maxHeight: 500, overflow: 'auto' }}
          >
            {items && <EquiposList detallesList={items}></EquiposList>}
          </FalconComponentCard>
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <FalconComponentCard noGuttersBottom>
          <Col></Col>
          <Col lg={6}>
            <FormInputText
              label="Total"
              size="sm"
              changeHandler={onChange}
              name={'total'}
              control={control}
              defaultValue={values.total}
            ></FormInputText>
          </Col>
        </FalconComponentCard>
      </Row>
      <Row className="g-3 mb-3">
        <FalconComponentCard noGuttersBottom>
          <Col></Col>
          <Col lg={6}>
            <FormInputText
              label="Anticipo"
              size="sm"
              changeHandler={onChange}
              name={'anticipo'}
              control={control}
              defaultValue={values.anticipo}
            ></FormInputText>
          </Col>
        </FalconComponentCard>
      </Row>
      <Row>
        <Button
          onClick={() => {
            createReparacion();
          }}
          color="success"
          size="lg"
          variant="primary"
          transform="shrink-3"
          className="rounded-pill me-2 mb-1"
        >
          Guardar y Cobrar
        </Button>
      </Row>
      {openModal && (
        <MyModal
          id="id_myModal"
          title={(idCliente > 0 ? 'Editar' : 'Agregar') + ' ' + 'Cliente'}
          openModal={openModal}
          closeModal={handleClose}
        >
          <EditCliente idCliente={idCliente} closeModal={handleClose} />
        </MyModal>
      )}
    </>
  );
};

export default Ingreso;
