import { useEffect, useState, useMemo } from 'react';
import { set, useForm } from 'react-hook-form';
import axios from 'axios';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import AlertNotification from 'form-components/AlertNotification';
import { ApiEndpoint } from 'utils/ApiEndPont';
import { FormInputText } from 'form-components/FormInputText';

const URI = ApiEndpoint + 'sucursales/';

export default function EditSucursal({ idSucursal, closeModal }) {
  const [alertMessage, setAlertMessage] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState('success');

  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);

  const [values, setValues] = useState({
    sucursal: '',
    direccion: '0',
    telefono: '',
    email: '',
    activo: '',
    idempresa: '1'
  });
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  // const [categoriesFailure] = useSymptomsCategory ();
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      sucursal: '',
      direccion: '0',
      telefono: '',
      email: '',
      activo: '',
      idempresa: '1'
    }
  });

  useEffect(() => {
    if (idSucursal > 0) {
      axios.get(URI + idSucursal).then(response => {
        setValues(response.data);
      });
    }
  }, []);

  const onSubmit = (data, e) => {
    const form = e.target;
    if (form.checkValidity() === false) {
      console.log('entre a submit 123');
      setValidated(true);
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    const URI = ApiEndpoint + 'sucursales/';
    setValidated(true);
    if (idSucursal > 0) {
      axios
        .put(URI + idSucursal, {
          sucursal: values.sucursal,
          direccion: values.direccion,
          telefono: values.telefono,
          email: values.email,
          activo: values.activo,
          idempresa: 1
        })
        .then(function (response) {
          console.log(response);
          closeModal();
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      axios
        .post(URI, {
          sucursal: values.sucursal,
          direccion: values.direccion,
          telefono: values.telefono,
          email: values.email,
          activo: values.activo,
          idempresa: 1
        })
        .then(function (response) {
          console.log(response);
          closeModal();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const onChange = event => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  return (
    <>
      <Card style={{ width: '100%' }}>
        <Card.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col>
                <FormInputText
                  label="Sucursal"
                  changeHandler={onChange}
                  name={'sucursal'}
                  control={control}
                  defaultValue={values.sucursal}
                ></FormInputText>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormInputText
                  label="Direccion"
                  changeHandler={onChange}
                  name={'direccion'}
                  control={control}
                  defaultValue={values.direccion}
                ></FormInputText>
              </Col>
              <Col>
                <FormInputText
                  label="Telefono"
                  changeHandler={onChange}
                  name={'telefono'}
                  control={control}
                  defaultValue={values.telefono}
                ></FormInputText>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormInputText
                  label="Email"
                  changeHandler={onChange}
                  name={'email'}
                  control={control}
                  defaultValue={values.email}
                ></FormInputText>
              </Col>
            </Row>

            <Button type="submit" color="primary" size="sm">
              Guardar
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
}
