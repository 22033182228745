import axios from 'axios';
import Flex from 'components/common/Flex';
import { FormInputText } from 'form-components/FormInputText';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Col, Row, Form, Button, Card } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { ApiEndpoint } from 'utils/ApiEndPont';

const AddQuickProduct = ({ addProduct, setCantidad, cantidad = 1 }) => {
  const [productosList, setProductosList] = useState([]);
  const [idProducto, setIdProducto] = useState(0);
  const [selectedProducto, setSelectedProducto] = useState(0);
  const [producto, setProducto] = useState(0);
  const [codigo, setCodigo] = useState(0);
  const {
    control,
    formState: { errors }
  } = useForm({
    mode: 'onBlur'
  });
  const getProductosList = () => {
    const uriProducts = ApiEndpoint + 'activeproductos/' + 1;
    axios(uriProducts).then(({ data }) => {
      const productosListData = data.map(producto => {
        return {
          value: `${producto.id_productos}`,
          label: `${producto.vc_nombre}`
        };
      });
      setProductosList(productosListData);
    });
  };

  useEffect(() => {
    getProductosList();
  }, []); // empty array makes hook working once

  // useEffect(() => {
  //   if (!codigo && codigo == '') return;

  //   const uriProducts = ApiEndpoint + 'searchproductos/' + codigo;
  //   axios(uriProducts).then(({ data }) => {
  //     if (!data) {
  //       setProducto(data);
  //     }
  //   });
  // }, [codigo]);

  useEffect(() => {
    if (!idProducto && idProducto == '') return;

    const uriProducts = ApiEndpoint + 'productos/' + idProducto;
    axios(uriProducts).then(({ data }) => {
      setProducto(data);
    });
  }, [idProducto]);

  const OnChangeCantidad = event => {
    setCantidad(`${event.target.value}`);
  };

  const OnChangeCodigo = event => {
    setCodigo(`${event.target.value}`);
  };

  const ClearAll = () => {
    setCodigo(null);
    setCantidad(1);
    setProducto(null);
    setIdProducto(0);
    setSelectedProducto(0);
  };
  function onKeyPressBarcode(event) {
    if (event.keyCode == 13) {
      if (!codigo && codigo == '') return;

      const uriProducts = ApiEndpoint + 'searchproductos/' + codigo;
      axios(uriProducts).then(({ data }) => {
        if (data) {
          data.cantidad = cantidad;
          setProducto(data);
          addProduct(data);
          ClearAll();
        }
      });
    }
  }
  return (
    <Card.Body>
      <Row>
        <Col xs={1}>
          <FormInputText
            label="Cantidad"
            changeHandler={OnChangeCantidad}
            name={'cantidad'}
            control={control}
            defaultValue={cantidad}
          ></FormInputText>
        </Col>
        <Col xs={2}>
          <FormInputText
            label="Codigo"
            changeHandler={OnChangeCodigo}
            onKeyDown={onKeyPressBarcode}
            name={'codigo'}
            control={control}
            defaultValue={codigo}
          ></FormInputText>
        </Col>
        <Col xs={8}>
          <Form.Group className="mb-3" controlId="idPart">
            <Form.Label>Producto</Form.Label>
            <div style={{ width: '450px' }}>
              <Select
                aria-label="Default select"
                name="producto"
                size="sm"
                closeMenuOnSelect={true}
                classNamePrefix="react-select"
                value={selectedProducto}
                onChange={value => {
                  setIdProducto(value.value);
                  setSelectedProducto(value);
                }}
                options={productosList}
              ></Select>
            </div>
          </Form.Group>
        </Col>
        <Col style={{ textAlign: 'right' }}>
          {producto && (
            <Button
              onClick={() => {
                addProduct(producto);
                ClearAll();
              }}
              color="primary"
              size="lg"
              type="button"
            >
              Agregar
            </Button>
          )}
        </Col>
      </Row>
    </Card.Body>
  );
};

export default AddQuickProduct;
