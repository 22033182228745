import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';

const EditCantidad = ({ producto, onChangeCantidad }) => {
  return (
    <>
      <Card style={{ width: '100%' }}>
        <Card.Body>
          <Row>
            <Col>
              <Form.Control
                value={producto.cantidad}
                label="Cantidad"
                name="cantidad"
                placeholder="Cantidad"
                onChange={event => {
                  onChangeCantidad({
                    ...producto,
                    cantidad: event.target.value
                  });
                }}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default EditCantidad;
