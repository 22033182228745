import React, { useEffect, useState } from 'react';
import { Accordion, Button, Col, InputGroup, Row, Form } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { useAuth } from 'context/AuthProvider';
import { ApiEndpoint } from 'utils/ApiEndPont';
import axios from 'axios';

const VentasFilters = ({
  filterFolio,
  setFilterFolio,
  idStatusFilter,
  setIdStatusFilter,
  fromFilter,
  setFromFilter,
  toFilter,
  action,
  setToFilter,
  customerIdFilter,
  setCustomerIdFilter,
  setIdSucursalFilter,
  idSucursalFilter
}) => {
  const [customersList, setCustomersList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [sucursalsList, setSucursalsList] = useState([]);
  const Auth = useAuth();

  const getCustomers = () => {
    const uriCustomer = ApiEndpoint + 'clientes/';
    axios(uriCustomer).then(({ data }) => {
      const customersList = data.map(customers => {
        return {
          value: `${customers.idcliente}`,
          label: `${customers.nombre}`
        };
      });
      setCustomersList(customersList);
    });
  };

  const getSucursals = () => {
    const uriSucursals = ApiEndpoint + 'sucursales/';
    axios(uriSucursals).then(({ data }) => {
      const sucursals = data.map(sucursal => {
        return {
          value: `${sucursal.idsucursal}`,
          label: `${sucursal.sucursal}`
        };
      });
      setSucursalsList(sucursals);
    });
  };

  const getStatus = () => {
    const uriStatus = ApiEndpoint + 'statusventas/';
    axios(uriStatus).then(({ data }) => {
      const statuslist = data.map(status => {
        return {
          value: `${status.idstatus}`,
          label: `${status.status}`
        };
      });
      setStatusList(statuslist);
    });
  };

  useEffect(() => {
    getCustomers();
    getStatus();
    getSucursals();
  }, []); // empty array makes hook working once

  return (
    <>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Filters</Accordion.Header>
          <Accordion.Body>
            <Form as={Row} className="gx-2">
              <Row>
                <Col xs="4">
                  <InputGroup size="sm">
                    <InputGroup.Text id="inputGroup-sizing-sm">
                      Folio
                    </InputGroup.Text>
                    <Form.Control
                      value={filterFolio || ''}
                      name={filterFolio}
                      placeholder="Folio"
                      onChange={event => {
                        setFilterFolio(event.target.value);
                      }}
                    />
                  </InputGroup>
                </Col>
                <Col xs="4">
                  <InputGroup size="sm">
                    <InputGroup.Text id="inputGroup-sizing-sm">
                      Sucursal
                    </InputGroup.Text>
                    <Form.Select
                      aria-label="Default select"
                      size="sm"
                      name="status"
                      onChange={selectedOption => {
                        setIdSucursalFilter(`${selectedOption.target.value}`);
                      }}
                      value={`${idSucursalFilter}`}
                    >
                      <option key={'sucursal_0'} value={0}>
                        Seleccione una sucursal
                      </option>
                      {!!sucursalsList?.length &&
                        sucursalsList.map(({ label, value }) => (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        ))}
                    </Form.Select>
                  </InputGroup>
                </Col>
                <Col xs="4">
                  <InputGroup size="sm">
                    <InputGroup.Text id="inputGroup-sizing-sm">
                      Cliente
                    </InputGroup.Text>
                    <Form.Select
                      aria-label="Default select"
                      size="sm"
                      name="customerIdFilter"
                      onChange={selectedOption => {
                        setCustomerIdFilter(`${selectedOption.target.value}`);
                      }}
                      value={`${customerIdFilter}`}
                    >
                      <option key={'location_0'} value={0}>
                        "Seleccione un elemento"
                      </option>
                      {!!customersList?.length &&
                        customersList.map(customer => (
                          <option key={customer.value} value={customer.value}>
                            {customer.label}
                          </option>
                        ))}
                    </Form.Select>
                  </InputGroup>
                </Col>
                <Row></Row>

                <Col xs="4">
                  <InputGroup size="sm">
                    <InputGroup.Text id="inputGroup-sizing-sm">
                      Status
                    </InputGroup.Text>
                    <Form.Select
                      aria-label="Default select"
                      size="sm"
                      name="status"
                      onChange={selectedOption => {
                        setIdStatusFilter(`${selectedOption.target.value}`);
                      }}
                      value={`${idStatusFilter}`}
                    >
                      <option key={'location_0'} value={0}>
                        Seleccione un status
                      </option>
                      {!!statusList?.length &&
                        statusList.map(({ label, value }) => (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        ))}
                    </Form.Select>
                  </InputGroup>
                </Col>

                <Col xs="4">
                  <Row>
                    <Col style={{ alignContent: 'right' }}>Desde</Col>
                    <Col>
                      <ReactDatePicker
                        className="mb-3"
                        selected={fromFilter}
                        size="sm"
                        onChange={date => setFromFilter(date)}
                        placeholderText="Select Date"
                        dateFormat={'dd/MM/yyyy'}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs="4">
                  <Row>
                    <Col style={{ alignContent: 'right' }}>Hasta</Col>
                    <Col>
                      <ReactDatePicker
                        className="mb-3"
                        selected={toFilter}
                        size="sm"
                        onChange={date => setToFilter(date)}
                        placeholderText="Hasta"
                        dateFormat={'dd/MM/yyyy'}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs="4">
                  <Col style={{ alignContent: 'left' }}></Col>
                  <Col>
                    <Button
                      type="button"
                      className="mb-5"
                      onClick={() => action()}
                      color="primary"
                      size="sm"
                    >
                      Filtrar
                    </Button>
                  </Col>
                </Col>
              </Row>
            </Form>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default VentasFilters;
