import axios from 'axios';
import Divider from 'components/common/Divider';
import { useAuth } from 'context/AuthProvider';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ApiEndpoint } from 'utils/ApiEndPont';
import { decryptData, encryptData } from 'utils/Encrypth';

const NewApertura = ({ idUserType, closeModal }) => {
  const Auth = useAuth();
  let navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [efectivo, setEfectivo] = useState(0);
  const [idUser, setIdUser] = useState(0);
  const [idSucursal, setIdSucursal] = useState(0);
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      formapago: '1',
      montopago: '0',
      referencia: ''
    }
  });

  useEffect(() => {
    var userData = Auth.getSessionData();
    setIdUser(userData.id_user);
    setIdSucursal(userData.id_sucursal);
  }, []);

  const onSubmit = (data, e) => {
    const form = e.target;
    if (form.checkValidity() === false) {
      console.log('entre a submit 123');
      setValidated(true);
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    const URI = ApiEndpoint + 'newapertura/';
    setValidated(true);

    axios
      .post(URI, {
        idsucursal: idSucursal,
        montoinicio: efectivo,
        iduser: idUser,
        fecha: new Date(),
        cerrado: false
      })
      .then(function (response) {
        console.log(response);
        if (!response.data.error) {
          localStorage.setItem(
            'crmovilcajadata',
            encryptData(JSON.stringify(response.data))
          );
        }
        closeModal();
        navigate('/');
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onChangeEfectivo = value => {
    if (value > 0) {
      setEfectivo(value);
    }
  };
  return (
    <>
      <Card style={{ width: '100%' }}>
        <Card.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col style={{ textAlign: 'center' }} className="display-5 mb-3">
                Monto Inicial
              </Col>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Col style={{ textAlign: 'center' }}>
                    Ingrese monto en efectivo
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Controller
                      name="efctivocontrller"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error }
                      }) => (
                        <Form.Control
                          value={efectivo || '0'}
                          label="Efectivo"
                          name="efectivo"
                          placeholder="Efectivo"
                          onChange={event => {
                            onChangeEfectivo(event.target.value);
                          }}
                        />
                      )}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Divider></Divider>
            <Row>
              <Col style={{ textAlign: 'right' }}>
                <Button
                  style={{ height: '50' }}
                  type="submit"
                  color="primary"
                  size="bg"
                >
                  Guardar
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default NewApertura;
