import React, { useEffect, useState } from 'react';
import { Accordion, Button, Col, InputGroup, Row, Form } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { useAuth } from 'context/AuthProvider';
import { ApiEndpoint } from 'utils/ApiEndPont';
import axios from 'axios';
import { FormatDate } from 'utils/FormatData';

const FilterCaja = ({
  fromFilter,
  setFromFilter,
  setIdSucursalFilter,
  idSucursalFilter,
  action
}) => {
  const [sucursalsList, setSucursalsList] = useState([]);
  const Auth = useAuth();

  const getSucursals = () => {
    const uriSucursals = ApiEndpoint + 'sucursales/';
    axios(uriSucursals).then(({ data }) => {
      const sucursals = data.map(sucursal => {
        return {
          value: `${sucursal.idsucursal}`,
          label: `${sucursal.sucursal}`
        };
      });
      setSucursalsList(sucursals);
    });
  };

  useEffect(() => {
    getSucursals();
  }, []); // empty array makes hook working once

  return (
    <>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Filters</Accordion.Header>
          <Accordion.Body>
            <Form as={Row} className="gx-2">
              <Row>
                <Col xs="4">
                  <InputGroup size="sm">
                    <InputGroup.Text id="inputGroup-sizing-sm">
                      Sucursal
                    </InputGroup.Text>
                    <Form.Select
                      aria-label="Default select"
                      size="sm"
                      name="status"
                      onChange={selectedOption => {
                        setIdSucursalFilter(`${selectedOption.target.value}`);
                      }}
                      value={`${idSucursalFilter}`}
                    >
                      <option key={'sucursal_0'} value={0}>
                        Seleccione una sucursal
                      </option>
                      {!!sucursalsList?.length &&
                        sucursalsList.map(({ label, value }) => (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        ))}
                    </Form.Select>
                  </InputGroup>
                </Col>
                <Col xs="4">
                  <Row>
                    <Col style={{ alignContent: 'right' }}>Fecha</Col>
                    <Col>
                      <ReactDatePicker
                        className="mb-3"
                        selected={fromFilter}
                        size="sm"
                        onChange={date => {
                          setFromFilter(date);
                        }}
                        placeholderText="Select Date"
                        dateFormat={'dd/MM/yyyy'}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs="4">
                  <Col style={{ alignContent: 'left' }}></Col>
                  <Col>
                    <Button
                      type="button"
                      className="mb-5"
                      onClick={() => action()}
                      color="primary"
                      size="sm"
                    >
                      Filtrar
                    </Button>
                  </Col>
                </Col>
              </Row>
            </Form>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default FilterCaja;
