import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CloseButton,
  Col,
  Container,
  Row,
  Table
} from 'react-bootstrap';
import { ApiEndpoint } from 'utils/ApiEndPont';
import ReactToPrint from 'react-to-print';
import { FormatCurrency, FormatDate } from 'utils/FormatData';
import { useRef } from 'react';
import Logo from 'assets/img/Logo';
import Flex from 'components/common/Flex';

const ReceiptPreview = ({ idMovement, closeModal }) => {
  const [movement, setMovement] = useState(null);
  const [movementDetails, setMovementDetails] = useState(null);

  let componentRef = useRef();
  const handlePrint = () => {
    window.print();
  };

  useEffect(() => {
    axios
      .post(ApiEndpoint + 'warehouse/warehouseMovement', {
        movimiento: idMovement
      })
      .then(response => {
        setMovement(response.data);
        getDetails();
      });
  }, []);

  const getDetails = () => {
    axios
      .get(ApiEndpoint + 'warehouse/warehouseMovement/' + idMovement)
      .then(response => {
        setMovementDetails(response.data);
      });
  };

  return (
    <>
      <ReactToPrint
        trigger={() => <Button>Imprimir</Button>}
        content={() => componentRef.current}
      />
      {movement && (
        <div ref={componentRef}>
          <Card handlePrint={handlePrint} style={{ padding: 0 }}>
            <div className="printLayoutContainer">
              <style type="text/css" media="print">
                {
                  " @page { width: '80mm' } html, body {color:'black !important' height: auto;font-size: 80%; /* changing to 10pt has no impact */}                "
                }
              </style>
            </div>

            <Card.Body>
              <Container fluid>
                <Row xs={12} md={12} style={{ textAlign: 'center' }} item>
                  <Logo style={{ with: '50%', height: '50%' }}></Logo>
                </Row>
                <Row
                  xs={12}
                  md={12}
                  style={{ textAlign: 'center', fontStyle: 'bold' }}
                  item
                >
                  <Flex justifyContent="center" className="p-2 mb-2 fw-bold">
                    {movement.vc_movimiento} #
                    {String(idMovement).padStart(5, '0')}
                  </Flex>
                </Row>
                <Row xs={12} md={12} style={{ textAlign: 'center' }} item>
                  <Flex justifyContent="center" className="p-2 mb-2 fw-bold">
                    {movement.vc_sucursal}
                  </Flex>
                </Row>
                <Row className="border border-100">
                  <Col xs={4} md={2}>
                    <Flex justifyContent="left" className="p-2 mb-2 fw-bold">
                      Fecha
                    </Flex>
                  </Col>
                  <Col>
                    <Flex justifyContent="left" className="p-2 mb-2">
                      {FormatDate(movement.dt_fecha)}
                    </Flex>
                  </Col>
                </Row>
                {movement.id_tipomovimientoinventario == 2 && (
                  <>
                    <Row className="border border-100">
                      <Col xs={4} md={2}>
                        <Flex
                          justifyContent="left"
                          className="p-2 mb-2 fw-bold"
                        >
                          Proveedor
                        </Flex>
                      </Col>
                      <Col>
                        <Flex justifyContent="left" className="p-2 mb-2">
                          {movement.vc_nombre}
                        </Flex>
                      </Col>
                    </Row>
                  </>
                )}

                {movement.id_tipomovimientoinventario == 3 && (
                  <>
                    <Row className="border border-100">
                      <Col xs={4} md={2}>
                        <Flex
                          justifyContent="left"
                          className="p-2 mb-2 fw-bold"
                        >
                          Destino
                        </Flex>
                      </Col>
                      <Col>
                        <Flex justifyContent="left" className="p-2 mb-2">
                          {movement.destino}
                        </Flex>
                      </Col>
                    </Row>
                  </>
                )}
              </Container>
              <hr />
              <Row>
                <Table aria-label="sticky table">
                  <thead>
                    <tr>
                      {' '}
                      <th style={{ textAlign: 'left' }} colSpan={4}>
                        Cant.
                      </th>
                      <th style={{ textAlign: 'left' }} colSpan={4}>
                        Producto
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {movementDetails &&
                      movementDetails.map(item => (
                        <tr hover>
                          <td
                            style={{ textAlign: 'left', fontSize: 'smaller' }}
                            colSpan={4}
                          >
                            {item.cantidad}
                          </td>
                          <td
                            style={{ textAlign: 'left', fontSize: 'smaller' }}
                          >
                            {item.cat_producto.nombre}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Row>
              <hr color="DarkGrey" style={{ fontSize: 'smaller' }} />
              <Row
                className="fw-black center"
                style={{
                  textAlign: 'center',
                  alignContent: 'center',
                  alignItems: 'center'
                }}
                justifyContent="center"
              >
                <Row xs={12} md={12} justifyContent="center">
                  {' '}
                  <Flex justifyContent="center" className="p-2 mb-2">
                    {movement.vc_referencia}
                  </Flex>
                </Row>
              </Row>
            </Card.Body>
          </Card>
        </div>
      )}
    </>
  );
};

export default ReceiptPreview;
