import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Table } from 'react-bootstrap';
import { FormatCurrency } from 'utils/FormatData';

const ProductsVentas = ({
  detallesList,
  totalProducts,
  setDeleteProduct,
  setEditProduct,
  setOpenModalCantidad
}) => {
  return (
    <>
      {
        <div>
          <Table responsive striped hover>
            <thead className="light">
              <tr className="bg-primary text-white dark__bg-1000">
                <th scope="col">Cantidad</th>
                <th scope="col">Producto</th>
                <th scope="col">Precio</th>
                <th scope="col">Subtotal</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {detallesList.map(detail => (
                <tr className="align-middle" key={detail.rowId}>
                  <td className="text-nowrap">
                    <Button
                      variant="link"
                      className="link link-primary d-block"
                      onClick={() => {
                        setEditProduct(detail);
                        setOpenModalCantidad(true);
                      }}
                    >
                      {detail.cantidad}
                    </Button>
                  </td>
                  <td className="text-nowrap">{detail.nombre}</td>

                  <td className="text-nowrap">
                    {FormatCurrency(detail.preciopublico)}
                  </td>
                  <td className="text-nowrap">
                    {FormatCurrency(
                      Number(detail.cantidad) * Number(detail.preciopublico)
                    )}
                  </td>
                  <td className="text-end">
                    <Button
                      variant="falcon-default"
                      size="sm"
                      onClick={() => {
                        console.log(detail.rowId);
                        setDeleteProduct(detail.rowId);
                      }}
                    >
                      <FontAwesomeIcon icon="trash-alt" />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      }
    </>
  );
};

export default ProductsVentas;
