import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Button,
  Card,
  Col,
  Dropdown,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ApiEndpoint } from 'utils/ApiEndPont.js';
import MyModal from 'utils/Modal.js';
import GenericTableHeader from 'form-components/TableHeaders/GenericTableHeader';
import NewPayment from './NewPayment';
import TicketPreview from './TicketPreview';
import { FormatCurrency, FormatDate } from 'utils/FormatData';
import { Link } from 'react-router-dom';

const URI = ApiEndpoint + 'ventas/';

const Ventas = () => {
  const [ventas, setVentas] = useState([]);
  const [idVenta, setIdVenta] = useState(0);

  const [openModal, setOpenModal] = useState(false);
  const [openModalPago, setOpenModalPago] = useState(false);

  const columns = [
    {
      accessor: 'folio',
      Header: 'folio',
      headerProps: { style: { minWidth: '200px' }, className: 'ps-5' },
      cellProps: { className: 'ps-5' }
    },
    {
      accessor: 'fecha',
      Header: 'Fecha',
      disableSortBy: true,
      cellProps: { className: 'ps-5' },
      Cell: rowData => {
        let total = rowData.row.original.fecha;
        return <>{FormatDate(total)}</>;
      }
    },
    {
      accessor: 'cat_sucursale.sucursal',
      Header: 'Sucursal',
      headerProps: { style: { minWidth: '200px' }, className: 'ps-5' },
      cellProps: { className: 'ps-5' }
    },
    {
      accessor: 'total',
      Header: 'Importe',
      disableSortBy: true,
      cellProps: { className: 'ps-5' },
      Cell: rowData => {
        let total = rowData.row.original.total;
        return <>{FormatCurrency(total)}</>;
      }
    },
    {
      accessor: 'cat_status.status',
      Header: 'Status',
      disableSortBy: true,
      cellProps: { className: 'ps-5' }
    },
    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: rowData => {
        let idventa = rowData.row.original.idventa;
        let idStatus = rowData.row.original.idstatus;
        return (
          <>
            {idStatus == 1 && (
              <>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Agregar Pago</Tooltip>}
                >
                  <Button
                    variant="falcon-default"
                    size="sm"
                    onClick={() => {
                      setIdVenta(idventa);
                      setOpenModalPago(true);
                    }}
                  >
                    <FontAwesomeIcon icon="dollar-sign" />
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Editar</Tooltip>}
                >
                  <Button
                    variant="falcon-default"
                    size="sm"
                    as={Link}
                    to={'/NewVentas/' + idventa}
                    onClick={() => {
                      setIdVenta(idventa);
                    }}
                  >
                    <FontAwesomeIcon icon="pencil" />
                  </Button>
                </OverlayTrigger>
              </>
            )}

            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Imprimir</Tooltip>}
            >
              <Button
                variant="falcon-default"
                size="sm"
                onClick={() => {
                  setIdVenta(idventa);
                  setOpenModal(true);
                }}
              >
                <FontAwesomeIcon icon="print" />
              </Button>
            </OverlayTrigger>
          </>
        );
      }
    }
  ];
  const handleClose = () => {
    setOpenModal(false);
    setOpenModalPago(false);
    getVentas();
  };
  const handleClosePreview = () => {
    setOpenModal(false);
  };
  const getVentas = () => {
    axios.get(URI).then(response => {
      setVentas(response.data);
    });
  };

  useEffect(() => {
    getVentas();
  }, []);
  return (
    <>
      <AdvanceTableWrapper
        columns={columns}
        data={ventas}
        sortable
        pagination
        perPage={10}
      >
        <Card className="mb-3">
          <Card.Header>
            <Row className="flex-between-center">
              <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Ventas</h5>
              </Col>
              <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                <div id="orders-actions">
                  <Button
                    size="sm"
                    style={{ width: '100%' }}
                    variant="falcon-primary"
                    as={Link}
                    to="/NewVentas"
                  >
                    Nueva Venta
                  </Button>
                </div>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body className="p-0">
            {/* <Row className="flex-end-center mb-3">
              <Col xs="auto" sm={6} lg={4}>
                <AdvanceTableSearchBox table />
              </Col>
            </Row> */}
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTablePagination table />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
      {openModal && (
        <TicketPreview
          id="id_myModal"
          title={'ImprimirVenta'}
          openModal={openModal}
          closeModal={handleClosePreview}
          idVenta={idVenta}
        />
      )}
      {openModalPago && (
        <MyModal
          id="id_myModal"
          title={'Agregar Pago'}
          openModal={openModalPago}
          closeModal={handleClose}
        >
          <NewPayment idVenta={idVenta} closeModal={handleClose} />
          {/* <TicketPreview
            idVenta={1}
            montoAPagar={100}
            closeModal={handleClose}
          /> */}
        </MyModal>
      )}
    </>
  );
};

export default Ventas;
