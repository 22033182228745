import { formatDate } from '@fullcalendar/react';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Table,
  Tooltip
} from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ApiEndpoint } from 'utils/ApiEndPont';
import axios from 'axios';
const DetailReparacionCambios = ({
  idReparacion,
  setOpenComentario,
  setOpenFalla
}) => {
  const [cambios, setCambios] = useState(null);
  const getCambios = () => {
    const uriModels = ApiEndpoint + 'reparaciones/getcambios/' + idReparacion;
    axios(uriModels).then(({ data }) => {
      setCambios(data);
    });
  };

  useEffect(() => {
    getCambios();
  }, []); // empty array makes hook working once

  return (
    <>
      <Row>
        <Col></Col>
        <Col className="border-0">
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>Agregar Falla</Tooltip>}
          >
            <Button
              variant="falcon-default"
              size="sm"
              onClick={() => {
                setOpenFalla(true);
              }}
            >
              <FontAwesomeIcon icon="fa-solid fa-screwdriver-wrench" />
            </Button>
          </OverlayTrigger>
        </Col>
        <Col className="border-0">
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>Agregar Comentario</Tooltip>}
          >
            <Button
              variant="falcon-default"
              size="sm"
              onClick={() => {
                setOpenComentario(true);
              }}
            >
              <FontAwesomeIcon icon="fa-regular fa-comment" />
            </Button>
          </OverlayTrigger>
        </Col>
        <Col className="border-0">
          <IconButton
            variant="falcon-default"
            size="sm"
            icon="arrow-down"
            className="me-1 mb-2 mb-sm-0"
            iconClassName="me-1"
          ></IconButton>
        </Col>
      </Row>
      <Table striped className="border-bottom" style={{ minHeight: '500px' }}>
        <thead className="light">
          <tr className="bg-primary text-white dark__bg-1000">
            <th className="border-0">Cambios</th>
            {/* <th className="border-0 text-center">Quantity</th>
            <th className="border-0 text-end">Rate</th>
            <th className="border-0 text-end">Amount</th> */}
          </tr>
        </thead>
        <tbody>
          {cambios &&
            cambios.map(detail => (
              <tr style={{ height: '80px' }}>
                <td className="align-middle">
                  <Card.Text>
                    {detail.vc_descripcion}
                    <Row>
                      <Col>
                        <p className="mb-0 text-body-emphasis">
                          {formatDate(detail.dt_fechacambio)}
                        </p>
                      </Col>
                      <Col style={{ textAlign: 'right' }}>
                        <p className="mb-0 fs-11 fw-bolder">
                          {detail.vc_alias}
                        </p>
                      </Col>
                    </Row>
                  </Card.Text>
                </td>
              </tr>
            ))}
          <tr>
            <td></td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default DetailReparacionCambios;
