export const dashboardRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Inicio',
      active: true,
      icon: 'home',
      to: '/'
    },
    {
      name: 'Dahsb. Reparaciones',
      to: '/DashboardReparaciones',
      exact: true,
      icon: 'thumbtack',
      active: true
    },
    {
      name: 'Nueva Venta',
      to: '/NewVentas',
      exact: false,
      active: true,
      icon: 'shopping-cart'
    },
    {
      name: 'Nueva Reparacion',
      to: '/Reparacion',
      exact: true,
      active: true,
      icon: 'shopping-cart'
    }
  ]
};

export const CajaRoutes = {
  label: 'Caja',
  labelDisable: false,
  children: [
    {
      name: 'Caja',
      to: '/caja',
      active: true,
      icon: 'shopping-cart'
    }
  ]
};
export const VentasRoutes = {
  label: 'Ventas',
  labelDisable: false,
  children: [
    {
      name: 'Reparaciones',
      to: '/Reparaciones',
      exact: true,
      active: true,
      icon: 'shopping-cart'
    },

    {
      name: 'Ventas',
      to: '/Ventas',
      exact: true,
      active: true,
      icon: 'shopping-cart'
    },
    {
      name: 'Vales de Caja',
      to: '/ValesCaja',
      exact: true,
      active: true,
      icon: 'shopping-cart'
    }
  ]
};
export const SecurityRoutes = {
  label: 'Seguridad',
  labelDisable: false,
  children: [
    {
      name: 'Security',
      active: true,
      icon: 'chart-pie',
      children: [
        {
          name: 'Users',
          to: '/Users',
          exact: true,
          active: true
        }
      ]
    }
  ]
};

export const ReportsRoutes = {
  label: 'Reportes',
  labelDisable: false,
  children: [
    {
      name: 'Reportes',
      active: true,
      icon: 'puzzle-piece',
      children: [
        {
          name: 'Caja',
          to: '/CajaReport',
          exact: true,
          active: true
        },
        {
          name: 'Ventas',
          to: '/VentasFilter',
          exact: true,
          active: true
        },
        {
          name: 'Ingresos Almacen',
          to: '/Receipts',
          exact: true,
          active: true
        }
      ]
    }
  ]
};

export const WarehouseRoutes = {
  label: 'Almacen',
  labelDisable: false,
  children: [
    {
      name: 'Productos',
      to: '/productos',
      exact: true,
      active: true
    },
    {
      name: 'Almacen',
      active: true,
      icon: 'puzzle-piece',
      children: [
        {
          name: 'Ingreso a Almacen',
          to: '/Receipt',
          exact: true,
          active: true
        },
        {
          name: 'Traslado Almacen',
          to: '/Transfer',
          exact: true,
          active: true
        },
        {
          name: 'Inventario Almacen',
          to: '/Inventory',
          exact: true,
          active: true
        }
      ]
    }
  ]
};

export const AdministratorRoutes = {
  label: 'Administracion',
  labelDisable: false,
  children: [
    {
      name: 'Administracion',
      active: true,
      icon: 'puzzle-piece',
      children: [
        {
          name: 'Clientes',
          to: '/Clientes',
          exact: true,
          active: true
        },
        {
          name: 'Sucursales',
          to: '/Sucursales',
          exact: true,
          active: true
        }
      ]
    },
    {
      name: 'Configuracion',
      active: true,
      icon: 'wrench',
      children: [
        {
          name: 'Marcas',
          to: '/marcas',
          exact: true,
          active: true
        },
        {
          name: 'Modelos',
          to: '/modelos',
          exact: true,
          active: true
        },
        {
          name: 'Departamentos',
          to: '/departamentos',
          exact: true,
          active: true
        },
        {
          name: 'Categorias',
          to: '/categorias',
          exact: true,
          active: true
        },
        {
          name: 'Proveedores',
          to: '/Proveedores',
          exact: true,
          active: true
        }
      ]
    }
  ]
};
export default [
  dashboardRoutes,
  VentasRoutes,
  CajaRoutes,
  WarehouseRoutes,
  AdministratorRoutes,
  ReportsRoutes,
  SecurityRoutes
];
