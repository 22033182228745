import axios from 'axios';
import Flex from 'components/common/Flex';
import Logo from 'assets/img/Logo';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import { Button, Card, Col, Container, Row, Table } from 'react-bootstrap';
import ReactToPrint from 'react-to-print';
import { ApiEndpoint } from 'utils/ApiEndPont';
import { FormatCurrency, FormatDate } from 'utils/FormatData';

const ValeCajaPreview = ({ idVale, closeModal }) => {
  const [vale, setVale] = useState(null);

  let componentRef = useRef();
  const handlePrint = () => {
    window.print();
  };

  useEffect(() => {
    axios.get(ApiEndpoint + 'valecaja/' + idVale).then(response => {
      setVale(response.data);
    });
  }, []);

  return (
    <>
      <ReactToPrint
        trigger={() => <Button>Imprimir</Button>}
        content={() => componentRef.current}
      />
      {vale && (
        <div ref={componentRef}>
          <Card handlePrint={handlePrint} style={{ padding: 0 }}>
            <div className="printLayoutContainer">
              <style type="text/css" media="print">
                {
                  " @page { width: '80mm' } html, body {color:'black !important' height: auto;font-size: 80%; /* changing to 10pt has no impact */}                "
                }
              </style>
            </div>

            <Card.Body>
              <Container fluid>
                <Row xs={12} md={12} style={{ textAlign: 'center' }} item>
                  <Logo style={{ with: '50%', height: '50%' }}></Logo>
                </Row>
                <Row xs={12} md={12} style={{ textAlign: 'center' }} item>
                  <Flex justifyContent="center" className="p-2 mb-2">
                    {vale.cat_sucursale.sucursal}
                  </Flex>
                </Row>
                <Row xs={12} md={12} style={{ textAlign: 'center' }} item>
                  <Flex justifyContent="center" className="p-2 mb-2">
                    {vale.cat_sucursale.direccion}
                  </Flex>
                </Row>
                <Row xs={12} md={12} style={{ textAlign: 'center' }} item>
                  <Flex justifyContent="center" className="p-2 mb-2">
                    {vale.cat_sucursale.telefono}
                  </Flex>
                </Row>
                <Row className="border border-100">
                  <Col xs={4} md={2}>
                    <Flex justifyContent="left" className="p-2 mb-2">
                      Folio
                    </Flex>
                  </Col>
                  <Col>
                    <Flex justifyContent="left" className="p-2 mb-2">
                      {vale.folio}
                    </Flex>
                  </Col>
                </Row>
                <Row className="border border-100">
                  <Col xs={4} md={2}>
                    <Flex justifyContent="left" className="p-2 mb-2">
                      Fecha
                    </Flex>
                  </Col>
                  <Col>
                    <Flex justifyContent="left" className="p-2 mb-2">
                      {FormatDate(vale.fecha)}
                    </Flex>
                  </Col>
                </Row>
                <Row className="border border-100">
                  <Col xs={4} md={2}>
                    <Flex justifyContent="left" className="p-2 mb-2">
                      Descripcion
                    </Flex>
                  </Col>
                  <Col>
                    <Flex justifyContent="left" className="p-2 mb-2">
                      {vale.descripcion}
                    </Flex>
                  </Col>
                </Row>
                <Row className="border border-100">
                  <Col xs={4} md={2}>
                    <Flex justifyContent="left" className="p-2 mb-2">
                      Total
                    </Flex>
                  </Col>
                  <Col>
                    <Flex justifyContent="left" className="p-2 mb-2">
                      {FormatCurrency(vale.amount)}
                    </Flex>
                  </Col>
                </Row>
              </Container>

              <hr color="DarkGrey" style={{ fontSize: 'smaller' }} />
              <Row
                className="fw-black center"
                style={{
                  textAlign: 'center',
                  alignContent: 'center',
                  alignItems: 'center'
                }}
                justifyContent="center"
              >
                <Row xs={12} md={12} justifyContent="center">
                  {' '}
                  <Flex justifyContent="center" className="p-2 mb-2">
                    {vale.cat_sucursale.sucursal}
                  </Flex>
                </Row>

                <Row style={{ textAlign: 'center' }} xs={12} md={12} item>
                  <Flex justifyContent="center" className="p-2 mb-2">
                    {vale.cat_sucursale.email}
                  </Flex>
                </Row>
              </Row>
            </Card.Body>
          </Card>
        </div>
      )}
    </>
  );
};

export default ValeCajaPreview;
