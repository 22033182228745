import CardDropdown from 'components/common/CardDropdown';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  Card,
  Col,
  Form,
  Image,
  Row,
  Table,
  OverlayTrigger,
  Tooltip,
  Button
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import axios from 'axios';
import { ApiEndpoint } from 'utils/ApiEndPont';
import { FormatCurrency, FormatDate } from 'utils/FormatData';
import MyModal from 'utils/Modal';
import TechAsign from './TechAsign';
import { useNavigate } from 'react-router-dom';

const ReparacionesItem = ({ item, setOpenModalTech, setIdReparacion }) => {
  const navigate = useNavigate();
  const routeChange = ({ path }) => {
    navigate(path);
  };
  return (
    <tr>
      <td className={classNames('align-middle ps-0 text-nowrap')}>
        <Flex alignItems="center" className="position-relative">
          <div className="flex-1">
            <Link to="#!" className="stretched-link">
              <h6 className="mb-0">{item.vc_folio}</h6>
            </Link>
            <p className="mb-0">{FormatDate(item.dt_fecharecepcion)}</p>
          </div>
        </Flex>
      </td>{' '}
      <td className={classNames('align-middle ps-0 text-nowrap')}>
        <Flex alignItems="center" className="position-relative">
          <div className="flex-1">
            <h6 className="mb-0">
              {item.vc_imei + '/' + item.vc_marca + '-' + item.vc_modelo}
            </h6>
            <p className="mb-0">{item.vc_falla}</p>
          </div>
        </Flex>
      </td>
      <td className={classNames('align-middle ps-0 text-nowrap')}>
        <Flex alignItems="center" className="position-relative">
          <div className="flex-1">
            <Link to="#!" className="stretched-link">
              <h6 className="mb-0">{item.vc_sucursal}</h6>
            </Link>
            <p className="mb-0">{item.vc_cliente}</p>
            <p className="mb-0">
              {' '}
              {item.vc_tecnico ? 'Tecnico: ' + item.vc_tecnico : ''}
            </p>
          </div>
        </Flex>
      </td>
      <td className={classNames('align-middle px-4')} style={{ width: '1%' }}>
        <SoftBadge
          bg={classNames({
            success: item.id_statusreparacion == '2',
            warning: item.id_statusreparacion == '1',
            danger: item.id_statusreparacion == '3'
          })}
          className="fs--1 w-100"
        >
          {item.vc_status}
        </SoftBadge>
        <p className="fs--2 mb-0">Fecha Estimada: {item.dt_fechaestimada}</p>
      </td>
      <td
        className={classNames('align-middle px-4 text-end text-nowrap')}
        style={{ width: '1%' }}
      >
        <h6 className="mb-0">{FormatCurrency(item.n_total)}</h6>
      </td>
      {/* <td
        className={classNames('align-middle ps-4 pe-1')}
        style={{ width: '130px', minWidth: '130px' }}
      >
        <Form.Select size="sm" className="px-2 bg-transparent">
          <option value="action">Action</option>
          <option value="archive">Archive</option>
          <option value="delete">Delete</option>
        </Form.Select>
      </td>{' '} */}
      <td
        className={classNames('align-middle ps-4 pe-1')}
        style={{ width: '130px', minWidth: '130px' }}
        colSpan={3}
      >
        {item.id_statusreparacion != '2' ? (
          <>
            {' '}
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Asignar Tecnico</Tooltip>}
            >
              <Button
                variant="falcon-default"
                size="sm"
                onClick={() => {
                  console.log(item);
                  setIdReparacion(item.id_reparacion);
                  setOpenModalTech(true);
                }}
              >
                <FontAwesomeIcon icon="user" />
              </Button>
            </OverlayTrigger>
          </>
        ) : (
          <>
            {' '}
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Cerrar Reparacion</Tooltip>}
            >
              <Button
                variant="falcon-default"
                size="sm"
                onClick={() => {
                  console.log(item);
                  setIdReparacion(item.id_reparacion);
                  setOpenModalTech(true);
                }}
              >
                <FontAwesomeIcon icon="check" />
              </Button>
            </OverlayTrigger>
          </>
        )}
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Asignar Tecnico</Tooltip>}
        >
          <Button
            variant="falcon-default"
            size="sm"
            onClick={() => {
              console.log(item);
              setIdReparacion(item.id_reparacion);
              setOpenModalTech(true);
            }}
          >
            <FontAwesomeIcon icon="dollar-sign" />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Agregar Actualizacion</Tooltip>}
        >
          <Link
            icon={<FontAwesomeIcon icon="rotate-right" />}
            to={`/DetalleReparacion/${item.id_reparacion}`}
            className="stretched-link"
          >
            <Button
              variant="falcon-default"
              size="sm"
              onClick={() => {
                console.log(item.id_reparacion);
                //routeChange(`/DetalleReparacion/` + item.id_reparacion);
              }}
            >
              <FontAwesomeIcon icon="rotate-right" />
            </Button>
          </Link>
        </OverlayTrigger>
      </td>
    </tr>
  );
};
const ReparacionesSmry = () => {
  const [reparaciones, setReparaciones] = useState([]);
  const [openModalTech, setOpenModalTech] = useState(false);
  const [idReparacion, setIdReparacion] = useState([]);

  const getReparaciones = () => {
    const URI = ApiEndpoint + 'reparaciones/list/0';
    axios.get(URI).then(response => {
      console.log(response.data);
      setReparaciones(response.data);
    });
  };

  useEffect(() => {
    getReparaciones();
  }, []);

  const handleClose = () => {
    setOpenModalTech(false);
    // setOpenModalPago(false);
    getReparaciones();
  };
  return (
    <>
      <Card className="overflow-hidden">
        <FalconCardHeader
          title="Reparaciones Pendientes"
          titleTag="h6"
          className="py-2"
          light
          endEl={<CardDropdown />}
        />
        <Card.Body className="py-0">
          <Table className="table-dashboard mb-0 fs--1">
            <tbody>
              {reparaciones &&
                reparaciones.map((reparacion, index) => (
                  <ReparacionesItem
                    item={reparacion}
                    setIdReparacion={setIdReparacion}
                    setOpenModalTech={setOpenModalTech}
                  />
                ))}
            </tbody>
          </Table>
        </Card.Body>
        <Card.Footer className="bg-light py-2">
          <Row className="flex-between-center">
            <Col xs="auto"></Col>
            <Col xs="auto"></Col>
          </Row>
        </Card.Footer>
      </Card>
      {openModalTech && (
        <MyModal
          id="id_myModal"
          title={'Asignar Tecnico'}
          openModal={openModalTech}
          closeModal={handleClose}
        >
          <TechAsign idReparacion={idReparacion} closeModal={handleClose} />
          {/* <TicketPreview
            idVenta={1}
            montoAPagar={100}
            closeModal={handleClose}
          /> */}
        </MyModal>
      )}
    </>
  );
};

export default ReparacionesSmry;
