import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Button,
  Card,
  Col,
  Dropdown,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ApiEndpoint } from 'utils/ApiEndPont.js';
import GenericTableHeader from 'form-components/TableHeaders/GenericTableHeader';
import { FormatCurrency } from 'utils/FormatData';
import { decryptData, encryptData } from 'utils/Encrypth';
import PageHeader from 'components/common/PageHeader';
import MyModal from 'utils/Modal';
import TicketPreview from 'Ventas/TicketPreview';
import NewPayment from 'Ventas/NewPayment';
import { useAuth } from 'context/AuthProvider';
import { Link } from 'react-router-dom';
import CajaList from 'components/caja/CajaList.js';
import NewApertura from './NewApertura';

const URI = ApiEndpoint + 'ventascaja/';

const Caja = () => {
  const Auth = useAuth();
  const [ventas, setVentas] = useState([]);
  const [openModalCaja, setOpenModalCaja] = useState(false);
  const [idVenta, setIdVenta] = useState(0);
  const [cierreSummary, setCierreSummary] = useState(null);

  const [openModal, setOpenModal] = useState(false);
  const [openModalPago, setOpenModalPago] = useState(false);
  const handleCloseModal = () => {
    setOpenModalCaja(false);
    window.location.reload();
  };

  const handleClose = () => {
    setOpenModal(false);
    setOpenModalPago(false);
    getVentas();
  };

  const getVentas = () => {
    var cajaDataVar = Auth.getCajaData();

    if (cajaDataVar == null || cajaDataVar?.fecha == null) {
      getCajaData();
      return;
    }
    if (
      new Date(
        cajaDataVar == null ||
          cajaDataVar?.fecha == null ||
          cajaDataVar?.fecha ||
          new Date()
      ).setHours(0, 0, 0, 0) != new Date().setHours(0, 0, 0, 0)
    ) {
      return;
    }
    axios.get(URI + cajaDataVar?.idapertura || 0).then(response => {
      console.log(response.data);
      setVentas(response.data);
    });
  };

  const getCajaData = () => {
    var cajaDataVar = Auth.getCajaData();
    if (
      new Date(
        cajaDataVar == null ||
          cajaDataVar?.fecha == null ||
          cajaDataVar?.fecha ||
          new Date()
      ).setHours(0, 0, 0, 0) != new Date().setHours(0, 0, 0, 0)
    ) {
      return;
    }
    const URIApertura = ApiEndpoint + 'getapertura/';
    axios
      .post(URIApertura, {
        idsucursal: Auth.getSessionData().id_sucursal
      })
      .then(function (response) {
        if (!response.data.error) {
          Auth.setCajaData(response.data);

          axios.get(URI + response.data.id_apertura).then(response => {
            console.log(response.data);
            setVentas(response.data);
          });
        }
      });
  };

  const getCajaCierre = () => {
    var cajaDataVar = Auth.getCajaData();
    if (
      new Date(cajaDataVar.fecha.split('T')[0]).setHours(0, 0, 0, 0) !=
      new Date(new Date().toISOString().split('T')[0]).setHours(0, 0, 0, 0)
    ) {
      return;
    }

    const URIApertura = ApiEndpoint + 'getcierre/';
    axios
      .post(URIApertura, {
        idapertura: Auth.getCajaData()?.idapertura || 0
      })
      .then(function (response) {
        setCierreSummary(response.data);
      });
  };

  useEffect(() => {
    getVentas();
    getCajaCierre();
  }, []);

  return (
    <>
      {cierreSummary && ventas ? (
        <CajaList
          cierreSummary={cierreSummary}
          ventas={ventas}
          setIdVenta={setIdVenta}
          setOpenModalPrintVenta={setOpenModal}
          setOpenModalPago={setOpenModalPago}
          idSucursalFilter={Auth.getSessionData().id_sucursal}
          fromFilter={new Date()}
        ></CajaList>
      ) : (
        <>
          <Card>
            <Card.Header className="d-flex align-items-center justify-content-between">
              <h5 className="mb-0">Caja</h5>
            </Card.Header>
            <Card.Body>
              <h3 className="text-primary">Caja Cerrada</h3>
              <p className="lead">
                Debe Iniciar la caja visualizar las ventas del dia.
              </p>
              <Button
                onClick={() => {
                  setOpenModalCaja(true);
                }}
                variant="falcon-primary"
              >
                Abrir Caja
              </Button>
            </Card.Body>
          </Card>
        </>
      )}

      {openModal && (
        <MyModal
          id="id_myModal"
          title={(idVenta > 0 ? 'Editar' : 'Agregar') + ' ' + 'Venta'}
          openModal={openModal}
          closeModal={handleClose}
        >
          <TicketPreview idVenta={idVenta} closeModal={handleClose} />
        </MyModal>
      )}
      {openModalPago && (
        <MyModal
          id="id_myModal"
          title={'Agregar Pago'}
          openModal={openModalPago}
          closeModal={handleClose}
        >
          <NewPayment idVenta={idVenta} closeModal={handleClose} />
          {/* <TicketPreview
            idVenta={1}
            montoAPagar={100}
            closeModal={handleClose}
          /> */}
        </MyModal>
      )}
      {openModalCaja && (
        <MyModal
          id="id_myModal"
          title={'Abrir Caja'}
          openModal={openModalCaja}
          closeModal={handleCloseModal}
        >
          <NewApertura
            idUser={1}
            idUserType={1}
            closeModal={handleCloseModal}
            idsucursal={1}
          ></NewApertura>
        </MyModal>
      )}
    </>
  );
};

export default Caja;
