import React from 'react';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useAuth } from 'context/AuthProvider';
const ReparacionesByStatusCard = ({ status, countStatus }) => {
  return (
    <div>
      <Card className="h-md-100">
        <Card.Header className="pb-0">
          <h6 className="mb-0 mt-2">{status}</h6>
        </Card.Header>

        <Card.Body as={Flex} alignItems="end" justifyContent="between">
          <div>
            <h2
              className={classNames('mb-1 text-400 mb-0 mt-2')}
              style={{ fontSize: 'medium' }}
            >
              {countStatus}
            </h2>
          </div>
        </Card.Body>
      </Card>{' '}
    </div>
  );
};

export default ReparacionesByStatusCard;
