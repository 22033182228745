import React from 'react';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useAuth } from 'context/AuthProvider';

const InfoSucursalCard = () => {
  const Auth = useAuth();
  return (
    <div>
      <Card className="h-md-100">
        <Card.Header className="pb-0">
          <h6 className="mb-0 mt-2">Sucursal</h6>
        </Card.Header>

        <Card.Body as={Flex} alignItems="end" justifyContent="between">
          <div>
            <h2
              className={classNames('mb-1 text-400 mb-0 mt-2')}
              style={{ fontSize: 'medium' }}
            >
              {Auth.getCajaData()?.vc_sucursal}
            </h2>
          </div>
        </Card.Body>
      </Card>{' '}
    </div>
  );
};

export default InfoSucursalCard;
