import { useEffect, useState, useMemo } from 'react';
import { set, useForm } from 'react-hook-form';
import axios from 'axios';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import AlertNotification from 'form-components/AlertNotification';
import { ApiEndpoint } from 'utils/ApiEndPont';
import { FormInputText } from 'form-components/FormInputText';
import Select from 'react-select';
import uuid from 'react-uuid';

const URI = ApiEndpoint + 'productos/';

export default function EditProducto({ idProducto, closeModal }) {
  const [options, setOptions] = useState([]);
  const [marca, setMarca] = useState('');

  const [categoria, setCategoria] = useState('');
  const [proveedor, setProveedor] = useState('');
  const [selectedMarca, setSelectedMarca] = useState('');
  const [categoriasList, setCategoriasList] = useState('');
  const [proveedoresList, setProveedoresList] = useState('');
  const [modelosList, setModelosList] = useState('');
  const [selectedCategoria, setSelectedCategoria] = useState('');
  const [selectedModelo, setSelectedModelo] = useState('');
  const [selectedProveedor, setSelectedProveedor] = useState('');
  const [modelo, setModelo] = useState('');
  const [validar, setValidar] = useState(true);
  const [values, setValues] = useState({
    nombre: '',
    idcategoriaproducto: '0',
    descripcion: '',
    clave: '',
    idproveedor: '',
    idmarca: '',
    idmodelo: '',
    preciopublico: '',
    existencia: '',
    validar: 'true'
  });
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  // const [categoriesFailure] = useSymptomsCategory ();
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      nombre: '',
      idcategoriaproducto: '0',
      descripcion: '',
      clave: '',
      idproveedor: '',
      idmarca: '',
      idmodelo: '',
      preciopublico: '',
      existencia: '',
      validar: 'true'
    }
  });

  useEffect(() => {
    if (idProducto > 0) {
      axios.get(URI + idProducto).then(response => {
        setValues(response.data);

        setMarca(response.data.idmarca);
        setCategoria(response.data.idcategoriaproducto);
        setModelo(response.data.idmodelo);
        setProveedor(response.data.idproveedor);
        setValidar(response.data.validarinventario);
        setSelectedCategoria({
          value: `${response.data.cat_categoriaproducto.idcategoriaproductos}`,
          label: `${response.data.cat_categoriaproducto.descripcion}`
        });

        setSelectedModelo({
          value: `${response.data.cat_modelo.idmodelo}`,
          label: `${response.data.cat_modelo.modelo}`
        });

        setSelectedProveedor({
          value: `${response.data.cat_proveedore.idproveedores}`,
          label: `${response.data.cat_proveedore.nombre}`
        });
        setSelectedMarca({
          value: `${response.data.cat_marca.idmarca}`,
          label: `${response.data.cat_marca.marca}`
        });
      });
    }
  }, []);

  useEffect(() => {
    const uriMarcas = ApiEndpoint + 'marcas/';
    axios(uriMarcas).then(({ data }) => {
      console.log(uriMarcas);
      const marcasList = data.map(marca => {
        return {
          value: `${marca.idmarca}`,
          label: `${marca.marca}`
        };
      });
      setOptions(marcasList);
    });
  }, []); // empty array makes hook working once

  useEffect(() => {
    const uriProveedores = ApiEndpoint + 'proveedores/';
    axios(uriProveedores).then(({ data }) => {
      console.log(uriProveedores);
      const pletProveedoresList = data.map(proveedor => {
        return {
          value: `${proveedor.idproveedores}`,
          label: `${proveedor.nombre}`
        };
      });
      setProveedoresList(pletProveedoresList);
    });
  }, []);

  useEffect(() => {
    const uriCategorias = ApiEndpoint + 'catproductos/';
    axios(uriCategorias).then(({ data }) => {
      const categos = data.map(cat => {
        return {
          value: `${cat.idcategoriaproductos}`,
          label: `${cat.descripcion}`
        };
      });
      setCategoriasList(categos);
    });
  }, []); // empty array makes hook working once

  useEffect(() => {
    if (marca == 0) return;
    const uriModelo = ApiEndpoint + 'marcamodelos/' + marca;
    axios(uriModelo).then(({ data }) => {
      const modelosList = data.map(modelo => {
        return {
          value: `${modelo.idmodelo}`,
          label: `${modelo.modelo}`
        };
      });
      setModelosList(modelosList);
    });
  }, [marca]); // empty array makes hook working once

  const onSubmit = (data, e) => {
    const form = e.target;
    if (form.checkValidity() === false) {
      console.log('entre a submit 123');
      setValidated(true);
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    const URI = ApiEndpoint + 'productos/';
    setValidated(true);
    if (idProducto > 0) {
      axios
        .put(URI + idProducto, {
          nombre: values.nombre,
          idcategoriaproducto: categoria,
          descripcion: values.descripcion,
          clave: values.codigo,
          idmarca: marca,
          idmodelo: modelo,
          preciopublico: values.preciopublico,
          codigo: values.codigo,
          idproveedor: proveedor,
          existencia: values.existencia,
          validarinventario: validar
        })
        .then(function (response) {
          console.log(response);
          closeModal();
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      axios
        .post(URI, {
          nombre: values.nombre,
          idcategoriaproducto: categoria,
          descripcion: values.descripcion,
          clave: values.codigo,
          idmarca: marca,
          idmodelo: modelo,
          preciopublico: values.preciopublico,
          codigo: values.codigo,
          idproveedor: proveedor,
          existencia: values.existencia,
          validarinventario: validar
        })
        .then(function (response) {
          console.log(response);
          closeModal();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const onChange = event => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  const generateCode = event => {
    let uid = uuid();
    setValues({ ...values, codigo: uid });
  };
  return (
    <>
      <Card style={{ width: '100%' }}>
        <Card.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col>
                <FormInputText
                  label="Nombre"
                  changeHandler={onChange}
                  name={'nombre'}
                  control={control}
                  defaultValue={values.nombre}
                ></FormInputText>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormInputText
                  label="Descripcion"
                  changeHandler={onChange}
                  name={'descripcion'}
                  control={control}
                  defaultValue={values.descripcion}
                ></FormInputText>
              </Col>
              <Col>
                <FormInputText
                  label="Precio Publico"
                  changeHandler={onChange}
                  name={'preciopublico'}
                  control={control}
                  defaultValue={values.preciopublico}
                ></FormInputText>
              </Col>
            </Row>{' '}
            <Row>
              <Col>
                <FormInputText
                  label="Existencia"
                  changeHandler={onChange}
                  name={'existencia'}
                  control={control}
                  defaultValue={values.existencia}
                ></FormInputText>
              </Col>
            </Row>
            <Row>
              <Col>
                Codigo
                <Form.Control
                  value={values.codigo || ''}
                  label="Codigo"
                  name="codigo"
                  placeholder="Codigo"
                  onKeyDown={e => {
                    e.key === 'Enter' && e.preventDefault();
                  }}
                  onChange={event => {
                    onChange(event);
                  }}
                />
              </Col>
              {idProducto == 0 && (
                <Col>
                  <Form.Group className="mb-1" controlId="idPart">
                    <Button onClick={generateCode} color="primary" size="sm">
                      Generar Codigo
                    </Button>
                  </Form.Group>
                </Col>
              )}
            </Row>
            <hr />
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="idPart">
                  <Form.Label>Proveedor</Form.Label>
                  <div style={{ width: '450px' }}>
                    <Select
                      aria-label="Default select"
                      name="selectedProveedor"
                      size="sm"
                      closeMenuOnSelect={true}
                      classNamePrefix="react-select"
                      value={selectedProveedor}
                      onChange={value => {
                        setProveedor(`${value.value}`);
                        setSelectedProveedor(value);
                      }}
                      options={proveedoresList}
                    ></Select>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="idPart">
                  <Form.Label>Categoria</Form.Label>
                  <div style={{ width: '450px' }}>
                    <Select
                      aria-label="Default select"
                      name="selectedCategoria"
                      size="sm"
                      closeMenuOnSelect={true}
                      classNamePrefix="react-select"
                      value={selectedCategoria}
                      onChange={value => {
                        setCategoria(`${value.value}`);
                        setSelectedCategoria(value);
                      }}
                      options={categoriasList}
                    ></Select>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="idPart">
                  <Form.Label>Marca</Form.Label>
                  <div style={{ width: '450px' }}>
                    <Select
                      aria-label="Default select"
                      name="selectedMarca"
                      size="sm"
                      closeMenuOnSelect={true}
                      classNamePrefix="react-select"
                      value={selectedMarca}
                      onChange={value => {
                        setMarca(`${value.value}`);
                        setSelectedMarca(value);
                      }}
                      options={options}
                    ></Select>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="idPart">
                  <Form.Label>Modelo</Form.Label>
                  <div style={{ width: '450px' }}>
                    <Select
                      aria-label="Default select"
                      name="part"
                      size="sm"
                      closeMenuOnSelect={true}
                      classNamePrefix="react-select"
                      value={selectedModelo}
                      onChange={value => {
                        setModelo(`${value.value}`);
                        setSelectedModelo(value);
                      }}
                      options={modelosList}
                    ></Select>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Check
                  type="switch"
                  id="isRequired"
                  label="Validar Inventario"
                  checked={validar}
                  onChange={() => {
                    setValidar(!validar);
                  }}
                />
              </Col>
            </Row>
            <Button type="submit" color="primary" size="sm">
              Guardar
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
}
