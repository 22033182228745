import React, { useState } from 'react';
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormatCurrency, FormatDateToDb } from 'utils/FormatData';
import PageHeader from 'components/common/PageHeader';
import PrintCierreCaja from 'Catalogos/Caja/PrintCierreCaja';
import MyModal from 'utils/Modal';
import { useAuth } from 'context/AuthProvider';

const CajaList = ({
  cierreSummary,
  ventas,
  setIdVenta,
  setOpenModalPago,
  setOpenModalPrintVenta,
  idSucursalFilter,
  fromFilter
}) => {
  const Auth = useAuth();
  const [openModal, setOpenModal] = useState(false);
  const idSucursal = idSucursalFilter;
  const handleClose = () => {
    setOpenModal(false);
  };

  const columns = [
    {
      accessor: 'folio',
      Header: 'folio',
      headerProps: { style: { minWidth: '200px' }, className: 'ps-5' },
      cellProps: { className: 'ps-5' }
    },
    {
      accessor: 'cat_sucursale.sucursal',
      Header: 'Sucursal',
      headerProps: { style: { minWidth: '200px' }, className: 'ps-5' },
      cellProps: { className: 'ps-5' }
    },
    {
      accessor: 'total',
      Header: 'Importe',
      disableSortBy: true,
      cellProps: { className: 'ps-5' },
      Cell: rowData => {
        let total = rowData.row.original.total;
        return <>{FormatCurrency(total)}</>;
      }
    },
    {
      accessor: 'cat_status.status',
      Header: 'Status',
      disableSortBy: true,
      cellProps: { className: 'ps-5' }
    },
    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: rowData => {
        let idventa = rowData.row.original.idventa;
        let idStatus = rowData.row.original.idstatus;
        return (
          <>
            {idStatus == 1 && (
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Agregar Pago</Tooltip>}
              >
                <Button
                  variant="falcon-default"
                  size="sm"
                  onClick={() => {
                    setIdVenta(idventa);
                    setOpenModalPago(true);
                  }}
                >
                  <FontAwesomeIcon icon="dollar-sign" />
                </Button>
              </OverlayTrigger>
            )}

            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Imprimir</Tooltip>}
            >
              <Button
                variant="falcon-default"
                size="sm"
                onClick={() => {
                  setIdVenta(idventa);
                  setOpenModalPrintVenta(true);
                }}
              >
                <FontAwesomeIcon icon="print" />
              </Button>
            </OverlayTrigger>
          </>
        );
      }
    }
  ];
  return (
    <>
      {cierreSummary && (
        <>
          <Card className="mb-3">
            <Card.Body>
              <Row>
                <Col md={12} className="mb-4 mb-lg-0">
                  <PageHeader
                    title="Ventas del dia"
                    titleTag="h5"
                    className="mb-3"
                  ></PageHeader>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row>
                    <Col md={12} className="mb-4 mb-lg-0">
                      <Row>
                        <Col md={12} className="mb-4 mb-lg-0">
                          <p className="mb-3">Inicio en Caja</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} className="mb-4 mb-lg-0">
                          <p className="mb-3">
                            {FormatCurrency(cierreSummary.n_iniciocaja)}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col md={12} className="mb-4 mb-lg-0">
                      <Row>
                        <Col md={12} className="mb-4 mb-lg-0">
                          <p className="mb-3">Total Vendido</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} className="mb-4 mb-lg-0">
                          <p className="mb-3">
                            {FormatCurrency(cierreSummary.total)}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col md={12} className="mb-4 mb-lg-0">
                      <Row>
                        <Col md={12} className="mb-4 mb-lg-0">
                          <p className="mb-3">Total Cobrado</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} className="mb-4 mb-lg-0">
                          <p className="mb-3">
                            {FormatCurrency(cierreSummary.payed)}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col md={12} className="mb-4 mb-lg-0">
                      <Row>
                        <Col md={12} className="mb-4 mb-lg-0">
                          <p className="mb-3">Total Por Cobrar</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} className="mb-4 mb-lg-0">
                          <p className="mb-3">
                            {FormatCurrency(cierreSummary.pending)}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col md={12} className="mb-4 mb-lg-0">
                      <Row>
                        <Col md={12} className="mb-4 mb-lg-0">
                          <p className="mb-3">Total Vales</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} className="mb-4 mb-lg-0">
                          <p className="mb-3">
                            {FormatCurrency(cierreSummary.vales)}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col md={12} className="mb-4 mb-lg-0">
                      <Row>
                        <Col md={12} className="mb-4 mb-lg-0">
                          <p className="mb-3">Total Cierre</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} className="mb-4 mb-lg-0">
                          <p className="mb-3">
                            {FormatCurrency(cierreSummary.n_current)}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col></Col>
                <Col></Col>
                <Col>
                  <Button
                    size="sm"
                    onClick={() => setOpenModal(true)}
                    style={{ width: '100%' }}
                  >
                    Cierre de Caja
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <AdvanceTableWrapper
            columns={columns}
            data={ventas}
            sortable
            pagination
            perPage={10}
          >
            <Card className="mb-3">
              <Card.Header>
                <Row className="flex-between-center">
                  <Col
                    xs={4}
                    sm="auto"
                    className="d-flex align-items-center pe-0"
                  >
                    <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                      Ventas
                    </h5>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="p-0">
                {/* <Row className="flex-end-center mb-3">
            <Col xs="auto" sm={6} lg={4}>
              <AdvanceTableSearchBox table />
            </Col>
          </Row> */}
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    size: 'sm',
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                  }}
                />
              </Card.Body>
              <Card.Footer>
                <AdvanceTablePagination table />
              </Card.Footer>
            </Card>
          </AdvanceTableWrapper>
        </>
      )}
      {openModal && (
        <MyModal
          id="id_myModal"
          title={'Imprimir Cierre'}
          openModal={openModal}
          closeModal={handleClose}
        >
          <PrintCierreCaja
            idsucursal={idSucursal}
            date={FormatDateToDb(fromFilter)}
            closeModal={handleClose}
          />
        </MyModal>
      )}
    </>
  );
};

export default CajaList;
