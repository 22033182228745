import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import GenericTableHeader from 'form-components/TableHeaders/GenericTableHeader';
import React, { useEffect, useState } from 'react';
import { Button, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ApiEndpoint } from 'utils/ApiEndPont';
import { FormatCurrency, FormatDate } from 'utils/FormatData';
import MyModal from 'utils/Modal';
import ReparacionPreview from './ReparacionPreview';
const URI = ApiEndpoint + 'reparaciones/';
//reparaciones
const Reparaciones = () => {
  const [reparaciones, setReparaciones] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [idReparacion, setIdReparacion] = useState(0);

  const columns = [
    {
      accessor: 'folio',
      Header: 'folio',
      headerProps: { style: { minWidth: '200px' }, className: 'ps-5' },
      cellProps: { className: 'ps-5' }
    },
    {
      accessor: 'fecharecepcion',
      Header: 'Fecha',
      disableSortBy: true,
      cellProps: { className: 'ps-5' },
      Cell: rowData => {
        let total = rowData.row.original.fecharecepcion;
        return <>{FormatDate(total)}</>;
      }
    },
    {
      accessor: 'cat_sucursale.sucursal',
      Header: 'Sucursal',
      headerProps: { style: { minWidth: '200px' }, className: 'ps-5' },
      cellProps: { className: 'ps-5' }
    },
    {
      accessor: 'total',
      Header: 'Importe',
      disableSortBy: true,
      cellProps: { className: 'ps-5' },
      Cell: rowData => {
        let total = rowData.row.original.total;
        return <>{FormatCurrency(total)}</>;
      }
    },
    {
      accessor: 'cat_statusreparacion.status',
      Header: 'Status',
      disableSortBy: true,
      cellProps: { className: 'ps-5' }
    },
    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: rowData => {
        let idreparacion = rowData.row.original.idreparacion;
        let idstatusreparacion = rowData.row.original.idstatusreparacion;
        return (
          <>
            {idstatusreparacion == 1 && (
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Agregar Pago</Tooltip>}
              >
                <Button
                  variant="falcon-default"
                  size="sm"
                  onClick={() => {
                    // setIdVenta(idventa);
                    // setOpenModalPago(true);
                  }}
                >
                  <FontAwesomeIcon icon="dollar-sign" />
                </Button>
              </OverlayTrigger>
            )}

            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Imprimir</Tooltip>}
            >
              <Button
                variant="falcon-default"
                size="sm"
                onClick={() => {
                  setIdReparacion(idreparacion);
                  setOpenModal(true);
                }}
              >
                <FontAwesomeIcon icon="print" />
              </Button>
            </OverlayTrigger>
          </>
        );
      }
    }
  ];
  const handleClose = () => {
    setOpenModal(false);
    // setOpenModalPago(false);
    getReparaciones();
  };

  const getReparaciones = () => {
    axios.get(URI).then(response => {
      console.log(response.data);
      setReparaciones(response.data);
    });
  };

  useEffect(() => {
    getReparaciones();
  }, []);
  return (
    <>
      <AdvanceTableWrapper
        columns={columns}
        data={reparaciones}
        sortable
        pagination
        perPage={10}
      >
        <Card className="mb-3">
          <Card.Header>
            <GenericTableHeader
              label="Ventas"
              newFunction={() => {
                // setIdVenta(0);
                // setOpenModal(true);
              }}
            />
          </Card.Header>
          <Card.Body className="p-0">
            {/* <Row className="flex-end-center mb-3">
                  <Col xs="auto" sm={6} lg={4}>
                    <AdvanceTableSearchBox table />
                  </Col>
                </Row> */}
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTablePagination table />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
      {openModal && (
        <MyModal
          id="id_myModal"
          title={(idReparacion > 0 ? 'Editar' : 'Agregar') + ' ' + 'Reparacion'}
          openModal={openModal}
          closeModal={handleClose}
        >
          <ReparacionPreview
            idReparacion={idReparacion}
            closeModal={handleClose}
          />
        </MyModal>
      )}
    </>
  );
};

export default Reparaciones;
