import axios from 'axios';
import { useAuth } from 'context/AuthProvider';
import { FormInputText } from 'form-components/FormInputText';
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { ApiEndpoint } from 'utils/ApiEndPont';

export default function AddValeCaja({ closeModal }) {
  const Auth = useAuth();
  const [validated, setValidated] = useState(false);
  const [tiposValesOptions, setTiposValesOptions] = useState(false);
  const [tipoVale, setTipoVale] = useState(null);
  const [selectedTipoVale, setSelectedTipoVale] = useState(null);
  const [cajaData, setCajaData] = useState(null);
  const [userData, setUserData] = useState(null);
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      idtipovale: '0',
      amount: '0',
      descripcion: ''
    }
  });
  const [values, setValues] = useState({
    idtipovale: '0',
    amount: '0',
    descripcion: ''
  });

  useEffect(() => {
    const uritipos = ApiEndpoint + 'tiposvale/';
    axios(uritipos).then(({ data }) => {
      const tiposVales = data.map(tipo => {
        return {
          value: `${tipo.idtipovale}`,
          label: `${tipo.descripcion}`
        };
      });
      setTiposValesOptions(tiposVales);
    });
  }, []); // empty array makes hook working once

  useEffect(() => {
    setCajaData(Auth.getCajaData());
  }, []);

  const onSubmit = (data, e) => {
    const form = e.target;
    if (form.checkValidity() === false) {
      console.log('entre a submit 123');
      setValidated(true);
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    const URI = ApiEndpoint + 'createvalecaja/';
    setValidated(true);
    console.log(Auth.getCajaData());
    axios
      .post(URI, {
        idtipovale: tipoVale,
        amount: values.amount,
        descripcion: values.descripcion,
        idsucursal: Auth.getSessionData().id_sucursal,
        iduser: Auth.getSessionData().id_user,
        idapertura: Auth.getCajaData().idapertura
      })
      .then(function (response) {
        if (!response.data.error) {
          closeModal();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onChange = event => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  return (
    <>
      <Card style={{ width: '100%' }}>
        <Card.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="idPart">
                  <Form.Label>Tipo Vale</Form.Label>
                  <div style={{ width: '450px' }}>
                    <Select
                      aria-label="Default select"
                      name="selectedTipoCliente"
                      size="sm"
                      closeMenuOnSelect={true}
                      classNamePrefix="react-select"
                      value={selectedTipoVale}
                      onChange={value => {
                        setTipoVale(`${value.value}`);
                        setSelectedTipoVale(value);
                      }}
                      options={tiposValesOptions}
                    ></Select>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormInputText
                  label="Descripcion"
                  changeHandler={onChange}
                  name={'descripcion'}
                  control={control}
                  defaultValue={values.descripcion}
                ></FormInputText>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormInputText
                  label="Monto"
                  changeHandler={onChange}
                  name={'amount'}
                  control={control}
                  defaultValue={values.amount}
                ></FormInputText>
              </Col>
            </Row>
            <hr />
            <Button type="submit" color="primary" size="sm">
              Guardar
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
}
