import axios from 'axios';
import FalconComponentCard from 'components/common/FalconComponentCard';
import PageHeader from 'components/common/PageHeader';
import { FormInputText } from 'form-components/FormInputText';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { ApiEndpoint } from 'utils/ApiEndPont';
import ProductsList from './ProductsList';
import AddProduct from './AddProduct';
import { useAuth } from 'context/AuthProvider';
import Flex from 'components/common/Flex';
import { FormatDateToDb } from 'utils/FormatData';
import ReceiptPreview from './ReceiptPreview';
import MyModal from 'utils/Modal';

const Transfer = () => {
  const Auth = useAuth();
  const [messageError, setMessageError] = useState(null);
  const [sucursalsList, setSucursalsList] = useState([]);
  const [tipoMovimiento, setTipoMovimiento] = useState(null);
  const [totalProducts, setTotalProducts] = useState(null);
  const [deleteProduct, setDeleteProduct] = useState(null);

  const [producto, setProducto] = useState(null);
  const [cantidad, setCantidad] = useState(1);
  const [detallesList, setDetallesList] = useState([]);
  const [idMovementResult, setIdMovementResult] = useState(null);
  const [idDestinySelected, setIdDestinySelected] = useState(null);
  const [openModalPrint, setOpenModalPrint] = useState(null);
  const [values, setValues] = useState({
    tipoMovimiento: 3,
    referencia: '',
    fecha: '',
    entrega: ''
  });
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      tipoMovimiento: '3',
      referencia: '',
      fecha: '',
      entrega: ''
    }
  });

  const handleClose = () => {
    setOpenModalPrint(false);
  };

  const saveToWarehouse = () => {
    if (!detallesList || detallesList.length == 0) {
      setMessageError('Debe agregar al menos 1 articulo');
      return;
    }

    var dataToSubmit = {
      idsucursal: Auth.getSessionData().id_sucursal,
      tipomovimiento: tipoMovimiento || 3,
      referencia: values.referencia,
      products: detallesList,
      fecha: FormatDateToDb(new Date()),
      iduser: Auth.getSessionData().id_user,
      idsucursaldestino: idDestinySelected.value
    };
    console.log(dataToSubmit);
    const URI = ApiEndpoint + 'warehouse/InsertWarehouseRecepitTransfer';
    axios
      .post(URI, dataToSubmit)
      .then(function (response) {
        console.log(response);
        if (!response.data.error) {
          console.log(response.data);
          setIdMovementResult(response.data.result.UG_ID);
          setOpenModalPrint(true);
        } else {
          setMessageError(response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getSucursals = () => {
    const uriSucursals = ApiEndpoint + 'sucursales/';
    axios(uriSucursals).then(({ data }) => {
      const sucursals = data.map(sucursal => {
        return {
          value: `${sucursal.idsucursal}`,
          label: `${sucursal.sucursal}`
        };
      });
      setSucursalsList(sucursals);
    });
  };
  useEffect(() => {
    getSucursals();
  }, []); // empty array makes hook working once

  useEffect(() => {
    if (deleteProduct) {
      var newArr = detallesList;
      setDetallesList(newArr.filter(c => c.rowId != deleteProduct));
      setDeleteProduct(null);
    }
  }, [deleteProduct]);

  const onChange = event => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  return (
    <>
      <PageHeader
        title={
          'Nueva Salida de Almacen ' // + String('0000000' + 123).slice(-7)
        }
        titleTag="h5"
        className="mb-3"
      ></PageHeader>
      <Card className="mb-3">
        <Card.Header>
          <Row className="flex-between-center">
            <Col sm="auto">
              <h5 className="mb-0">Movimiento</h5>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Row className="align-items-center">
            <Col md={4}>
              <FormInputText
                label="Referencia "
                size="sm"
                changeHandler={event => {
                  onChange(event);
                  // setTelefono(event.value);
                }}
                name={'referencia'}
                control={control}
                defaultValue={values.referencia}
                value={values.referencia}
              ></FormInputText>
            </Col>
            <Col md={4}>
              <FormInputText
                label="Fecha "
                type="date"
                size="sm"
                changeHandler={event => {
                  onChange(event);
                  // setFecha(event.value);
                }}
                name={'fecha'}
                control={control}
                defaultValue={values.fecha}
                value={values.fecha}
              ></FormInputText>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={4}>
              <Form.Group className="mb-2" controlId="customer">
                <Form.Label> Sucursal Destino</Form.Label>
                <Select
                  aria-label="Default select"
                  size="sm"
                  name="status"
                  onChange={selected => {
                    setIdDestinySelected(selected);
                  }}
                  value={idDestinySelected}
                  defaultValue={idDestinySelected}
                  options={sucursalsList}
                ></Select>
              </Form.Group>
            </Col>
            <Col md={4}>
              <FormInputText
                label="Persona que Entrega"
                size="sm"
                changeHandler={event => {
                  onChange(event);
                  // setTelefono(event.value);
                }}
                name={'entrega'}
                control={control}
                defaultValue={values.entrega}
                value={values.entrega}
              ></FormInputText>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mb-3">
        <Card.Header>Detalle del Traslado</Card.Header>
        <Card.Body>
          <FalconComponentCard noGuttersBottom>
            <AddProduct
              addProduct={producto => {
                console.log(producto);
                detallesList.push(producto);
                setProducto(producto);
              }}
              setCantidad={setCantidad}
              cantidad={cantidad}
            ></AddProduct>
          </FalconComponentCard>
        </Card.Body>
      </Card>
      <Row>
        <h3 className="text-success fw-light">
          <span className="fw-bold">{messageError}</span>
        </h3>
      </Row>

      <Row className="g-3 mb-3">
        <Col lg={12}>
          <FalconComponentCard
            noGuttersBottom
            style={{ minHeight: 500, maxHeight: 500, overflow: 'auto' }}
          >
            {detallesList && (
              <ProductsList
                detallesList={detallesList}
                totalProducts={totalProducts}
                setDeleteProduct={setDeleteProduct}
              ></ProductsList>
            )}
          </FalconComponentCard>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="mb-4 mb-lg-0">
          <div className="flex-1">
            <h4 className="text-primary mb-0">
              <Flex justifyContent="center" className="p-2 mb-2">
                {detallesList && detallesList.length > 0 && (
                  <>
                    <Button
                      onClick={() => {
                        saveToWarehouse();
                      }}
                      color="primary"
                      size="lg"
                      variant="info"
                      transform="shrink-3"
                      className="rounded-pill me-2 mb-1"
                    >
                      Guardar
                    </Button>
                  </>
                )}
              </Flex>
            </h4>
          </div>
        </Col>
      </Row>
      {openModalPrint && (
        <MyModal
          id="id_myModal"
          title={'Ingreso a Almacen'}
          openModal={openModalPrint}
          closeModal={handleClose}
        >
          <ReceiptPreview
            idMovement={idMovementResult}
            closeModal={handleClose}
          />
        </MyModal>
      )}
    </>
  );
};

export default Transfer;
