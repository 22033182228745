import { useEffect, useState, useMemo } from 'react';
import { set, useForm } from 'react-hook-form';
import axios from 'axios';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import AlertNotification from 'form-components/AlertNotification';
import { ApiEndpoint } from 'utils/ApiEndPont';
import { FormInputText } from 'form-components/FormInputText';
import Select from 'react-select';
import uuid from 'react-uuid';

const URI = ApiEndpoint + 'clientes/';

export default function EditCliente({ idCliente, closeModal }) {
  const [alertMessage, setAlertMessage] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState('success');

  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);

  const [tipoCliente, setTipoCliente] = useState('');
  const [selectedTipoCliente, setSelectedTipoCliente] = useState('');
  const [tipoCientes, setTipoCientes] = useState('');

  const [values, setValues] = useState({
    nombre: '',
    razonsocial: '0',
    rfc: '',
    direccion: '',
    telefono: '',
    idtipocliente: '',
    email: ''
  });
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  // const [categoriesFailure] = useSymptomsCategory ();
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      nombre: '',
      razonsocial: '0',
      rfc: '',
      direccion: '',
      telefono: '',
      idtipocliente: '',
      email: ''
    }
  });

  useEffect(() => {
    if (idCliente > 0) {
      axios.get(URI + idCliente).then(response => {
        console.log(response.data);
        setValues(response.data);

        setTipoCliente(response.data.idtipocliente);

        setSelectedTipoCliente({
          value: `${response.data.cat_tipocliente.idtipocliente}`,
          label: `${response.data.cat_tipocliente.tipocliente}`
        });
      });
    }
  }, []);

  useEffect(() => {
    const uriMarcas = ApiEndpoint + 'tiposcliente/';
    axios(uriMarcas).then(({ data }) => {
      console.log(uriMarcas);
      const tiposClientesList = data.map(tipo => {
        return {
          value: `${tipo.idtipocliente}`,
          label: `${tipo.tipocliente}`
        };
      });
      setOptions(tiposClientesList);
    });
  }, []); // empty array makes hook working once

  const onSubmit = (data, e) => {
    const form = e.target;
    if (form.checkValidity() === false) {
      console.log('entre a submit 123');
      setValidated(true);
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    const URI = ApiEndpoint + 'clientes/';
    setValidated(true);
    if (idCliente > 0) {
      axios
        .put(URI + idCliente, {
          nombre: values.nombre,
          razonsocial: values.razonsocial,
          rfc: values.rfc,
          direccion: values.direccion,
          telefono: values.telefono,
          idtipocliente: tipoCliente,
          email: values.email
        })
        .then(function (response) {
          console.log(response);
          closeModal();
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      axios
        .post(URI, {
          nombre: values.nombre,
          razonsocial: values.razonsocial,
          rfc: values.rfc,
          direccion: values.direccion,
          telefono: values.telefono,
          idtipocliente: tipoCliente,
          email: values.email
        })
        .then(function (response) {
          console.log(response);
          closeModal();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const onChange = event => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  const generateCode = event => {
    let uid = uuid();
    setValues({ ...values, codigo: uid });
  };
  return (
    <>
      <Card style={{ width: '100%' }}>
        <Card.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col>
                <FormInputText
                  label="Nombre"
                  changeHandler={onChange}
                  name={'nombre'}
                  control={control}
                  defaultValue={values.nombre}
                ></FormInputText>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormInputText
                  label="Razon Social"
                  changeHandler={onChange}
                  name={'razonsocial'}
                  control={control}
                  defaultValue={values.razonsocial}
                ></FormInputText>
              </Col>
              <Col>
                <FormInputText
                  label="RFC"
                  changeHandler={onChange}
                  name={'rfc'}
                  control={control}
                  defaultValue={values.rfc}
                ></FormInputText>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormInputText
                  label="Direccion"
                  changeHandler={onChange}
                  name={'direccion'}
                  control={control}
                  defaultValue={values.direccion}
                ></FormInputText>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormInputText
                  label="Telefono"
                  changeHandler={onChange}
                  name={'telefono'}
                  control={control}
                  defaultValue={values.telefono}
                ></FormInputText>
              </Col>
              <Col>
                <FormInputText
                  label="Email"
                  changeHandler={onChange}
                  name={'email'}
                  control={control}
                  defaultValue={values.email}
                ></FormInputText>
              </Col>
            </Row>

            <hr />
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="idPart">
                  <Form.Label>Tipo Cliente</Form.Label>
                  <div style={{ width: '450px' }}>
                    <Select
                      aria-label="Default select"
                      name="selectedTipoCliente"
                      size="sm"
                      closeMenuOnSelect={true}
                      classNamePrefix="react-select"
                      value={selectedTipoCliente}
                      onChange={value => {
                        setTipoCliente(`${value.value}`);
                        setSelectedTipoCliente(value);
                      }}
                      options={options}
                    ></Select>
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <Button type="submit" color="primary" size="sm">
              Guardar
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
}
