import React from 'react';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

const TurnoCard = () => {
  return (
    <div>
      <Card className="h-md-100">
        <Card.Header className="pb-0">
          <h6 className="mb-0 mt-2">Caja</h6>
        </Card.Header>

        <Card.Body alignItems="end" justifyContent="between">
          <Row>
            <Col lg={6}>
              <h6 className="mb-0 mt-2">Ventas del Dia</h6>
              <h2 className={classNames('mb-1 text-700 fw-normal lh-1')}>
                $0.00
              </h2>
            </Col>
            <Col lg={6}>
              <h6 className="mb-0 mt-2">Por Cobrar</h6>
              <h2 className={classNames('mb-1 text-700 fw-normal lh-1')}>
                $0.00
              </h2>
            </Col>
          </Row>
          <Row>
            <Col></Col>
            <Col></Col>
            <Col alignItems="end">
              <Button
                size="sm"
                style={{ width: '100%' }}
                variant="falcon-primary"
                as={Link}
                to="/NewVentas"
              >
                Nueva Venta
              </Button>
            </Col>
          </Row>
          <Row>
            <Col></Col>
            <Col></Col>
            <Col>
              <Button
                size="md"
                style={{ width: '100%' }}
                variant="falcon-primary"
                as={Link}
                to="/Reparacion"
              >
                Reparacion
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>{' '}
    </div>
  );
};

export default TurnoCard;
