import React, { useContext, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import is from 'is_js';
import AppContext from 'context/Context';
import Dashboard from 'Dashboards/DashboardIndex';
import MainLayout from './MainLayout';
import Marcas from 'Catalogos/Configuracion/Marcas';
import Modelos from 'Catalogos/Configuracion/Modelos';
import CategoriasProductos from 'Catalogos/Configuracion/CategoriasProductos';
import Departamentos from 'Catalogos/Configuracion/Departamentos';
import Productos from 'Catalogos/Configuracion/Productos';
import Proveedores from 'Catalogos/Configuracion/Proveedores';
import Clientes from 'Catalogos/Administracion/Clientes';
import Sucursales from 'Catalogos/Administracion/Sucursales';
import NewVenta from 'Ventas/NewVenta';
import Ventas from 'Ventas/Ventas';
import Users from 'Catalogos/Security/Users';
import AuthSimpleLayout from './AuthSimpleLayout';
import Login from 'Account/Login';
import Caja from 'Catalogos/Caja/Caja';
import CreateSession from 'Catalogos/Caja/CreateSession';
import { ApiEndpoint } from 'utils/ApiEndPont';
import axios from 'axios';
import ValesCaja from 'Catalogos/Caja/ValesCaja';
import { useAuth } from 'context/AuthProvider';
import Ingreso from 'Taller/Ingreso';
import Reparaciones from 'Taller/Reparaciones';
import DashboardReparaciones from 'Taller/DashboardReparaciones';
import DetailReparacion from 'Taller/DetailReparacion';
import VentasList from 'Ventas/VentasList';
import ReporteCaja from 'reportes/ReporteCaja';
import Logout from 'components/authentication/card/Logout';
import WarehouseReceipts from 'warehouse/Receipts.js';
import WarehouseReport from 'warehouse/WarehouseReport';
import Movements from 'warehouse/Movements';
import Transfer from 'warehouse/Transfer';
import Inventory from 'warehouse/Inventory';

const Layout = () => {
  const [isCajaClose, setIsCajaClose] = useState(null);
  const [cajaData, setCajaData] = useState(null);
  let history = useNavigate();
  const Auth = useAuth();
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  useEffect(() => {
    var authCheck = Auth.getSessionData();
    console.log('authCheck');
    console.log(authCheck);
    if (!authCheck || authCheck?.length === 0 || authCheck === 'null') {
      history('/login');
      return;
      //Router.push('/login');
    }
    if (Auth.getCajaData() == null) {
      getAperturaData();
    }
  }, []);

  const getAperturaData = () => {
    const URIApertura = ApiEndpoint + 'getapertura/';
    axios
      .post(URIApertura, {
        idsucursal: Auth.getSessionData().id_sucursal
      })
      .then(function (response) {
        if (response.data && !response.data.error) {
          setIsCajaClose(false);
          setCajaData(response.data);
          Auth.setCajaData(response.data);
        } else {
          history('/newsess');
          return;
        }
      });
  };
  return (
    <>
      <Routes>
        <Route element={<MainLayout />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/marcas" element={<Marcas />} />
          <Route path="/modelos" element={<Modelos />} />
          <Route path="/departamentos" element={<Departamentos />} />
          <Route path="/categorias" element={<CategoriasProductos />} />
          <Route path="/productos" element={<Productos />} />
          <Route path="/proveedores" element={<Proveedores />} />
          <Route path="/clientes" element={<Clientes />} />
          <Route path="/Sucursales" element={<Sucursales />} />
          <Route path="/NewVentas" element={<NewVenta />} />
          <Route path="/NewVentas/:idVenta" element={<NewVenta />} />
          <Route path="/ValesCaja" element={<ValesCaja />} />
          <Route path="/Ventas" element={<Ventas />} />
          <Route path="/VentasFilter" element={<VentasList />} />
          <Route path="/Users" element={<Users />} />
          <Route path="/caja" element={<Caja />} />
          <Route path="/CajaReport" element={<ReporteCaja />} />
          <Route path="/Reparacion" element={<Ingreso />} />
          <Route path="/Receipt" element={<WarehouseReceipts />} />
          <Route path="/Transfer" element={<Transfer />} />
          <Route path="/Inventory" element={<Inventory />} />
          <Route path="/Receipts" element={<Movements />} />
          <Route path="/Reparaciones" element={<Reparaciones />} />
          <Route path="/DetalleReparacion/:id" element={<DetailReparacion />} />
          <Route
            path="/DashboardReparaciones"
            element={<DashboardReparaciones />}
          />
        </Route>
        <Route element={<AuthSimpleLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/newsess" element={<CreateSession />} />
          <Route path="/security/Logout" element={<Logout />} />
        </Route>
      </Routes>
    </>
  );
};

export default Layout;
