import React, { useEffect } from 'react';
import LogoutContent from 'components/authentication/LogoutContent';

import { useAuth } from 'context/AuthProvider';
import AuthSimpleLayout from 'layouts/AuthSimpleLayout';

const Logout = () => {
  const Auth = useAuth();
  useEffect(() => {
    console.log('entre a logout page');
    Auth.logout();
  }, []);
  return (
    <AuthSimpleLayout>
      <div className="text-center">
        <LogoutContent layout="card" titleTag="h3" />
      </div>
    </AuthSimpleLayout>
  );
};

export default Logout;
