import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import Avatar from 'components/common/Avatar';
import team3 from 'assets/img/avatars/avatar1.png';

const ProfileDropdown = () => {
  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        <Avatar src={team3} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          {/* <Dropdown.Item href="https://nexusdigitalmkt.com.mx/">Set status</Dropdown.Item>*/}

          {/*<Dropdown.Item href="https://nexusdigitalmkt.com.mx/">Feedback</Dropdown.Item>*/}
          <Dropdown.Divider />
          {/* <Dropdown.Item as={Link} to="/user/settings">
        {i18next.t('label.Settings')}
      </Dropdown.Item> */}
          <Dropdown.Item as={Link} to="/security/Logout">
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
