import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CloseButton,
  Col,
  Container,
  Row,
  Table
} from 'react-bootstrap';
import { ApiEndpoint } from 'utils/ApiEndPont';
import ReactToPrint from 'react-to-print';
import { FormatCurrency, FormatDate } from 'utils/FormatData';
import { useRef } from 'react';
import Logo from 'assets/img/Logo';
import Flex from 'components/common/Flex';

const PrintCierreCaja = ({ idsucursal, date, closeModal }) => {
  const [cierreSummary, setCierreSummary] = useState(null);
  const [sucursalData, setSucursalData] = useState(null);

  let componentRef = useRef();
  const handlePrint = () => {
    window.print();
  };

  const getAperturaData = () => {
    const URIApertura = ApiEndpoint + 'getcierredate/';
    axios
      .post(URIApertura, {
        idsucursal: idsucursal,
        date: date
      })
      .then(function (response) {
        if (response.data != null) {
          setCierreSummary(response.data);
        }
      });
  };

  const getSucursal = () => {
    const URISucursal = ApiEndpoint + 'sucursales/' + idsucursal;
    axios.get(URISucursal).then(function (response) {
      console.log(response.data);
      if (response.data != null) {
        setSucursalData(response.data);
      }
    });
  };

  useEffect(() => {
    getAperturaData();
    getSucursal();
  }, []);
  return (
    <>
      <ReactToPrint
        trigger={() => <Button>Imprimir</Button>}
        content={() => componentRef.current}
      />
      {cierreSummary && sucursalData && (
        <div ref={componentRef}>
          <Card handlePrint={handlePrint} style={{ padding: 0 }}>
            <div className="printLayoutContainer">
              <style type="text/css" media="print">
                {
                  " @page { width: '80mm' } html, body {color:'black !important' height: auto;font-size: 80%; /* changing to 10pt has no impact */}                "
                }
              </style>
            </div>

            <Card.Body>
              <Container fluid>
                <Row xs={12} md={12} style={{ textAlign: 'center' }} item>
                  <Logo style={{ with: '50%', height: '50%' }}></Logo>
                </Row>
                <Row xs={12} md={12} style={{ textAlign: 'center' }} item>
                  <Flex justifyContent="center" className="p-2 mb-2">
                    {sucursalData.sucursal}
                  </Flex>
                </Row>
                <Row xs={12} md={12} style={{ textAlign: 'center' }} item>
                  <Flex justifyContent="center" className="p-2 mb-2">
                    {sucursalData.direccion}
                  </Flex>
                </Row>
                <Row xs={12} md={12} style={{ textAlign: 'center' }} item>
                  <Flex justifyContent="center" className="p-2 mb-2">
                    {sucursalData.telefono}
                  </Flex>
                </Row>
                <Row className="border border-100">
                  <Col xs={4} md={2}>
                    <Flex justifyContent="left" className="p-2 mb-2">
                      Fecha
                    </Flex>
                  </Col>
                  <Col>
                    <Flex justifyContent="left" className="p-2 mb-2">
                      {sucursalData.dt_fecha}
                    </Flex>
                  </Col>
                </Row>
              </Container>
              <hr />
              <Row>
                <Table aria-label="sticky table">
                  <thead>
                    <tr>
                      <th style={{ textAlign: 'left' }} colSpan={4}>
                        Descripcion
                      </th>
                      <th style={{ textAlign: 'center' }} colSpan={6}>
                        Importe
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr hover>
                      <td
                        style={{ textAlign: 'left', fontSize: 'smaller' }}
                        colSpan={4}
                      >
                        Inicio en Caja
                      </td>
                      <td style={{ textAlign: 'right' }} colSpan={6}>
                        {cierreSummary.n_iniciocaja}
                      </td>
                    </tr>
                    <tr hover>
                      <td
                        style={{ textAlign: 'left', fontSize: 'smaller' }}
                        colSpan={4}
                      >
                        Total Vendido
                      </td>
                      <td style={{ textAlign: 'right' }} colSpan={6}>
                        + {cierreSummary.total}
                      </td>
                    </tr>
                    <tr hover>
                      <td
                        style={{ textAlign: 'left', fontSize: 'smaller' }}
                        colSpan={4}
                      >
                        Efectivo
                      </td>
                      <td style={{ textAlign: 'right' }} colSpan={6}>
                        + {cierreSummary.payed}
                      </td>
                    </tr>
                    <tr hover>
                      <td
                        style={{ textAlign: 'left', fontSize: 'smaller' }}
                        colSpan={4}
                      >
                        Vales
                      </td>
                      <td style={{ textAlign: 'right' }} colSpan={6}>
                        - {cierreSummary.vales || FormatCurrency('0.00')}
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td></td>
                      <td
                        colSpan={4}
                        className="fw-black"
                        style={{ textAlign: 'right' }}
                      >
                        Dinero en Caja
                      </td>
                      <td
                        className="fw-black"
                        style={{ textAlign: 'right' }}
                        colSpan={6}
                      >
                        {FormatCurrency(cierreSummary.n_current)}
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </Row>
              <hr color="DarkGrey" style={{ fontSize: 'smaller' }} />
              <Row
                className="fw-black center"
                style={{
                  textAlign: 'center',
                  alignContent: 'center',
                  alignItems: 'center'
                }}
                justifyContent="center"
              >
                <Row xs={12} md={12} justifyContent="center">
                  {' '}
                  <Flex justifyContent="center" className="p-2 mb-2">
                    {sucursalData.sucursal}
                  </Flex>
                </Row>

                <Row style={{ textAlign: 'center' }} xs={12} md={12} item>
                  <Flex justifyContent="center" className="p-2 mb-2">
                    {sucursalData.email}
                  </Flex>
                </Row>
              </Row>
            </Card.Body>
          </Card>
        </div>
      )}
    </>
  );
};

export default PrintCierreCaja;
