import React, { useEffect, useState } from 'react';
import { FormatCurrency } from 'utils/FormatData';
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ApiEndpoint } from 'utils/ApiEndPont';
import axios from 'axios';
import PageHeader from 'components/common/PageHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import GenericTableHeader from 'form-components/TableHeaders/GenericTableHeader';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import MyModal from 'utils/Modal.js';
import AddValeCaja from './AddValeCaja';
import ValeCajaPreview from './ValeCajaPreview';
import { useAuth } from 'context/AuthProvider';

const URI = ApiEndpoint + 'valescajaapertura/';

const ValesCaja = () => {
  const Auth = useAuth();
  const [vales, setVales] = useState([]);
  const [idVale, setIdVale] = useState(0);
  const [cajaData, setCajaData] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const [openModalPreview, setOpenModalPreview] = useState(false);

  const columns = [
    {
      accessor: 'folio',
      Header: 'folio',
      headerProps: { style: { minWidth: '200px' }, className: 'ps-5' },
      cellProps: { className: 'ps-5' }
    },
    {
      accessor: 'cat_sucursale.sucursal',
      Header: 'Sucursal',
      headerProps: { style: { minWidth: '200px' }, className: 'ps-5' },
      cellProps: { className: 'ps-5' }
    },
    {
      accessor: 'total',
      Header: 'Importe',
      disableSortBy: true,
      cellProps: { className: 'ps-5' },
      Cell: rowData => {
        let total = rowData.row.original.amount;
        return <>{FormatCurrency(total)}</>;
      }
    },
    {
      accessor: 'descripcion',
      Header: 'Descripcion',
      disableSortBy: true,
      cellProps: { className: 'ps-5' }
    },
    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: rowData => {
        let idventa = rowData.row.original.idvalecaja;
        return (
          <>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Imprimir</Tooltip>}
            >
              <Button
                variant="falcon-default"
                size="sm"
                onClick={() => {
                  setIdVale(idventa);
                  setOpenModalPreview(true);
                }}
              >
                <FontAwesomeIcon icon="print" />
              </Button>
            </OverlayTrigger>
          </>
        );
      }
    }
  ];
  const getVales = () => {
    axios.get(URI + Auth.getCajaData().idapertura).then(response => {
      console.log('response.data');
      console.log(response.data);
      setVales(response.data);
    });
  };

  useEffect(() => {
    getVales();
  }, []);

  const handleClose = () => {
    setOpenModal(false);
    setOpenModalPreview(false);
    getVales();
  };

  const getValesCajaTotales = items =>
    items != null
      ? items
          .map(({ amount }) => Number(amount))
          .reduce(
            (amount, currentValue) => Number(amount) + Number(currentValue),
            0
          )
      : 0;

  return (
    <>
      <Card className="mb-3">
        <Card.Body>
          <Row>
            <Col md={12} className="mb-4 mb-lg-0">
              <PageHeader
                title="Vales de caja del dia"
                titleTag="h5"
                className="mb-3"
              ></PageHeader>
            </Col>
          </Row>
          <Row>
            <Col>
              <Row>
                <Col md={12} className="mb-4 mb-lg-0">
                  <Row>
                    <Col md={12} className="mb-4 mb-lg-0">
                      <p className="mb-3">Total Vales</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className="mb-4 mb-lg-0">
                      <p className="mb-3">
                        {FormatCurrency(getValesCajaTotales(vales ?? []))}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col style={{ textAlign: 'center' }}></Col>
            <Col style={{ textAlign: 'right' }}>
              <Button
                style={{ height: '50' }}
                type="submit"
                color="primary"
                size="bg"
              >
                Vales de Caja
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <AdvanceTableWrapper
        columns={columns}
        data={vales}
        sortable
        pagination
        perPage={10}
      >
        <Card className="mb-3">
          <Card.Header>
            <GenericTableHeader
              label="Vales de Caja"
              newFunction={() => {
                setIdVale(0);
                setOpenModal(true);
              }}
            />
          </Card.Header>
          <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTablePagination table />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
      {openModal && (
        <MyModal
          id="id_myModal"
          title={(idVale > 0 ? 'Editar' : 'Agregar') + ' ' + 'Vale de Caja'}
          openModal={openModal}
          closeModal={handleClose}
        >
          <AddValeCaja closeModal={handleClose} />
        </MyModal>
      )}
      {openModalPreview && (
        <MyModal
          id="id_myModal"
          title={'Imprimir Vale'}
          openModal={openModalPreview}
          closeModal={handleClose}
        >
          <ValeCajaPreview idVale={idVale} closeModal={handleClose} />
        </MyModal>
      )}
    </>
  );
};

export default ValesCaja;
