import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MyModal from 'utils/Modal.js';
import { FormatCurrency, FormatDate, FormatDateToDb } from 'utils/FormatData';
import { Link } from 'react-router-dom';
import { ApiEndpoint } from 'utils/ApiEndPont';
import WarehouseFilters from './WarehouseFilters';
import ReceiptPreview from './ReceiptPreview';

const Movements = () => {
  const [filterFolio, setFilterFolio] = useState(0);
  const [typeMovementFilter, setTypeMovementFilter] = useState(0);
  const [idSucursalFilter, setIdSucursalFilter] = useState(0);
  const [fromFilter, setFromFilter] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [toFilter, setToFilter] = useState(new Date());
  const [movements, setMovements] = useState(false);
  const [idMovement, setIdMovement] = useState(0);

  const [openModalPrint, setOpenModalPrint] = useState(false);

  const columns = [
    {
      accessor: 'vc_sucursal',
      Header: 'Sucursal',
      headerProps: { style: { minWidth: '200px' }, className: 'ps-5' },
      cellProps: { className: 'ps-5' }
    },

    {
      accessor: 'dt_fecha',
      Header: 'Fecha',
      disableSortBy: true,
      cellProps: { className: 'ps-5' },
      Cell: rowData => {
        let total = rowData.row.original.dt_fecha;
        return <>{FormatDate(total)}</>;
      }
    },

    {
      accessor: 'destino',
      Header: 'Destino',
      disableSortBy: true,
      headerProps: { style: { minWidth: '200px' }, className: 'ps-5' },
      cellProps: { className: 'ps-5' }
    },
    {
      accessor: 'vc_movimiento',
      Header: 'Movimiento',
      disableSortBy: true,
      headerProps: { style: { minWidth: '200px' }, className: 'ps-5' },
      cellProps: { className: 'ps-5' }
    },
    {
      accessor: 'vc_referencia',
      Header: 'Referencia',
      disableSortBy: true,
      headerProps: { style: { minWidth: '200px' }, className: 'ps-5' },
      cellProps: { className: 'ps-5' }
    },
    {
      accessor: 'vc_nombre',
      Header: 'Proveedor',
      disableSortBy: true,
      cellProps: { className: 'ps-5' }
    },
    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: rowData => {
        let idMovimiento = rowData.row.original.id_movimientoalmacen;
        return (
          <>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Imprimir</Tooltip>}
            >
              <Button
                variant="falcon-default"
                size="sm"
                onClick={() => {
                  setIdMovement(idMovimiento);
                  setOpenModalPrint(true);
                }}
              >
                <FontAwesomeIcon icon="print" />
              </Button>
            </OverlayTrigger>
          </>
        );
      }
    }
  ];
  const handleClose = () => {
    setOpenModalPrint(false);
  };

  const getMovements = () => {
    const URI = ApiEndpoint + 'warehouse/warehouseMovementsRpt';
    setMovements(false);
    var dataToSubmit = {
      al: FormatDateToDb(toFilter),
      del: FormatDateToDb(fromFilter),
      idsucursal: idSucursalFilter,
      tipomovimiento: typeMovementFilter
    };
    console.log(dataToSubmit);
    axios
      .post(URI, dataToSubmit)
      .then(function (response) {
        setMovements(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <WarehouseFilters
        fromFilter={fromFilter}
        setFromFilter={setFromFilter}
        toFilter={toFilter}
        action={getMovements}
        setToFilter={setToFilter}
        typeMovementFilter={typeMovementFilter}
        setTypeMovementFilter={setTypeMovementFilter}
        setIdSucursalFilter={setIdSucursalFilter}
        idSucursalFilter={idSucursalFilter}
      ></WarehouseFilters>
      <>
        {movements && (
          <AdvanceTableWrapper
            columns={columns}
            data={movements || []}
            sortable
            pagination
            perPage={10}
          >
            <Card className="mb-3">
              <Card.Header>
                <Row className="flex-between-center">
                  <Col
                    xs={4}
                    sm="auto"
                    className="d-flex align-items-center pe-0"
                  >
                    <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                      Movimientos a Inventario
                    </h5>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="p-0">
                {/* <Row className="flex-end-center mb-3">
              <Col xs="auto" sm={6} lg={4}>
                <AdvanceTableSearchBox table />
              </Col>
            </Row> */}
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    size: 'sm',
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                  }}
                />
              </Card.Body>
              <Card.Footer>
                <AdvanceTablePagination table />
              </Card.Footer>
            </Card>
          </AdvanceTableWrapper>
        )}

        {openModalPrint && (
          <MyModal
            id="id_myModal"
            title={'Imprimir Recepcion de Inventario'}
            openModal={openModalPrint}
            closeModal={handleClose}
          >
            <ReceiptPreview idMovement={idMovement} closeModal={handleClose} />
          </MyModal>
        )}
      </>
    </>
  );
};

export default Movements;
