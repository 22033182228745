import { useEffect, useState, useMemo } from 'react';
import { Controller, set, useForm } from 'react-hook-form';
import axios from 'axios';
import { ApiEndpoint } from 'utils/ApiEndPont';
import { Badge, Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FormatCurrency } from 'utils/FormatData';
import Divider from 'components/common/Divider';
const NewPaymentRep = ({ idReparacion, closeModal }) => {
  const [reparacion, setReparacion] = useState(null);
  const [montoAPagar, setMontoAPagar] = useState(0);
  const [montoFaltante, setMontoFaltante] = useState(false);
  const [monto, setMonto] = useState(false);
  const [validated, setValidated] = useState(false);
  const [efectivo, setEfectivo] = useState(0);
  const [tarjeta, setTarjeta] = useState(0);
  const [transferencia, setTransferencia] = useState(0);
  const [formapago, setFormapago] = useState(0);
  const [values, setValues] = useState({
    formapago: '1',
    montopago: '0',
    referencia: ''
  });
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      formapago: '1',
      montopago: '0',
      referencia: ''
    }
  });

  const getReparacion = () => {
    const URIEdit = ApiEndpoint + 'reparacion/' + idReparacion;
    axios.get(URIEdit).then(response => {
      setReparacion(response.data);
      setMontoAPagar(response.data.total);
      setMontoFaltante(response.data.total);
    });
  };

  useEffect(() => {
    getReparacion();
  }, []);

  useEffect(() => {
    console.log('faltante');
    console.log(montoAPagar - monto);
    setMontoFaltante(montoAPagar - monto);
  }, [formapago, efectivo, tarjeta, transferencia]);

  const onSubmit = (data, e) => {
    const form = e.target;
    if (form.checkValidity() === false) {
      console.log('entre a submit 123');
      setValidated(true);
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    const URI = ApiEndpoint + 'pagoventa/';
    setValidated(true);

    // axios
    //   .put(URI + idReparacion, {
    //     idformapago: formapago,
    //     monto: montoAPagar,
    //     referencia: ''
    //   })
    //   .then(function (response) {
    //     console.log(response);
    //     closeModal();
    //     navigate('/Ventas');
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  };

  const onChangeEfectivo = value => {
    if (value > 0) {
      setFormapago(1);
      setMonto(value);
      setEfectivo(value);
      setTarjeta('0');
      setTransferencia('0');
    }
  };

  const onChangeTarjeta = value => {
    if (value > 0) {
      setFormapago(2);
      setMonto(value);
      setTarjeta(value);
      setEfectivo('0');
      setTransferencia('0');
    }
  };

  const onChangeCredito = event => {
    setFormapago(4);
  };
  const onChangeTransferencia = value => {
    if (value > 0) {
      setFormapago(3);

      setMonto(value);
      setTarjeta('0');
      setEfectivo('0');
      setTransferencia(value);
    }
  };

  return (
    <>
      <Card style={{ width: '100%' }}>
        <Card.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col style={{ textAlign: 'center' }} className="display-5 mb-3">
                Total a Pagar
              </Col>
            </Row>
            <Row>
              <Col style={{ textAlign: 'center' }} className="display-3 mb-3">
                {FormatCurrency(montoAPagar)}
              </Col>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Col style={{ textAlign: 'center' }}>
                    <img
                      onClick={() => {
                        onChangeEfectivo(montoAPagar);
                      }}
                      src="./css/cash.png"
                      height="50"
                      alt="card"
                    ></img>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Controller
                      name="efctivocontrller"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error }
                      }) => (
                        <Form.Control
                          value={efectivo || '0'}
                          label="Efectivo"
                          name="efectivo"
                          placeholder="Efectivo"
                          onChange={event => {
                            onChangeEfectivo(event.target.value);
                          }}
                        />
                      )}
                    />
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col style={{ textAlign: 'center' }}>
                    <img
                      onClick={() => {
                        onChangeTarjeta(montoAPagar);
                      }}
                      src="./css/card.png"
                      height="50"
                      alt="card"
                    ></img>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Control
                      value={tarjeta}
                      label="Tarjeta"
                      name="tarjeta"
                      placeholder="Tarjeta"
                      onChange={event => {
                        onChangeTarjeta(event.value);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col style={{ textAlign: 'center' }}>
                    <img
                      onClick={() => {
                        onChangeTransferencia(montoAPagar);
                      }}
                      src="./css/transfer.png"
                      height="50"
                      alt="card"
                    ></img>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Control
                      value={transferencia}
                      label="Transferencia"
                      name="trasferencia"
                      placeholder="Trasferencia"
                      onChange={event => {
                        onChangeTransferencia(event.value);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Divider></Divider>
            <Row>
              <Col style={{ textAlign: 'center' }} className="display-5 mb-3">
                Por Pagar
                <h3
                  className={
                    (montoFaltante > 0 ? 'text-' : 'text-success') + 'fw-light'
                  }
                >
                  {montoFaltante > 0 ? (
                    <Badge bg="warning" className="me-2">
                      {' '}
                      {FormatCurrency(montoFaltante)}
                    </Badge>
                  ) : (
                    <Badge bg="success" className="me-2">
                      {' '}
                      {FormatCurrency(montoFaltante)}
                    </Badge>
                  )}
                  {/* <span className="fw-bold">
                    {FormatCurrency(montoFaltante)}
                  </span> */}
                </h3>
              </Col>
              <Col
                style={{ textAlign: 'center' }}
                className="display-5 mb-3"
              ></Col>
              <Col style={{ textAlign: 'right' }}>
                {montoFaltante <= 0 && (
                  <Button
                    color="primary"
                    size="lg"
                    style={{ height: '300' }}
                    variant="info"
                    transform="shrink-3"
                    type="submit"
                    className="rounded-pill me-2 mb-1"
                  >
                    Guardar
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default NewPaymentRep;
