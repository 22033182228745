import React, { useEffect, useState } from 'react';
import { Accordion, Button, Col, InputGroup, Row, Form } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { useAuth } from 'context/AuthProvider';
import { ApiEndpoint } from 'utils/ApiEndPont';
import axios from 'axios';

const WarehouseFilters = ({
  fromFilter,
  setFromFilter,
  toFilter,
  action,
  setToFilter,
  typeMovementFilter,
  setTypeMovementFilter,
  setIdSucursalFilter,
  idSucursalFilter
}) => {
  const [sucursalsList, setSucursalsList] = useState([]);
  const [typeMovementsList, setTypeMovementsList] = useState([]);

  const getSucursals = () => {
    const uriSucursals = ApiEndpoint + 'sucursales/';
    axios(uriSucursals).then(({ data }) => {
      const sucursals = data.map(sucursal => {
        return {
          value: `${sucursal.idsucursal}`,
          label: `${sucursal.sucursal}`
        };
      });
      setSucursalsList(sucursals);
    });
  };

  const getTypeMovements = () => {
    const uriStatus = ApiEndpoint + 'warehouse/getTiposMovimientos';
    axios(uriStatus).then(({ data }) => {
      const statuslist = data.map(status => {
        return {
          value: `${status.idtipomovimientoinventario}`,
          label: `${status.movimiento}`
        };
      });
      setTypeMovementsList(statuslist);
    });
  };

  useEffect(() => {
    getTypeMovements();
    getSucursals();
  }, []); // empty array makes hook working once

  return (
    <>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Filters</Accordion.Header>
          <Accordion.Body>
            <Form as={Row} className="gx-2">
              <Row>
                <Col xs="4">
                  <InputGroup size="sm">
                    <InputGroup.Text id="inputGroup-sizing-sm">
                      Sucursal
                    </InputGroup.Text>
                    <Form.Select
                      aria-label="Default select"
                      size="sm"
                      name="status"
                      onChange={selectedOption => {
                        setIdSucursalFilter(`${selectedOption.target.value}`);
                      }}
                      value={`${idSucursalFilter}`}
                    >
                      <option key={'sucursal_0'} value={0}>
                        Seleccione una sucursal
                      </option>
                      {!!sucursalsList?.length &&
                        sucursalsList.map(({ label, value }) => (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        ))}
                    </Form.Select>
                  </InputGroup>
                </Col>
                <Col xs="4">
                  <InputGroup size="sm">
                    <InputGroup.Text id="inputGroup-sizing-sm">
                      Tipo de Movimiento
                    </InputGroup.Text>
                    <Form.Select
                      aria-label="Default select"
                      size="sm"
                      name="customerIdFilter"
                      onChange={selectedOption => {
                        setTypeMovementFilter(`${selectedOption.target.value}`);
                      }}
                      value={`${typeMovementFilter}`}
                    >
                      <option key={'location_0'} value={0}>
                        "Seleccione un elemento"
                      </option>
                      {!!typeMovementsList?.length &&
                        typeMovementsList.map(typeMovement => (
                          <option
                            key={typeMovement.value}
                            value={typeMovement.value}
                          >
                            {typeMovement.label}
                          </option>
                        ))}
                    </Form.Select>
                  </InputGroup>
                </Col>
                <Row></Row>

                <Col xs="4">
                  <Row>
                    <Col style={{ alignContent: 'right' }}>Desde</Col>
                    <Col>
                      <ReactDatePicker
                        className="mb-3"
                        selected={fromFilter}
                        size="sm"
                        onChange={date => setFromFilter(date)}
                        placeholderText="Select Date"
                        dateFormat={'dd/MM/yyyy'}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs="4">
                  <Row>
                    <Col style={{ alignContent: 'right' }}>Hasta</Col>
                    <Col>
                      <ReactDatePicker
                        className="mb-3"
                        selected={toFilter}
                        size="sm"
                        onChange={date => setToFilter(date)}
                        placeholderText="Hasta"
                        dateFormat={'dd/MM/yyyy'}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs="4">
                  <Col style={{ alignContent: 'left' }}></Col>
                  <Col>
                    <Button
                      type="button"
                      className="mb-5"
                      onClick={() => action()}
                      color="primary"
                      size="sm"
                    >
                      Filtrar
                    </Button>
                  </Col>
                </Col>
              </Row>
            </Form>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};
export default WarehouseFilters;
