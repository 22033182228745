import FilterCaja from './filters/FilterCaja';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ApiEndpoint } from 'utils/ApiEndPont';
import CajaList from 'components/caja/CajaList';
import { FormatDateToDb } from 'utils/FormatData';
const ReporteCaja = () => {
  const [idSucursalFilter, setIdSucursalFilter] = useState(0);
  const [fromFilter, setFromFilter] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [ventas, setVentas] = useState(false);
  const [idVenta, setIdVenta] = useState(0);
  const [cierreSummary, setCierreSummary] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openModalPago, setOpenModalPago] = useState(false);

  const getCajaCierre = () => {
    console.log(FormatDateToDb(fromFilter));
    const URIApertura = ApiEndpoint + 'getcierredate/';
    axios
      .post(URIApertura, {
        idsucursal: idSucursalFilter,
        date: FormatDateToDb(fromFilter)
      })
      .then(function (response) {
        if (response.data != null) {
          setCierreSummary(response.data);
        }

        const URI = ApiEndpoint + 'ventascaja/';

        axios.get(URI + response.data.id_apertura).then(response => {
          setVentas(response.data);
        });
      });
  };

  return (
    <>
      <FilterCaja
        fromFilter={fromFilter}
        setFromFilter={setFromFilter}
        action={getCajaCierre}
        setIdSucursalFilter={setIdSucursalFilter}
        idSucursalFilter={idSucursalFilter}
      ></FilterCaja>
      <>
        {ventas && (
          <CajaList
            cierreSummary={cierreSummary}
            ventas={ventas}
            setIdVenta={setIdVenta}
            setOpenModal={setOpenModal}
            setOpenModalPago={setOpenModalPago}
            idSucursalFilter={idSucursalFilter}
            fromFilter={fromFilter}
          ></CajaList>
        )}
      </>
    </>
  );
};
export default ReporteCaja;
