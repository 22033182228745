import { useEffect, useState, useMemo } from 'react';
import { set, useForm } from 'react-hook-form';
import axios from 'axios';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import AlertNotification from 'form-components/AlertNotification';
import { ApiEndpoint } from 'utils/ApiEndPont';
import { FormInputText } from 'form-components/FormInputText';
import Select from 'react-select';

const URI = ApiEndpoint + 'departamentos/';

export default function EditDepartamento({ idDepartamento, closeModal }) {
  const [alertMessage, setAlertMessage] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState('success');

  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({
    departamento: ''
  });
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  // const [categoriesFailure] = useSymptomsCategory ();
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      departamento: ''
    }
  });

  useEffect(() => {
    if (idDepartamento > 0) {
      axios.get(URI + idDepartamento).then(response => {
        setValues(response.data);
      });
    }
  }, []);

  const onSubmit = (data, e) => {
    const form = e.target;
    if (form.checkValidity() === false) {
      console.log('entre a submit 123');
      setValidated(true);
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    const URI = ApiEndpoint + 'departamentos/';
    setValidated(true);
    if (idDepartamento > 0) {
      axios
        .put(URI + idDepartamento, {
          departamento: values.departamento
        })
        .then(function (response) {
          console.log(response);
          closeModal();
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      axios
        .post(URI, {
          departamento: values.departamento
        })
        .then(function (response) {
          console.log(response);
          closeModal();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const onChange = event => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  return (
    <>
      <Card style={{ width: '100%' }}>
        <Card.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Form.Group className="mb-3" controlId="categoria">
              <FormInputText
                label="Departamento"
                changeHandler={onChange}
                name={'departamento'}
                control={control}
                defaultValue={values.departamento}
              ></FormInputText>
            </Form.Group>
            <Button type="submit" color="primary" size="sm">
              Guardar
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
}
