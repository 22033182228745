import axios from 'axios';
import { FormInputText } from 'form-components/FormInputText';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import uuid from 'react-uuid';
import { ApiEndpoint } from 'utils/ApiEndPont';
const URI = ApiEndpoint + 'users/';

const EditUser = ({ idUser, closeModal }) => {
  const [validated, setValidated] = useState(false);
  const [user, setUser] = useState(null);
  const [idSucursal, setIdSucursal] = useState(null);
  const [usersType, setUsersType] = useState(null);
  const [sucursals, setSucursals] = useState(null);
  const [isActive, setIsActive] = useState(true);
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      name: '',
      firstname: '',
      lastname: '',
      alias: '',
      idusertype: '0',
      phone: '',
      email: '',
      active: '',
      idSucursal: '0'
    }
  });
  const [error, setError] = useState({
    password: '',
    confirmPassword: ''
  });
  const [password, setPassword] = useState(null);
  const [idUserType, setIdUserType] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [values, setValues] = useState({
    name: '',
    firstname: '',
    lastname: '',
    alias: '',
    idusertype: '0',
    phone: '',
    email: '',
    active: '',
    idSucursal: '0'
  });
  useEffect(() => {
    axios.get(URI + idUser).then(response => {
      console.log(response.data);
      setUser(response.data);
      setIdUserType(response.data.idusertype);
      setIsActive(response.data.active);
    });
  }, []);

  useEffect(() => {
    axios.get(ApiEndpoint + 'sucursales').then(response => {
      let scursals = response.data;

      setSucursals(scursals);
    });
  }, []);

  useEffect(() => {
    axios.get(URI + 'usertypes').then(response => {
      let usersTypes = response.data;

      setUsersType(
        usersTypes
          .filter(
            item =>
              item.idusertype === 1 ||
              item.idusertype === 2 ||
              item.idusertype === 5
          )
          .map(item => item)
      );
    });
  }, []); // empty array makes hook working once

  const onSubmit = (data, e) => {
    const form = e.target;
    if (
      form.checkValidity() === false ||
      (error.password !== error.confirmPassword && (!idUser || idUser === 0))
    ) {
      console.log('entre a submit 123');
      setValidated(true);
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    setValidated(true);
    if (idUser > 0) {
      axios
        .put(URI + idUser, {
          name: user.name,
          lastname: user.lastname,
          alias: user.alias,
          idusertype: idUserType,
          email: user.email,
          phone: user.phone,
          active: isActive,
          sucursal: idSucursal
        })
        .then(function (response) {
          console.log(response);
          closeModal();
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      axios
        .post(URI, {
          name: user.name,
          lastname: user.lastname,
          alias: user.alias,
          idusertype: idUserType,
          email: user.email,
          phone: user.phone,
          active: isActive,
          password: password,
          salt: uuid(),
          sucursal: idSucursal
        })
        .then(function (response) {
          console.log(response);
          closeModal();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };
  const onChange = event => {
    console.log(event.target);
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  };
  const onChangePassword = event => {
    setPassword(event.target.value);
    validateInput(event);
  };
  const onChangeConfirm = event => {
    setConfirmPassword(event.target.value);
    validateInput(event);
  };

  const onChangeActive = event => {
    setIsActive(!isActive);
  };

  const validateInput = e => {
    if (idUser > 0) {
      return;
    }
    let { name, value } = e.target;
    setError(prev => {
      const stateObj = { ...prev, [name]: '' };

      switch (name) {
        case 'password':
          if (!value) {
            stateObj[name] = 'Please enter Password.';
          } else if (confirmPassword && value !== confirmPassword) {
            stateObj['confirmPassword'] =
              'Password and Confirm Password does not match.';
          } else if (value.length < 6) {
            stateObj['password'] = 'Password is too small.';
          } else {
            stateObj['confirmPassword'] = confirmPassword
              ? ''
              : error.confirmPassword;
          }
          break;

        case 'confirmPassword':
          if (!value) {
            stateObj[name] = 'Please enter Confirm Password.';
          } else if (password && value !== password) {
            stateObj[name] = 'Password and Confirm Password does not match.';
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };
  return (
    <>
      <Card style={{ width: '100%' }}>
        <Card.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col>
                <FormInputText
                  label="Nombre"
                  changeHandler={onChange}
                  name={'name'}
                  control={control}
                  defaultValue={user?.name}
                ></FormInputText>
              </Col>
              <Col>
                <FormInputText
                  control={control}
                  label="Apellido"
                  name="lastname"
                  changeHandler={onChange}
                  defaultValue={user?.lastname}
                ></FormInputText>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group as={Col} className="mb-3" controlId="alias">
                  <FormInputText
                    control={control}
                    label="Alias"
                    name="alias"
                    changeHandler={onChange}
                    defaultValue={user?.alias}
                  ></FormInputText>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group as={Col} className="mb-3" controlId="city">
                  <FormInputText
                    control={control}
                    label="Email"
                    name="email"
                    changeHandler={onChange}
                    defaultValue={user?.email}
                  ></FormInputText>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group as={Col} className="mb-3" controlId="phone">
                  <FormInputText
                    control={control}
                    label="Telefono"
                    name="phone"
                    changeHandler={onChange}
                    defaultValue={user?.phone}
                  ></FormInputText>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="idUserType">
                  <Form.Label>Tipo de Usuario</Form.Label>
                  <Form.Select
                    aria-label="Default select"
                    name="idusertype"
                    value={idUserType}
                    style={{ minWidth: '250px' }}
                    onChange={selectedOption => {
                      setIdUserType(`${selectedOption.target.value}`);
                      console.log(`${selectedOption.target.value}`);
                      onChange(selectedOption);
                    }}
                  >
                    <option key={'location_0'} value={0}>
                      Selecciona algun valor
                    </option>
                    {!!usersType?.length &&
                      usersType.map(item => (
                        <option key={item.idusertype} value={item.idusertype}>
                          {item.usertype}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="idUserType">
                  <Form.Label>Sucursal Predeterminada</Form.Label>
                  <Form.Select
                    aria-label="Default select"
                    name="idsucursal"
                    value={idSucursal}
                    style={{ minWidth: '250px' }}
                    onChange={selectedOption => {
                      setIdSucursal(`${selectedOption.target.value}`);
                      console.log(`${selectedOption.target.value}`);
                      onChange(selectedOption);
                    }}
                  >
                    <option key={'sucursal_0'} value={0}>
                      Selecciona algun valor
                    </option>
                    {!!sucursals?.length &&
                      sucursals.map(item => (
                        <option key={item.idsucursal} value={item.idsucursal}>
                          {item.sucursal}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="isActive">
                  <Form.Check
                    type="switch"
                    id="isActive"
                    label="Activo?"
                    checked={isActive}
                    onChange={onChangeActive}
                  />
                </Form.Group>
              </Col>
            </Row>
            {idUser === 0 && (
              <Row>
                <Col>
                  <FormInputText
                    label="Contraseña"
                    changeHandler={onChangePassword}
                    name={'password'}
                    control={control}
                    type="password"
                    required={false}
                    defaultValue={password}
                  ></FormInputText>
                  {error.password && (
                    <span className="err">{error.password}</span>
                  )}
                </Col>
                <Col>
                  <FormInputText
                    control={control}
                    label="Confirme Contraseña"
                    name="confirmPassword"
                    type="password"
                    required={false}
                    changeHandler={onChangeConfirm}
                    defaultValue={confirmPassword}
                  ></FormInputText>
                  {error.confirmPassword && (
                    <span className="err">{error.confirmPassword}</span>
                  )}
                </Col>
              </Row>
            )}
            <Button type="submit" color="primary" size="sm">
              Guardar
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default EditUser;
