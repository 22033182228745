import { Button, Col, Form, Modal, Row, Toast } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FormInputText } from './FormInputText';

const ConfirmAction = ({
  open,
  handleClose,
  title,
  message,
  ConfirmAction
}) => {
  const { control } = useForm({
    mode: 'onBlur'
  });
  return (
    <>
      <Modal
        show={open}
        onHide={() => {
          handleClose(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>{message}</Row>
          <Row></Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              ConfirmAction(false);
              handleClose(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              ConfirmAction(true);
              handleClose(false);
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ConfirmAction;
