import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useAuth } from 'context/AuthProvider';
import { FormInputText } from 'form-components/FormInputText';
import React, { useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { ApiEndpoint } from 'utils/ApiEndPont';

const ReparacionFalla = ({ idReparacionEquipo, closeModal }) => {
  const Auth = useAuth();
  const [validated, setValidated] = useState(false);
  const [values, setValues] = useState({
    descripcion: '',
    precio: '0',
    costo: '0'
  });
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      descripcion: '',
      precio: '0',
      costo: '0'
    }
  });

  const onSubmit = (data, e) => {
    const form = e.target;
    if (form.checkValidity() === false) {
      console.log('entre a submit 123');
      setValidated(true);
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    console.log(idReparacionEquipo);
    const URI = ApiEndpoint + 'reparaciones/addfalla/';
    setValidated(true);
    axios
      .post(URI, {
        idreparacionequipo: idReparacionEquipo,
        creacion: new Date(),
        iduser: Auth.getSessionData().id_user,
        falla: values.descripcion,
        costo: values.costo,
        importe: values.precio
      })
      .then(function (response) {
        console.log(response);
        closeModal();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onChange = event => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  return (
    <>
      <Card style={{ width: '100%' }}>
        <Card.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col>
                <FormInputText
                  label="Comentario"
                  changeHandler={onChange}
                  name={'descripcion'}
                  control={control}
                  defaultValue={values.descripcion}
                ></FormInputText>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormInputText
                  label="Costo"
                  changeHandler={onChange}
                  name={'costo'}
                  control={control}
                  defaultValue={values.costo}
                ></FormInputText>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormInputText
                  label="Cobrar a Cliente"
                  changeHandler={onChange}
                  name={'precio'}
                  control={control}
                  defaultValue={values.precio}
                ></FormInputText>
              </Col>
            </Row>
            <Button type="submit" color="primary" size="sm">
              Guardar
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default ReparacionFalla;
