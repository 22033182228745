import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Table } from 'react-bootstrap';
import { FormatCurrency } from 'utils/FormatData';

const EquiposList = ({ detallesList }) => {
  return (
    <>
      {
        <div>
          <Table responsive striped hover>
            <thead>
              <tr>
                <th scope="col">IMEI</th>
                <th scope="col">Modelo</th>
                <th scope="col">Falla</th>
                <th scope="col">Estimado</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {detallesList.map(detail => (
                <tr className="align-middle" key={detail.rowId}>
                  <td className="text-nowrap">{detail.imei}</td>
                  <td className="text-nowrap">{detail.equipo}</td>

                  <td className="text-nowrap">{detail.falla}</td>

                  <td className="text-nowrap">
                    {FormatCurrency(detail.estimado)}
                  </td>

                  <td className="text-end">
                    <Button
                      variant="falcon-default"
                      size="sm"
                      onClick={() => {
                        console.log(detail.rowId);
                        // setDeleteProduct(detail.rowId);
                      }}
                    >
                      <FontAwesomeIcon icon="trash-alt" />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      }
    </>
  );
};

export default EquiposList;
