import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, Col, Row } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { ApiEndpoint } from 'utils/ApiEndPont';
import { useAuth } from 'context/AuthProvider';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import BtnExportToExcell from 'form-components/ExportToExcel';

const Inventory = () => {
  const Auth = useAuth();
  const [idSucursalFilter, setIdSucursalFilter] = useState(0);
  const [inventory, setInventory] = useState(false);

  const columns = [
    {
      accessor: 'vc_clave',
      Header: 'Clave',
      headerProps: { style: { minWidth: '200px' }, className: 'ps-5' },
      cellProps: { className: 'ps-5' }
    },
    {
      accessor: 'vc_nombre',
      Header: 'Producto',
      headerProps: { style: { minWidth: '200px' }, className: 'ps-5' },
      cellProps: { className: 'ps-5' }
    },
    {
      accessor: 'vc_categoria',
      Header: 'Categoria',
      headerProps: { style: { minWidth: '200px' }, className: 'ps-5' },
      cellProps: { className: 'ps-5' }
    },
    {
      accessor: 'i_existencia',
      Header: 'Existencia',
      headerProps: { style: { minWidth: '200px' }, className: 'ps-5' },
      cellProps: { className: 'ps-5' }
    }
  ];

  const getInventory = () => {
    const URI = ApiEndpoint + 'warehouse/SucursalInventory';
    setInventory(false);
    var dataToSubmit = {
      idsucursal: Auth.getSessionData().id_sucursal
    };
    console.log(dataToSubmit);
    axios
      .post(URI, dataToSubmit)
      .then(function (response) {
        setInventory(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getInventory();
  }, []); // empty array makes hook working once

  return (
    <>
      <>
        {inventory && (
          <AdvanceTableWrapper
            columns={columns}
            data={inventory || []}
            sortable
            pagination
            perPage={10}
          >
            <Card className="mb-3">
              <Card.Header>
                <Row>
                  <Col xs={10} className="d-flex align-items-center pe-0">
                    <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                      Reporte de Inventario
                    </h5>
                  </Col>
                  <Col xs={2}>
                    {inventory && inventory.length > 0 && (
                      <BtnExportToExcell
                        dataReport={inventory.map(item => {
                          return {
                            id: item.id_productos,
                            Producto: item.vc_nombre,
                            Categoria: item.vc_categoria,
                            Clave: item.vc_clave,
                            Marca: item.vc_marca,
                            Modelo: item.vc_modelo,
                            PrecioPublico: { v: item.n_preciopublico, t: 'n' },
                            Existencia: item.i_existencia
                          };
                        })}
                        fileName={'reportinventory.xls'}
                      ></BtnExportToExcell>
                    )}
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="p-0">
                <Row className="flex-end-center mb-3">
                  <Col xs="auto" sm={6} lg={4}>
                    <AdvanceTableSearchBox table />
                  </Col>
                </Row>
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    size: 'sm',
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                  }}
                />
              </Card.Body>
              <Card.Footer>
                <AdvanceTablePagination table />
              </Card.Footer>
            </Card>
          </AdvanceTableWrapper>
        )}
      </>
    </>
  );
};

export default Inventory;
