import axios from 'axios';
import Flex from 'components/common/Flex';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { ApiEndpoint } from 'utils/ApiEndPont';
import { FormatDate } from 'utils/FormatData';
import barChart from 'assets/img/illustrations/crm-bar-chart.png';
import lineChart from 'assets/img/illustrations/crm-line-chart.png';
import MyModal from 'utils/Modal';
import { useAuth } from 'context/AuthProvider';
import ReparacionesByStatusCard from './ReparacionesByStatusCard';
import ReparacionesSmry from './ReparacionesSmry';

const DashboardReparaciones = () => {
  const Auth = useAuth();
  const [smry, setSmry] = useState(null);

  const getReparacionesSmry = () => {
    const URIApertura = ApiEndpoint + 'reparacionessmry/';
    axios
      .post(URIApertura, {
        idsucursal: Auth.getSessionData().id_sucursal
      })
      .then(function (response) {
        if (!response.data.error) {
          setSmry(response.data);
        }
      });
  };

  useEffect(() => {
    getReparacionesSmry();
  }, []);

  return (
    <>
      <Card className="bg-100 shadow-none border mb-3">
        <Card.Body className="py-0">
          <Row className="g-0 justify-content-between">
            <Col sm="auto">
              <Flex alignItems="center">
                <img src={barChart} width={90} alt="..." className="ms-n4" />
                <div>
                  <h6 className="text-primary fs--1 mb-0">CRMovil</h6>
                </div>
                <img
                  src={lineChart}
                  width={140}
                  alt="..."
                  className="ms-n4 d-md-none d-lg-block"
                />
              </Flex>
            </Col>
            <Col md="auto" className="mb-3 mb-md-0">
              {/* <Row className="g-3 gy-md-0 h-100 align-items-center">
                  <Col xs="auto">
                    <h6 className="text-700 mb-0 text-nowrap">
                      Showing Data For:
                    </h6>
                  </Col>
                  <Col md="auto">
                    <FiltersDates />
                  </Col>
                </Row> */}
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {smry && (
        <Row className="g-3 mb-3">
          <Col md={6} xxl={3}>
            <ReparacionesByStatusCard
              status="Pendiente"
              countStatus={smry.pendiente}
            ></ReparacionesByStatusCard>
          </Col>
          <Col md={6} xxl={3}>
            <ReparacionesByStatusCard
              status="En Reparacion"
              countStatus={smry.enReparacion}
            ></ReparacionesByStatusCard>
          </Col>
          <Col md={6} xxl={3}>
            {' '}
            <ReparacionesByStatusCard
              status="Reparados"
              countStatus={smry.Reparados}
            ></ReparacionesByStatusCard>
          </Col>
          <Col md={6} xxl={3}>
            {' '}
            <ReparacionesByStatusCard
              status="Concluidas"
              countStatus={smry.Concluidas}
            ></ReparacionesByStatusCard>
          </Col>
        </Row>
      )}{' '}
      <Row>
        <ReparacionesSmry></ReparacionesSmry>
      </Row>
    </>
  );
};

export default DashboardReparaciones;
