import axios from 'axios';
import Divider from 'components/common/Divider';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { ApiEndpoint } from 'utils/ApiEndPont';
import { FormatDate } from 'utils/FormatData';
import Select from 'react-select';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'context/AuthProvider';

const CreateSession = () => {
  const Auth = useAuth();
  let history = useNavigate();
  const [validated, setValidated] = useState(false);
  const [efectivo, setEfectivo] = useState(0);
  const [idUser, setIdUser] = useState(0);
  const [userData, setUserData] = useState(0);
  const [idSucursal, setIdSucursal] = useState(0);
  const [sucursal, setSucursal] = useState(0);
  const [cajaData, setCajaData] = useState(null);
  const [isCajaClose, setIsCajaClose] = useState(true);
  const [sucursalesList, setSucursalesList] = useState(null);
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      formapago: '1',
      montopago: '0',
      referencia: ''
    }
  });

  const getSucursals = idSucursalSelected => {
    const uriSucursals = ApiEndpoint + 'sucursales/';
    axios(uriSucursals).then(({ data }) => {
      const sucList = data.map(parts => {
        return {
          value: `${parts.idsucursal}`,
          label: `${parts.sucursal}`
        };
      });
      var selSucursal = data.filter(
        item => item.idsucursal == idSucursalSelected
      );
      setSucursal({
        value: `${selSucursal[0].idsucursal}`,
        label: `${selSucursal[0].sucursal}`
      });
      setSucursalesList(sucList);
    });
  };

  const getAperturaData = () => {
    const URIApertura = ApiEndpoint + 'getapertura/';
    axios
      .post(URIApertura, {
        idsucursal: Auth.getSessionData().id_sucursal
      })
      .then(function (response) {
        if (response.data && !response.data.error) {
          setIsCajaClose(false);
          setCajaData(response.data);
          Auth.setCajaData(response.data);
        }
      });
  };

  useEffect(() => {
    setIdUser(Auth.getSessionData().id_user);
    setIdSucursal(Auth.getSessionData().id_sucursal);
    setUserData(Auth.getSessionData());
    getSucursals(Auth.getSessionData().id_sucursal);
    getAperturaData();
  }, []);

  const onSubmit = (data, e) => {
    const form = e.target;
    if (form.checkValidity() === false) {
      console.log('entre a submit 123');
      setValidated(true);
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    const URI = ApiEndpoint + 'newapertura/';
    setValidated(true);

    axios
      .post(URI, {
        idsucursal: idSucursal,
        montoinicio: efectivo,
        iduser: idUser,
        fecha: new Date(),
        cerrado: false
      })
      .then(function (response) {
        if (!response.data.error) {
          Auth.setCajaData(response.data.result);
          history('/');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onContinue = () => {
    console.log('pase a continuar');
    history('/');
  };

  const onChangeEfectivo = value => {
    if (value > 0) {
      setEfectivo(value);
    }
  };
  return (
    <>
      <Card style={{ width: '100%' }}>
        <FalconCardHeader
          title="Caja"
          light
          titleTag="h6"
          endEl={
            <Row>
              <Col style={{ textAlign: 'left' }} className="fs--2 fw-medium">
                Ultima Sesion: {FormatDate(userData.dt_created)}
              </Col>
            </Row>
          }
        ></FalconCardHeader>
        <Card.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col style={{ textAlign: 'left' }} className="fw-bold">
                Bienvenido {`${userData.vc_name} ${userData.vc_lastname}`}
              </Col>
            </Row>
            <hr />
            <Row>
              <Col style={{ textAlign: 'left' }} className="fw-bold">
                <Form.Group className="fw-bold" controlId="idSucursal">
                  Seleccione Sucursal
                  <Select
                    aria-label="Default select"
                    name="sucursal"
                    size="sm"
                    closeMenuOnSelect={true}
                    classNamePrefix="react-select"
                    value={sucursal}
                    onChange={value => {
                      setIdSucursal(value.value);
                      setSucursal(value);
                    }}
                    options={sucursalesList}
                  ></Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col style={{ textAlign: 'left' }} className="fw-bold">
                Ultima Sesion: {FormatDate(userData.dt_created)}
              </Col>
            </Row>
            <hr />
            <Row>
              <Col style={{ textAlign: 'center' }} className="display-5 mb-3">
                Monto Inicial
              </Col>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Col style={{ textAlign: 'center' }} className="fw-bold">
                    Ingrese monto en efectivo
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Controller
                      name="efctivocontrller"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error }
                      }) => (
                        <Form.Control
                          value={efectivo || '0'}
                          label="Efectivo"
                          name="efectivo"
                          placeholder="Efectivo"
                          onChange={event => {
                            onChangeEfectivo(event.target.value);
                          }}
                        />
                      )}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Divider></Divider>

            <Row>
              <Col style={{ textAlign: 'left' }}>
                <Button
                  style={{ height: '150' }}
                  type="submit"
                  color="primary"
                  size="bg"
                  disabled={!isCajaClose}
                >
                  Iniciar nueva Sesion
                </Button>
              </Col>
              <Col style={{ textAlign: 'right' }}>
                <Button
                  style={{ height: '150' }}
                  type="button"
                  color="primary"
                  size="bg"
                  onClick={() => {
                    return history('/');
                  }}
                  disabled={isCajaClose}
                >
                  Continuar Sesion actual
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default CreateSession;
