import axios from 'axios';
import Flex from 'components/common/Flex';
import Logo from 'assets/img/Logo';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Container, Row, Table } from 'react-bootstrap';
import ReactToPrint from 'react-to-print';
import { ApiEndpoint } from 'utils/ApiEndPont';
import { FormatCurrency, FormatDate } from 'utils/FormatData';

const ReparacionPreview = ({ idReparacion, closeModal }) => {
  const [reparacion, setReparacion] = useState(null);
  const [reparacionDetails, setReparacionDetails] = useState(null);

  const [reparacionFallas, setReparacionFallas] = useState(null);
  let componentRef = useRef();
  const handlePrint = () => {
    window.print();
  };

  useEffect(() => {
    axios.get(ApiEndpoint + 'reparacion/' + idReparacion).then(response => {
      setReparacion(response.data);
      getReparacionDetails();
      getReparacionFallas();
    });
  }, []);

  const getReparacionDetails = () => {
    axios
      .get(ApiEndpoint + 'reparaciondetails/' + idReparacion)
      .then(response => {
        getReparacionDetailsObject(response.data);
      });
  };

  const getReparacionFallas = () => {
    axios
      .get(ApiEndpoint + 'reparaciones/getequipos/' + idReparacion)
      .then(response => {
        setReparacionFallas(response.data[0]);
        console.log(response.data);
      });
  };
  const getReparacionDetailsObject = reparacionEquipos => {
    const details = [];
    reparacionEquipos.map(item => {
      let reparacion = {};
      reparacion.IMEI = item.imei;
      reparacion.Falla = item.falla;
      reparacion.Modelo = item.cat_modelo.modelo;
      details.push(reparacion);
    });
    setReparacionDetails(details);
  };

  return (
    <>
      <ReactToPrint
        trigger={() => <Button>Imprimir</Button>}
        content={() => componentRef.current}
      />
      {reparacion && (
        <div ref={componentRef}>
          <Card handlePrint={handlePrint} style={{ padding: 0 }}>
            <div className="printLayoutContainer">
              <style type="text/css" media="print">
                {
                  " @page { width: '80mm' } html, body {color:'black !important' height: auto;font-size: 80%; /* changing to 10pt has no impact */}                "
                }
              </style>
            </div>
            <Card.Header>
              <Row xs={12} md={12} style={{ textAlign: 'center' }} item>
                <Logo style={{ with: '50%', height: '50%' }}></Logo>
              </Row>
            </Card.Header>
            <Card.Body>
              <Container fluid>
                <Row xs={12} md={12} style={{ textAlign: 'center' }} item>
                  <Flex
                    style={{ textAlign: 'center' }}
                    justifyContent="center"
                    className="p-2 mb-2"
                  >
                    {reparacion.cat_sucursale.sucursal}
                  </Flex>
                </Row>
                <Row
                  xs={12}
                  md={12}
                  style={{ textAlign: 'center', fontSize: 'small' }}
                  item
                >
                  <Flex
                    style={{ textAlign: 'center', fontSize: 'small' }}
                    justifyContent="center"
                    className="p-1 mb-1"
                  >
                    {reparacion.cat_sucursale.direccion}
                  </Flex>
                </Row>
                <Row
                  xs={12}
                  md={12}
                  style={{ textAlign: 'center', fontSize: 'small' }}
                  item
                >
                  <Flex
                    style={{ textAlign: 'center', fontSize: 'small' }}
                    justifyContent="center"
                    className="p-1 mb-1"
                  >
                    {reparacion.cat_sucursale.telefono}
                  </Flex>
                </Row>
                <Row
                  xs={12}
                  md={12}
                  style={{ textAlign: 'center', fontWeight: 'bold' }}
                  item
                >
                  <Flex justifyContent="center" className="p-1 mb-1">
                    Orden de Reparacion
                  </Flex>
                </Row>
                <Row className="border border-100">
                  <Col xs={2} md={2} style={{ textAlign: 'left' }}>
                    Folio
                  </Col>
                  <Col
                    xs={4}
                    md={4}
                    style={{ textAlign: 'left', fontWeight: 'bold' }}
                  >
                    {reparacion.folio}
                  </Col>
                  <Col xs={4} md={4} style={{ textAlign: 'right' }}>
                    Fecha
                  </Col>
                  <Col
                    xs={2}
                    md={2}
                    style={{ textAlign: 'right', fontWeight: 'bold' }}
                  >
                    {FormatDate(reparacion.fecharecepcion)}
                  </Col>
                </Row>
                <Row className="border border-100">
                  <Col xs={2} md={2}>
                    Cliente
                  </Col>
                  <Col
                    xs={10}
                    md={10}
                    style={{ textAlign: 'left', fontWeight: 'bold' }}
                  >
                    {reparacion.cat_cliente.nombre}
                  </Col>
                </Row>
              </Container>
              <hr />
              <Row>
                <Table aria-label="sticky table">
                  <thead>
                    <tr>
                      <th style={{ textAlign: 'left' }} colSpan={4}>
                        IMEI
                      </th>
                      <th style={{ textAlign: 'left' }}>Falla</th>
                      <th style={{ textAlign: 'left' }}>Modelo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reparacionDetails &&
                      reparacionDetails.map(item => (
                        <tr hover>
                          <td
                            style={{ textAlign: 'left', fontSize: 'smaller' }}
                            colSpan={4}
                          >
                            {item.IMEI}
                          </td>
                          <td
                            style={{ textAlign: 'left', fontSize: 'smaller' }}
                          >
                            {item.Falla}
                          </td>
                          <td
                            style={{ textAlign: 'left', fontSize: 'smaller' }}
                          >
                            {item.Modelo}
                          </td>
                        </tr>
                      ))}
                    {reparacionFallas &&
                      reparacionFallas.tr_reparacionfallas &&
                      reparacionFallas.tr_reparacionfallas.map(item => (
                        <tr hover>
                          <td></td>
                          <td
                            style={{ textAlign: 'left', fontSize: 'smallest' }}
                            colSpan={4}
                          >
                            {item.falla}
                          </td>
                          <td
                            style={{ textAlign: 'right', fontSize: 'smallest' }}
                          >
                            {FormatCurrency(item.importe)}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                  <tfoot>
                    <tr style={{ borderBottom: '1px' }}></tr>
                  </tfoot>
                </Table>
              </Row>
              <Row>
                <Col xs={8}></Col> <Col>Total</Col>
                <Col className="fw-black">
                  {FormatCurrency(reparacion.total - reparacion.anticipo)}
                </Col>
              </Row>
              <Row>
                <Col xs={8}></Col> <Col>Anticipo</Col>
                <Col className="fw-black">
                  {FormatCurrency(reparacion.total - reparacion.anticipo)}
                </Col>
              </Row>
              <Row>
                <Col xs={8}></Col> <Col>Por Cobrar</Col>
                <Col className="fw-black">
                  {FormatCurrency(reparacion.total - reparacion.anticipo)}
                </Col>
              </Row>
              <hr color="DarkGrey" style={{ fontSize: 'smaller' }} />
              <Row
                className="fw-black center"
                style={{
                  textAlign: 'center',
                  alignContent: 'center',
                  alignItems: 'center'
                }}
                justifyContent="center"
              >
                <Row xs={12} md={12} justifyContent="center">
                  {' '}
                  <Flex justifyContent="center" className="p-2 mb-2">
                    {reparacion.cat_sucursale.sucursal}
                  </Flex>
                </Row>

                <Row style={{ textAlign: 'center' }} xs={12} md={12} item>
                  <Flex justifyContent="center" className="p-2 mb-2">
                    {reparacion.cat_sucursale.email}
                  </Flex>
                </Row>
              </Row>
            </Card.Body>
          </Card>
        </div>
      )}
    </>
  );
};

export default ReparacionPreview;
