import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Table } from 'react-bootstrap';
import { FormatCurrency } from 'utils/FormatData';

const ProductsList = ({ detallesList, totalProducts, setDeleteProduct }) => {
  return (
    <>
      {
        <div>
          <Table responsive striped hover>
            <thead>
              <tr>
                <th scope="col">Cantidad</th>
                <th scope="col">Codigo</th>
                <th scope="col">Producto</th>
                <th scope="col">Costo U.</th>
                <th scope="col">Subtotal</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {detallesList.map(detail => (
                <tr className="align-middle" key={detail.rowId}>
                  <td className="text-nowrap">{detail.Cantidad}</td>
                  <td className="text-nowrap">{detail.Codigo}</td>
                  <td className="text-nowrap">{detail.Producto}</td>

                  <td className="text-nowrap">
                    {FormatCurrency(detail.Costo)}
                  </td>
                  <td className="text-nowrap">
                    {FormatCurrency(
                      Number(detail.Cantidad) * Number(detail.Costo)
                    )}
                  </td>
                  <td className="text-end">
                    <Button
                      variant="falcon-default"
                      size="sm"
                      onClick={() => {
                        console.log(detail.rowId);
                        setDeleteProduct(detail.rowId);
                      }}
                    >
                      <FontAwesomeIcon icon="trash-alt" />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      }
    </>
  );
};

export default ProductsList;
